import React from 'react';

const DeleteUserModal = ({ userToDelete, handleConfirmDelete, closeDeleteModal }) => {
  return (
    <div className="modal">
      <div className="modal-content-delete">
        <button className="close-button" onClick={closeDeleteModal}>×</button>
        <h3>Confirm Delete</h3>
        <p>Are you sure you want to delete {userToDelete?.name}?</p>
        <div className="button-container">
          <button className="delete-confirm-button" onClick={handleConfirmDelete}>Yes, Delete</button>
          <button className="cancel-button" onClick={closeDeleteModal}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;

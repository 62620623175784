import React, { useState,useEffect } from 'react';
import './PlanList.css';
import EditPlan from './EditPlan';
import DeletePlan from './DeletePlan';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { useNavigate } from 'react-router-dom';

const PlanList = () => {
  const { userData } = useUser();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [plans, setPlans] = useState([
    {
      id: 1,
      planType: 'Premium',
      sportType: 'Football',
      name: 'Football Plan A',
      type: 'Full Day',
      price: '$200',
      strikethroughPrice: '$250',
      gstInclusive: 'Yes',
      tagline: 'Best for teams!',
      playingHours: '5',
      maxSlotPerDay: '10',
      coinsPerHour: '100',
      benefitsAdded: 'Free Snacks',
    },
    // Add more plans
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();
  const [editPlan, setEditPlan] = useState(null);
  const [deletePlan, setDeletePlan] = useState(null);

  const handleUpdate = (updatedPlan) => {
    setPlans(plans.map((plan) => (plan.id === updatedPlan.id ? updatedPlan : plan)));
  };

  const handleDelete = (id) => {
    setPlans(plans.filter((plan) => plan.id !== id));
  };

  const handlePlanList = () => navigate('/newPlan');

  const filteredPlans = plans.filter((plan) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      plan.planType.toLowerCase().includes(searchLower) ||
      plan.sportType.toLowerCase().includes(searchLower) ||
      plan.name.toLowerCase().includes(searchLower) ||
      plan.tagline.toLowerCase().includes(searchLower)
    );
  });
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id); // Toggle dropdown visibility
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.plan-dropdown-menu');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.plan-dots-button')) {
        setActiveDropdown(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPlans.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPlans.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  return (
    <div className="plan-list-container" style={{ maxWidth: '1155px', overflowY: 'auto' }}>
      <h2 className="header">Plan List</h2>
      <div className="search-create-container">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="create-button" onClick={handlePlanList}>Create Plan</button>
      </div>
      <div className="plan-list-scroll-container">
        <table className="plan-list-table">
          <thead>
            <tr>
              <th>Plan Type</th>
              <th>Sport Type</th>
              <th>Name</th>
              <th>Type</th>
              <th>Price</th>
              <th>StrikeThrough Price</th>
              <th>GST Inclusive</th>
              <th>Tagline</th>
              <th>Playing Hours</th>
              <th>Max Slot Booking/Day</th>
              <th>Coins per Hour</th>
              <th>Benefits Added</th>
              {hasPrivilege(userData, 'PLANS_LIST_MODIFY') && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {filteredPlans.map((plan) => (
              <tr key={plan.id}>
                <td>{plan.planType}</td>
                <td>{plan.sportType}</td>
                <td>{plan.name}</td>
                <td>{plan.type}</td>
                <td>{plan.price}</td>
                <td>{plan.strikethroughPrice}</td>
                <td>{plan.gstInclusive}</td>
                <td>{plan.tagline}</td>
                <td>{plan.playingHours}</td>
                <td>{plan.maxSlotPerDay}</td>
                <td>{plan.coinsPerHour}</td>
                <td>{plan.benefitsAdded}</td>
                {hasPrivilege(userData, 'PLANS_LIST_MODIFY') && (
                  <td>
                    <div className="plan-action-wrapper">
                      <button
                        onClick={() => toggleDropdown(plan.id)}
                        className="plan-dots-button"
                      >
                        &#x22EE;
                      </button>
                      {activeDropdown === plan.id && (
                        <div className="plan-dropdown-menu">
                          <button onClick={() => setEditPlan(plan)} className="plan-dropdown-item">Edit</button>
                          <button onClick={() => setDeletePlan(plan)} className="plan-dropdown-item">Delete</button>
                          <button className="plan-dropdown-item">Manage Benefits</button>
                        </div>
                      )}
                    </div>
                  </td>
                )}

              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="pagination">
          <button
            className="prev-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {renderPagination()}
          <button
            className="next-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanList;

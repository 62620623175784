import React, { useState } from 'react';
import '../sliders/NewSlider.css'; // Import the external CSS file
import { Link } from 'react-router-dom';

const NewAttributeQA = () => {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!heading || !subHeading) {
      setError('Both Question and Answer fields are required.');
      return;
    }

    // Reset error and success messages
    setError('');
    setSuccess('');

    // Prepare request data
    const requestData = {
      question: heading,
      answer: subHeading,
    };

    try {
      const response = await fetch(
        'https://admin.stage.machaxi.com/api/admin/new-progress-attribute-qa?progressAttributeId=24',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        setSuccess('QA submitted successfully!');
        setHeading('');
        setSubHeading('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to submit QA.');
      }
    } catch (error) {
      setError('An error occurred while submitting QA.');
    }
  };

  return (
    <div className="new-slider-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt;
        <Link to="/ProgresAttribute" className="breadcrumb-link">Progres Attribute</Link> &gt;
        <Link to="/AttributeQa" className="breadcrumb-link">Attribute QA</Link> &gt; Create QA
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Question:</label>
          <input
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Enter Question"
          />
        </div>
        <div>
          <label>Answer:</label>
          <input
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter Answer"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default NewAttributeQA;

import React, { useState } from 'react';
import '../Feedback/coachfeedback.css';
import { useUser } from '../context/UserContext'; 
import { hasPrivilege } from '../utils/hasPrivilege';
import { useNavigate } from 'react-router-dom';

const CoachFeedback = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [coaches, setCoaches] = useState([
    { id: 1, name: 'Coach A', about: 'Experienced in swimming and basketball.', experience: '5 years' },
    { id: 2, name: 'Coach B', about: 'Specializes in football and tennis.', experience: '7 years' },
    { id: 3, name: 'Coach C', about: 'Expert in boxing and martial arts.', experience: '3 years' },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedCoach, setEditedCoach] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [coachToDelete, setCoachToDelete] = useState(null);
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCoaches = coaches.filter((coach) =>
    Object.values(coach)
      .join(' ') // Join all fields into a single string
      .toLowerCase() // Case-insensitive comparison
      .includes(searchTerm.toLowerCase()) // Filter based on the search term
  );

  const handleEditClick = (coach) => {
    setEditedCoach(coach);  // Set the selected coach to be edited
    setIsModalOpen(true);  // Open the modal
  };

  const handleDeleteClick = (coach) => {
    setCoachToDelete(coach); // Set the coach to delete
    setIsDeleteModalOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirm = () => {
    // Proceed with deletion if confirmed
    const updatedCoaches = coaches.filter((coach) => coach.id !== coachToDelete.id);
    setCoaches(updatedCoaches);
    setIsDeleteModalOpen(false); // Close the delete confirmation modal
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete confirmation modal without deleting
    setCoachToDelete(null); // Clear the selected coach to delete
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the edit modal
    setEditedCoach(null); // Clear the edited coach
  };

  const handleSaveChanges = () => {
    const updatedCoaches = coaches.map((coach) =>
      coach.id === editedCoach.id ? editedCoach : coach
    );
    setCoaches(updatedCoaches); // Save changes to the coaches
    handleCloseModal(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCoach((prevCoach) => ({
      ...prevCoach,
      [name]: value,
    }));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCoaches.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCoaches.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const handleCoachList = () => navigate('/CreateCoach');

  return (
    <div className="coach-feedback-container">
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search Coach"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <button className="create-coach-button" onClick={handleCoachList}>Create Coach</button>
      </div>

      <table className="coach-feedback-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>About</th>
            <th>Experience</th>
            {hasPrivilege(userData ,'COACH_FEEDBACKS_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((coach) => (
            <tr key={coach.id}>
              <td>{coach.name}</td>
              <td>{coach.about}</td>
              <td>{coach.experience}</td>
              {hasPrivilege(userData ,'COACH_FEEDBACKS_MODIFY') && ( <td>
                <button className="edit-button" onClick={() => handleEditClick(coach)}>Edit</button>
                <button className="delete-button" onClick={() => handleDeleteClick(coach)}>Delete</button>
              </td>)}
             
            </tr>
          ))}
        </tbody>
      </table>
 {/* Pagination */}
       <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {/* Edit Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Edit Coach Feedback</h2>
            <div className="modal-body">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={editedCoach.name}
                onChange={handleInputChange}
              />
              <label>About</label>
              <textarea
                name="about"
                value={editedCoach.about}
                onChange={handleInputChange}
              />
              <label>Experience</label>
              <input
                type="text"
                name="experience"
                value={editedCoach.experience}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-footer">
              <button className="save-button" onClick={handleSaveChanges}>Save</button>
              <button className="cancel-button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Are you sure you want to delete this coach's feedback?</h2>
            <div className="modal-footer">
              <button className="confirm-button" onClick={handleDeleteConfirm}>Yes, Delete</button>
              <button className="cancel-button" onClick={handleDeleteCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachFeedback;

import React, { useState } from 'react';
import './FeesSetting.css'; // Assuming you will create a separate CSS file for styling

const FeesSetting = () => {
  const [formData, setFormData] = useState({
    tournamentCommission: '',
    courtBookingCommission: '',
    feesCommission: '',
    gstNumber: '',
    panCardNumber: '',
    coachingEnabled: false,
    tournamentEnabled: false,
    bookAndPlayEnabled: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send data to an API or log to the console)
    console.log(formData);
  };

  return (
    <div className="fees-setting-container">
      <form onSubmit={handleSubmit} className="fees-form">
        <div className="form-group">
          <label htmlFor="tournamentCommission">Tournament Commission (%)</label>
          <input
            type="number"
            id="tournamentCommission"
            name="tournamentCommission"
            value={formData.tournamentCommission}
            onChange={handleChange}
            placeholder="Enter Tournament Commission (%)"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="courtBookingCommission">Court Booking Commission (%)</label>
          <input
            type="number"
            id="courtBookingCommission"
            name="courtBookingCommission"
            value={formData.courtBookingCommission}
            onChange={handleChange}
            placeholder="Enter Court Booking Commission (%)"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="feesCommission">Fees Commission (%)</label>
          <input
            type="number"
            id="feesCommission"
            name="feesCommission"
            value={formData.feesCommission}
            onChange={handleChange}
            placeholder="Enter Fees Commission (%)"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="gstNumber">GST Number</label>
          <input
            type="text"
            id="gstNumber"
            name="gstNumber"
            value={formData.gstNumber}
            onChange={handleChange}
            placeholder="Enter GST Number"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="panCardNumber">PAN Card Number</label>
          <input
            type="text"
            id="panCardNumber"
            name="panCardNumber"
            value={formData.panCardNumber}
            onChange={handleChange}
            placeholder="Enter PAN Card Number"
            required
          />
        </div>

        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              name="coachingEnabled"
              checked={formData.coachingEnabled}
              onChange={handleChange}
            />
            Coaching Enabled
          </label>

          <label>
            <input
              type="checkbox"
              name="tournamentEnabled"
              checked={formData.tournamentEnabled}
              onChange={handleChange}
            />
            Tournament Enabled
          </label>

          <label>
            <input
              type="checkbox"
              name="bookAndPlayEnabled"
              checked={formData.bookAndPlayEnabled}
              onChange={handleChange}
            />
            Book and Play Enabled
          </label>
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default FeesSetting;

import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import { useUser } from '../context/UserContext';

const CheckinReports = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const { selectedCenter, setSelectedCenter } = useUser();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [rowsPerPage, setrowsPerPage] = useState(10); // Default to 10 items per page
  const [items, setItems] = useState([]); // Your data (e.g., from API)
  useEffect(() => {
    if (selectedCenter) {
      setSelectedAcademy(selectedCenter); // Set selected academy to match the selected center
    }
  }, [selectedCenter]);
  
  const handleItemsPerPageChange = (event) => {
    setrowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };
  const [CheckinReports, setCheckinReports] = useState([]);

  const [academyNames, setAcademyNames] = useState({}); // To store academy names mapping

  useEffect(() => {
    // Fetch check-in trials data
    axios
      .get('https://admin.stage.machaxi.com/api/academy/checkIn-trials', {
        headers: {
          'x-authorization':
            'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
      })
      .then((response) => {
        if (response.data.success) {
          const reports = response.data.data.bookings.map((booking) => ({
            player_name: booking.userName,
            parents_phone: booking['Parent Phone'],
            players_phone: booking['Player Phone'],
            type: booking.trialType,
            sport: booking.SportName,
            time: '3',
            date: booking.trialDate,
            status: booking.enabled ? 'successful' : 'failed',
            preferredCenter: booking.academyName,
          }));
          setCheckinReports(reports); // Update state with booking reports
        } else {
          console.error('Error:', response.data.success_message);
        }
      })
      .catch((error) => {
        console.error('Error fetching check-in trials data:', error);
      });

    // Fetch academy names separately
    axios
      .get('https://admin.stage.machaxi.com/api/academy/checkIn-trials', {
        headers: {
          'x-authorization':
            'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Use appropriate token here
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAcademyNames(response.data.data.academyNameMap);
          console.log(response.data.data.academyNameMap)// Set the academy name map
        } else {
          console.error('Error fetching academy names:', response.data.success_message);
        }
      })
      .catch((error) => {
        console.error('Error fetching academy names:', error);
      });
  }, []);
  const headers = [
    { label: "Academy", key: "preferredCenter" },
    { label: "Name", key: "player_name" },
    { label: "Parent's Phone", key: "parents_phone" },
    { label: "Player's Phone", key: "players_phone" },
    { label: "Type", key: "type" },
    { label: "Sport", key: "sport" },
    { label: "Time", key: "time" },
    { label: "Date", key: "date" },
    { label: "Status", key: "status" },
  ];


  const handleUpdateCenter = () => {
    // Example: Update the center to a new value
    setSelectedCenter('newCenterId');
  };

  const [currentPage, setCurrentPage] = useState(1);

  const resetPageToFirst = () => {
    setCurrentPage(1); // Reset to page 1
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCheckinReports = CheckinReports.filter((checkin) => {
    const matchesSearch = Object.values(checkin)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  
    const matchesAcademy =
      selectedAcademy === '' || checkin.preferredCenter === academyNames[selectedAcademy];
  
    const matchesDate =
      (fromDate === '' || new Date(checkin.date) >= new Date(fromDate)) &&
      (toDate === '' || new Date(checkin.date) <= new Date(toDate));
  
    const matchesType = selectedType === '' || checkin.type === selectedType;
    const matchesSport = selectedSport === '' || checkin.sport === selectedSport;
    const matchesStatus = selectedStatus === '' || checkin.status === selectedStatus;
  
    return matchesSearch && matchesAcademy && matchesDate && matchesType && matchesSport && matchesStatus;
  });
  

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCheckinReports.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCheckinReports.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  const academyOptions = [...new Set(CheckinReports.map((checkin) => checkin.preferredCenter))];
  const typeOptions = [...new Set(CheckinReports.map((checkin) => checkin.type))];
  const sportOptions = [...new Set(CheckinReports.map((checkin) => checkin.sport))];
  const statusOptions = [...new Set(CheckinReports.map((checkin) => checkin.status))];

  return (
    <div className="challenges-container">

      <div>
        <p>Current Selected Center: {selectedCenter || 'No center selected'}</p>

      </div>
      <div className="sales-filters">

        <div className="floating-select">
          <select
            id="academySelect"
            className={`sales-filter-academy ${selectedAcademy ? "has-value" : ""}`}
            value={selectedAcademy}
            onChange={(e) => {
              const value = e.target.value;
              if (value === 'unselect') {
                setSelectedAcademy(''); // Reset the selection to "unselect"
              } else {
                setSelectedAcademy(value);
              }
              resetPageToFirst();
            }}
            
          >
            <option value="" disabled hidden></option>
            <option value="unselect">All Academy</option>
            {Object.entries(academyNames).map(([id, name]) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}

          </select>
          <label htmlFor="academySelect">Select Academy</label>
        </div>

        <div className="floating-select">
          <input
            type="date"
            id="fromDate"
            className={`sales-filter-academy ${fromDate ? "has-value" : ""}`}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
          {fromDate && <label htmlFor="fromDate">Start Date</label>}
        </div>

        <div className="floating-select">
          <input
            type="date"
            id="toDate"
            className={`sales-filter-academy ${toDate ? "has-value" : ""}`}
            value={toDate}
            placeholder="dd/mm/yyyy" // Not natively supported for type="date"
            onChange={(e) => setToDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />

          {toDate && <label htmlFor="toDate">End Date</label>}
        </div>

        <div className="floating-select">
          <select
            id="typeSelect"
            className={`sales-filter-academy ${selectedType ? "has-value" : ""}`}
            value={selectedType}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "unselect") {
                setSelectedType(""); // Reset the selection to "unselect"
              } else {
                setSelectedType(value);
              }
              resetPageToFirst();
            }}
          >
            <option value="" disabled hidden></option>
            <option value="unselect">All Type </option> {/* Add Unselect All option */}
            {typeOptions.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <label htmlFor="typeSelect">Select Type</label>
        </div>

        <div className="floating-select">
          <select
            className={`sales-filter-academy ${selectedSport ? "has-value" : ""}`}
            id='selectSport'
            value={selectedSport}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "unselect") {
                setSelectedSport(""); // Reset the selection to "unselect"
              } else {
                setSelectedSport(value);
              }
              resetPageToFirst();
            }}
          >
            <option value="" disabled hidden></option>
            <option value="unselect">All Sport </option> {/* Add Unselect All option */}
            {sportOptions.map((sport) => (
              <option key={sport} value={sport}>
                {sport}
              </option>
            ))}
          </select>
          <label htmlFor="selectSport">Select Sport</label>
        </div>

        <div className="floating-select">
          <select
            className={`sales-filter-academy ${selectedStatus ? "has-value" : ""}`}
            id="selectStatus"
            value={selectedStatus}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "unselect") {
                setSelectedStatus(""); // Reset the selection to "unselect"
              } else {
                setSelectedStatus(value);
              }
              resetPageToFirst();
            }}
          >
            <option value="" disabled hidden></option>
            <option value="unselect">All Status</option> {/* Add Unselect All option */}
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          <label htmlFor="selectStatus">Select Status</label>
        </div>

      </div>

      <div className="space"></div>

      <div className="search-bar">
        <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>

        <CSVLink
          data={filteredCheckinReports}
          headers={headers}
          filename="filtered_checkin_reports.csv"
          className="btn btn-primary"
        >
          <button className="create-button">Export CSV</button>
        </CSVLink>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
        <option value={100}>100 items per page</option>
      </select>

      <div style={{ maxWidth: '1200px', overflowY: 'auto' }}>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th>Academy Name</th>
              <th>Player Name</th>
              <th>Parents Phone No.</th>
              <th>Player Phone No.</th>
              <th>Sport</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Checkin Status</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((checkin) => (
              <tr key={checkin.id}>
                <td>{checkin.preferredCenter}</td>
                <td>{checkin.player_name}</td>
                <td>{checkin.parents_phone}</td>
                <td>{checkin.players_phone}</td>
                <td>{checkin.sport}</td>
                <td>{checkin.type}</td>
                <td>{checkin.date}</td>
                <td>{checkin.time}</td>
                <td>{checkin.status}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            className="prev-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {renderPagination()}
          <button
            className="next-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckinReports;

import React, { useState } from 'react';
import '../PlayingPlan/NewPlan.css';
import { Link } from 'react-router-dom';

const NewPlan = () => {
  const [sportsType, setSportsType] = useState('');
  const [selectSport, setSelectSport] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    planType: '',
    price: '',
    strikethroughPrice: '',
    gstInclusive: '',
    tagLine: '',
    playingHours: '',
    maxSlotBooking: '',
    coinsRewarded: '',
    planIcon: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleImageUpload = (e) => {
    setFormValues({ ...formValues, planIcon: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!sportsType) return alert('Sports Type is required');
    if (sportsType === 'single' && !selectSport) return alert('Select Sport is required');
    for (const key in formValues) {
      if (!formValues[key] && key !== 'planIcon') {
        return alert(`${key.replace(/([A-Z])/g, ' $1')} is required`);
      }
    }
    if (!formValues.planIcon) return alert('Plan Icon is required');
    alert('Form submitted successfully!');
    // Add logic to submit the form data
  };

  return (
    <div className="new-plan-form-container">
      <form onSubmit={handleSubmit} className="new-plan-form">
      <div className="breadcrumb">
          <Link to="/planList" className="breadcrumb-link">Plan List</Link> &gt; New Plan
        </div>
        <p className='create-plan'>Create Plan</p>
        <div className="form-group">
          <label>Sports Type *</label>
          <select
            className="input-select"
            value={sportsType}
            onChange={(e) => setSportsType(e.target.value)}
            required
          >
            <option value="">Select Sports Type</option>
            <option value="single">Single Sport</option>
            <option value="multi">Multi Sport</option>
          </select>
        </div>

        {sportsType === 'single' && (
          <div className="form-group">
            <label>Select Sport *</label>
            <select
              className="input-select"
              value={selectSport}
              onChange={(e) => setSelectSport(e.target.value)}
              required
            >
              <option value="">Select Sport</option>
              <option value="badminton">Badminton</option>
              <option value="tableTennis">Table Tennis</option>
              {/* Add other sports here */}
            </select>
          </div>
        )}

        <div className="form-group">
          <label>Name *</label>
          <input
            className="input-text"
            type="text"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Plan Type *</label>
          <select
            className="input-select"
            name="planType"
            value={formValues.planType}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Plan Type</option>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>

        <div className="form-group">
          <label>Price *</label>
          <input
            className="input-text"
            type="number"
            name="price"
            value={formValues.price}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Strikethrough Price *</label>
          <input
            className="input-text"
            type="number"
            name="strikethroughPrice"
            value={formValues.strikethroughPrice}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>GST Inclusive *</label>
          <select
            className="input-select"
            name="gstInclusive"
            value={formValues.gstInclusive}
            onChange={handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div className="form-group">
          <label>Tag Line *</label>
          <input
            className="input-text"
            type="text"
            name="tagLine"
            value={formValues.tagLine}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Playing Hours *</label>
          <input
            className="input-text"
            type="number"
            name="playingHours"
            value={formValues.playingHours}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Max Slot Booking/Day *</label>
          <input
            className="input-text"
            type="number"
            name="maxSlotBooking"
            value={formValues.maxSlotBooking}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Coins Rewarded/Hour *</label>
          <input
            className="input-text"
            type="number"
            name="coinsRewarded"
            value={formValues.coinsRewarded}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Plan Icon *</label>
          <input
            className="input-file"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />
        </div>

        <div className="form-submit">
          <button className="submit-button" type="submit">Create Plan</button>
        </div>
      </form>
    </div>
  );
};

export default NewPlan;

import React, { useState } from 'react';
import './AcademyGallery.css'; // Assuming you have a corresponding CSS file

const AcademyGallery = () => {
  const [notifications, setNotifications] = useState([
    { id: 1, name: 'Batch A', imageUrl: 'https://via.placeholder.com/100', },
    { id: 2, name: 'Batch B', imageUrl: 'https://via.placeholder.com/100', },
    { id: 3, name: 'Batch C', imageUrl: 'https://via.placeholder.com/100', },
  ]);

  // Handle delete functionality
  const handleDelete = (id) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  return (
    <div className="notification-container">
      <button className="create-button">
          Upload Image
        </button>
      <table className="notification-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => (
            <tr key={notification.id}>
              <td>
                <img 
                  src={notification.imageUrl} 
                  alt={notification.name} 
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
              </td>
              <td>
                <button 
                  className="delete-button" 
                  onClick={() => handleDelete(notification.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AcademyGallery;

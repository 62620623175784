import React, { useState } from 'react';
import './Challenge.css';

const SalesRegister = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [SalesRegister, setSalesRegister] = useState([
    {
      name: "John Doe",
      academyName: "Elite Sports Academy",
      mobileNumber: "9876543210",
      typeOfPlan: "Monthly",
      sportName: "Badminton",
      baseValue: 5000,
      couponAmount: 500,
      gstValue: 450,
      finalAmount: 4950,
      validity: "30 Days",
      invoiceDate: "2024-12-01",
      pdf: "Invoice_JohnDoe.pdf",
    },
    {
      name: "Jane Smith",
      academyName: "Pro Fitness Hub",
      mobileNumber: "8765432109",
      typeOfPlan: "Yearly",
      sportName: "Tennis",
      baseValue: 20000,
      couponAmount: 2000,
      gstValue: 1800,
      finalAmount: 19800,
      validity: "365 Days",
      invoiceDate: "2024-11-15",
      pdf: "Invoice_JaneSmith.pdf",
    },
    {
      name: "Mike Johnson",
      academyName: "Champion's League",
      mobileNumber: "7654321098",
      typeOfPlan: "Quarterly",
      sportName: "Football",
      baseValue: 10000,
      couponAmount: 1000,
      gstValue: 900,
      finalAmount: 9900,
      validity: "90 Days",
      invoiceDate: "2024-10-20",
      pdf: "Invoice_MikeJohnson.pdf",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtering the data
  const filteredSalesRegister = SalesRegister.filter((Sales) => {
    const matchesSearch = Object.values(Sales)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesAcademy =
      selectedAcademy === '' || Sales.academyName === selectedAcademy;

    const matchesSport =
      selectedSport === '' || Sales.sportName === selectedSport;

    const matchesPlan =
      selectedPlan === '' || Sales.typeOfPlan === selectedPlan;

    const matchesDate =
      (fromDate === '' || new Date(Sales.invoiceDate) >= new Date(fromDate)) &&
      (toDate === '' || new Date(Sales.invoiceDate) <= new Date(toDate));

    return (
      matchesSearch && matchesAcademy && matchesSport && matchesPlan && matchesDate
    );
  });

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredSalesRegister.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredSalesRegister.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page) => (
      <button
        key={page}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ));
  };

  return (
    <div className="challenges-container">
      <div className="sales-filters">
        <select
          className="sales-filter-academy"
          value={selectedAcademy}
          onChange={(e) => setSelectedAcademy(e.target.value)}
        >
          <option value="">Select Academy</option>
          <option value="Elite Sports Academy">Elite Sports Academy</option>
          <option value="Pro Fitness Hub">Pro Fitness Hub</option>
          <option value="Champion's League">Champion's League</option>
        </select>

        <select
          className="sales-filter-sport"
          value={selectedSport}
          onChange={(e) => setSelectedSport(e.target.value)}
        >
          <option value="">Select Sport</option>
          <option value="Badminton">Badminton</option>
          <option value="Tennis">Tennis</option>
          <option value="Football">Football</option>
        </select>

        <select
          className="sales-filter-plan"
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
        >
          <option value="">Select Type of Plan</option>
          <option value="Monthly">Monthly</option>
          <option value="Quarterly">Quarterly</option>
          <option value="Yearly">Yearly</option>
        </select>

        <input
          type="date"
          className="sales-filter-from-date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}

        />

        <input
          type="date"
          className="sales-filter-to-date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          onFocus={(e) => e.target.showPicker()}

        />
      </div>
     
        <h2 className="challenges-header">Sales Register</h2>

        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="create-button">Export CSV</button>
        </div>
        <div style={{ maxWidth: '1100px', overflowY: 'auto' }}>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th className="challenges-column">Name</th>
              <th className="challenges-column">Academy Name</th>
              <th className="challenges-column">Mobile Number</th>
              <th className="challenges-column">Type of Plan</th>
              <th className="challenges-column">Sport Name</th>
              <th className="challenges-column">Base Value</th>
              <th className="challenges-column">Coupon Amount</th>
              <th className="challenges-column">GST Value</th>
              <th className="challenges-column">Final Amount</th>
              <th className="challenges-column">Validity</th>
              <th className="challenges-column">Invoice Date</th>
              <th className="challenges-column">PDF</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((Sales, index) => (
              <tr key={index} className="challenges-row">
                <td>{Sales.name}</td>
                <td>{Sales.academyName}</td>
                <td>{Sales.mobileNumber}</td>
                <td>{Sales.typeOfPlan}</td>
                <td>{Sales.sportName}</td>
                <td>{Sales.baseValue}</td>
                <td>{Sales.couponAmount}</td>
                <td>{Sales.gstValue}</td>
                <td>{Sales.finalAmount}</td>
                <td>{Sales.validity}</td>
                <td>{Sales.invoiceDate}</td>
                <td>
                  <a href={`/${Sales.pdf}`} download>
                    Download PDF
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination">
          <button
            className="prev-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {renderPagination()}
          <button
            className="next-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesRegister;

import React, { useState } from 'react';
import './Shop.css';
import { Link } from 'react-router-dom';

const Shop = () => {
  const [formData, setFormData] = useState({
    playerName: 'John Doe',  // Example default value for player name
    rewardPointsBalance: 1000, // Example default value for reward points balance
    transactionType: '',
    rewardPoints: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., sending data to an API or logging it
    console.log('Form submitted:', formData);
  };

  return (
    <div className="shop-container">
       <div className="breadcrumb">
        <Link to="/academyPlayerList" className="breadcrumb-link">Academy Player List</Link> &gt; Shop
      </div>
      <form onSubmit={handleSubmit} className="shop-form">
        <div className="form-group-player-name">
          <label htmlFor="playerName" className="form-label-player-name">Player Name</label>
          <input
            type="text"
            id="playerName"
            name="playerName"
            value={formData.playerName}
            onChange={handleChange}
            className="form-input-player-name"
            disabled
          />
        </div>

        <div className="form-group-reward-points-balance">
          <label htmlFor="rewardPointsBalance" className="form-label-reward-points-balance">Reward Points Balance</label>
          <input
            type="number"
            id="rewardPointsBalance"
            name="rewardPointsBalance"
            value={formData.rewardPointsBalance}
            onChange={handleChange}
            className="form-input-reward-points-balance"
            disabled
          />
        </div>

        <div className="form-group-transaction-type">
          <label htmlFor="transactionType" className="form-label-transaction-type">Transaction Type</label>
          <select
            id="transactionType"
            name="transactionType"
            value={formData.transactionType}
            onChange={handleChange}
            className="form-input-transaction-type"
            required
          >
            <option value="">Select Transaction Type</option>
            <option value="earn">Earn</option>
            <option value="redeem">Redeem</option>
          </select>
        </div>

        <div className="form-group-reward-points">
          <label htmlFor="rewardPoints" className="form-label-reward-points">Reward Points*</label>
          <input
            type="input"
            id="rewardPoints"
            name="rewardPoints"
            value={formData.rewardPoints}
            onChange={handleChange}
            className="form-input-reward-points"
            required
            placeholder='0'
          />
        </div>

        <div className="form-group-submit-btn">
          <button type="submit" className="form-submit-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Shop;

import React, { useState } from 'react';
import '../sliders/NewSlider.css'; // Import the external CSS file
import { Link } from 'react-router-dom';

const NewProgessParameter = () => {
  const [subHeading, setSubHeading] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = 'https://admin.stage.machaxi.com/api/admin/new-progress-parameter?progressAttributeId=25';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
        },
        body: JSON.stringify({ name: subHeading }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage('Progress parameter created successfully!');
        setSubHeading('');
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to create progress parameter.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="new-slider-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">
          Sport List
        </Link>{' '}
        &gt;
        <Link to="/ProgresAttribute" className="breadcrumb-link">
          Progres Parameter
        </Link>{' '}
        &gt;
        <Link to="/ProgessParameter" className="breadcrumb-link">
          Progess Parameter
        </Link>{' '}
        &gt; Create Progress Parameter
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name-input">Name:</label>
          <input
            id="name-input"
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter Name"
            required
          />
        </div>
        <button type="submit" onClick={handleSubmit}>Submit</button>
      </form>

      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default NewProgessParameter;

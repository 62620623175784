import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import '../Login/LoginPage.css';
import logo from '../images/image.png'; // Replace with the correct path to your logo image
import { useUser } from '../context/UserContext'; 
const LoginPage = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const navigate = useNavigate();
  const { setUser } = useUser(); // Destructure the setUser function from context

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading state

    try {
      const response = await fetch('https://admin.stage.machaxi.com/api/auth/login2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Login successful:', data);
        setUser({
          userId: data.userId,
          email: data.email,
          privileges: data.privileges,
          userType: data.userType,
        });
        setIsLoggedIn(true);
        navigate('/');
      } else {
        if (response.status === 401) {
          setError('Invalid email or password. Please try again.');
        } else if (response.status === 403) {
          setError('Your account is not authorized to access this application.');
        } else if (response.status === 500) {
          setError('Internal server error. Please try again later.');
        } else {
          setError(data?.message || 'An unknown error occurred. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('Invalid email or password. Please try again.');
    } finally {
      setIsLoading(false); // End loading state
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError(''); // Clear the error
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(''); // Clear the error
  };

  return (
    <div className="login-page"  >
      {/* Navbar */}
      <nav className="navbar">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <h2 className="heading">Machaxi</h2>
        </div>
      </nav>

      {/* Login Section */}
      <div className="login-container">
        <div className="left-section"></div>
        <div className="right-section">
          <h2>Login</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button className="button" type="submit" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
          {error && <div className="error-message">{error}</div>}

        </div>
      </div>
    </div>
  );
};

export default LoginPage;
import React, { useState } from 'react';
import './Challenge.css';

const LoginReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [LoginReport, setLoginReport] = useState([
    {
      parentName: "Michael Johnson",
      mobileNumber: "9876543210",
      academy: "Elite Academy",
      playerName: "Alice Johnson",
      lastActive: "2024-01-15",
      lastLogin: "2024-01-14",
      oneSignalDeviceId: "abc123deviceid",
    },
    {
      parentName: "Sarah Smith",
      mobileNumber: "9887766554",
      academy: "Pro Academy",
      playerName: "Bob Smith",
      lastActive: "2024-02-08",
      lastLogin: "2024-02-07",
      oneSignalDeviceId: "def456deviceid",
    },
    {
      parentName: "David Davis",
      mobileNumber: "9776677889",
      academy: "Super Academy",
      playerName: "Charlie Davis",
      lastActive: "2024-03-12",
      lastLogin: "2024-03-10",
      oneSignalDeviceId: "ghi789deviceid",
    },
    {
      parentName: "Emily Brown",
      mobileNumber: "9654433221",
      academy: "Champion Academy",
      playerName: "David Brown",
      lastActive: "2024-04-06",
      lastLogin: "2024-04-05",
      oneSignalDeviceId: "jkl101deviceid",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDateFilterChange = (event) => {
    setStartDateFilter(event.target.value);
  };

  const filteredLoginReport = LoginReport.filter((row) =>
    Object.values(row)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase()) &&
    (startDateFilter ? row.lastActive === startDateFilter : true)
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredLoginReport.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredLoginReport.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container" style={{ maxWidth: '1100px', overflowY: 'auto' }}>
      <h2 className="challenges-header">Login Report</h2>
      
      {/* Search and Start Date Filter */}
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        
        {/* Start Date Filter */}
        <select
          value={startDateFilter}
          onChange={handleStartDateFilterChange}
          className="start-date-filter"
        >
          <option value="">All Start Dates</option>
          {LoginReport.map((row, index) => (
            <option key={index} value={row.lastActive}>
              {row.lastActive}
            </option>
          ))}
        </select>

        <button className="create-button">
          Export CSV
        </button>
      </div>

      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
            <th className="challenges-column">Parent Name</th>
            <th className="challenges-column">Mobile Number</th>
            <th className="challenges-column">Academy</th>
            <th className="challenges-column">Player Name</th>
            <th className="challenges-column">Last Active</th>
            <th className="challenges-column">Last Login</th>
            <th className="challenges-column">OneSignal Device id</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((LoginReport) => (
            <tr key={LoginReport} className="challenges-row">
              <td className="challenges-cell">{LoginReport.parentName}</td>
              <td className="challenges-cell">{LoginReport.mobileNumber}</td>
              <td className="challenges-cell">{LoginReport.academy}</td>
              <td className="challenges-cell">{LoginReport.playerName}</td>
              <td className="challenges-cell">{LoginReport.lastActive}</td>
              <td className="challenges-cell">{LoginReport.lastLogin}</td>
              <td className="challenges-cell">{LoginReport.oneSignalDeviceId}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LoginReport;

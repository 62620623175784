import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Payment/sportpayment.css'; // Ensure you have the appropriate CSS

const SportPayment = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [payments, setPayments] = useState([
    { id: 1, academy: 'Academy A', badminton: 500, swimming: 600, football: 450, boxing: 550, karate: 400, date: '2024-11-01' },
    { id: 2, academy: 'Academy B', badminton: 400, swimming: 700, football: 500, boxing: 600, karate: 450, date: '2024-11-10' },
    { id: 3, academy: 'Academy C', badminton: 550, swimming: 650, football: 480, boxing: 590, karate: 430, date: '2024-11-15' },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle start date change
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  // Handle end date change
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  // Filter payments based on search term and date range
  const filteredPayments = payments.filter((payment) => {
    // Filter by search term
    const matchesSearch = Object.values(payment)
      .join(' ') // Join all fields into a single string
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Filter by date range if provided
    const paymentDate = new Date(payment.date);
    const isWithinDateRange =
      (!startDate || paymentDate >= new Date(startDate)) &&
      (!endDate || paymentDate <= new Date(endDate));

    return matchesSearch && isWithinDateRange;
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPayments.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPayments.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="sport-payment-container">
       {/* Date Filter Section */}
       <div className="filter-container-sportpayment">
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
            placeholder="Start Date"
            onClick={(e) => e.target.showPicker()}
          />
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
            placeholder="End Date"
            onClick={(e) => e.target.showPicker()}
          />
        </div>

      {/* Row for search bar and date filters */}
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

       
      </div>

      <table className="sport-payment-table">
        <thead>
          <tr>
            <th>Academy</th>
            <th>Badminton</th>
            <th>Swimming</th>
            <th>Football</th>
            <th>Boxing</th>
            <th>Karate</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.academy}</td>
              <td>{payment.badminton}</td>
              <td>{payment.swimming}</td>
              <td>{payment.football}</td>
              <td>{payment.boxing}</td>
              <td>{payment.karate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SportPayment;

import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; 
import { hasPrivilege } from '../utils/hasPrivilege';
import './BatchList.css';

const BatchList = () => {
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [batches, setBatches] = useState([
    { id: 1, name: 'Batch A', proficiency: 'Advanced', capacity: 30, currentStrength: 25, monthlyFees: 1000 },
    { id: 2, name: 'Batch B', proficiency: 'Intermediate', capacity: 25, currentStrength: 20, monthlyFees: 800 },
    { id: 3, name: 'Batch C', proficiency: 'Beginner', capacity: 20, currentStrength: 18, monthlyFees: 500 },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ name: '', proficiency: '', capacity: '', currentStrength: '', monthlyFees: '' });
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const toggleDropdown = (playerId) => {
    setDropdownOpen(dropdownOpen === playerId ? null : playerId);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewBatch = () => {
    navigate('/createNewBatch');
  };

  const handleEditBatch = (batchId) => {
    const batch = batches.find(b => b.id === batchId);
    setSelectedBatch(batch);
    setFormData({ ...batch });
    setAction('edit');
    setIsModalOpen(true);
  };

  const handleDeleteBatch = (batchId) => {
    setSelectedBatch(batches.find(batch => batch.id === batchId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedBatches = batches.filter(batch => batch.id !== selectedBatch.id);
    setBatches(updatedBatches);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedBatches = batches.map(batch =>
      batch.id === selectedBatch.id ? { ...batch, ...formData } : batch
    );
    setBatches(updatedBatches);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredBatches = batches.filter((batch) =>
    Object.values(batch)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredBatches.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredBatches.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-player');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="batch-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewBatch}>
          + Create Batch
        </button>
      </div>

      <table className="batch-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Batch Proficiency</th>
            <th>Batch Capacity</th>
            <th>Current Strength</th>
            <th>Monthly Fees</th>
            {hasPrivilege(userData, 'BATCH_LIST_MODIFY') && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((batch) => (
            <tr key={batch.id}>
              <td>{batch.name}</td>
              <td>{batch.proficiency}</td>
              <td>{batch.capacity}</td>
              <td>{batch.currentStrength}</td>
              <td>{batch.monthlyFees}</td>
              {hasPrivilege(userData, 'BATCH_LIST_MODIFY') && (
               <td className="action-buttons-player">
               <button
                 className="dots-btn-player"
                 onClick={() => toggleDropdown(batch.id)}
                 style={{
                   background: "none",
                   border: "none",
                   cursor: "pointer",
                   fontSize: "24px",
                 }}
               >
                 &#x22EE; {/* Unicode for vertical ellipsis (three dots) */}
               </button>
               {dropdownOpen === batch.id && (
                 <div className="dropdown-menu-player">
                   <button className="dropdown-item">Player List</button>
                   <button className="dropdown-item">Coach List</button>
                   <button className="dropdown-item">Attendance Book</button>
                   <button className="dropdown-item">Edit</button>
                   <button className="dropdown-item">Player Score</button>
                   <button className="dropdown-item">Reward</button>
                   <button className="dropdown-item">Disable</button>
                   
                   <button className="dropdown-item">Batch Player Payment Details</button>
                 </div>
               )}
             </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BatchList;

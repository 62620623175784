import React, { useState } from 'react';
import './Sms.css'; 
import { Link } from 'react-router-dom';

const Sms = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  return (
    <div className="sms-container">
       <div className="breadcrumb">
        <Link to="/tournamentList" className="breadcrumb-link">Tournament List</Link> &gt; Sms
      </div>
      <h2 className="sms-title">Send SMS</h2>
      <div className="sms-input-group">
        <label className="sms-label">Name:</label>
        <input
          type="text"
          className="sms-input"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
        />
      </div>
      <div className="sms-input-group">
        <label className="sms-label">Message:</label>
        <textarea
          className="sms-textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
          rows="4"
        />
      </div>
      <button
        className="sms-submit-button"
        onClick={() => alert(`Name: ${name}\nMessage: ${message}`)}
      >
        Submit
      </button>
    </div>
  );
};

export default Sms;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../sport/sport.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const SportList = () => {
    const { userData } = useUser();
    const [searchTerm, setSearchTerm] = useState('');
    const [sports, setSports] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
    const [items, setItems] = useState([]); // Your data (e.g., from API)

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to first page when items per page change
    };
    useEffect(() => {
        // Fetch sports data from the API with headers
        fetch('https://admin.stage.machaxi.com/api/admin/sports', {
            method: 'GET',
            headers: {
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token or other header values
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    console.log(data.success)
                    const formattedSports = data.data.sports.map((sport) => ({
                        id: sport.id,
                        name: sport.name,
                        description: sport.description,
                        area: sport.playingAreaName,
                        bookingAllowed: sport.allowEntireCourtBooking,
                        proficiencies: sport.proficiencies.join(', '),
                        enabled: sport.enabled,  // Added field
                        createdAt: sport.createdAt,  // Added field
                        updatedAt: sport.updatedAt,  // Added field
                        image: sport.image,  // Added field
                        courtBookingNotes: sport.courtBookingNotes,  // Added field
                        requestAllowed: sport.requestAllowed,  // Added field
                        isRegistrationFees: sport.isRegistrationFees,  // Added field
                        registrationFees: sport.registrationFees,  // Added field
                        renewalFees: sport.renewalFees,  // Added field
                        imageUrl: sport.imageUrl,
                    }));
                    setSports(formattedSports); // Set the formatted data to state
                }
            })
            .catch((error) => {
                console.error('Error fetching sports data:', error);
            });
    }, []);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSport, setSelectedSport] = useState(null);
    const [action, setAction] = useState('');
    const [formData, setFormData] = useState({ name: '', description: '', area: '', bookingAllowed: '', proficiencies: '' });
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const dropdownRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(sports.length / itemsPerPage);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCreateSport = () => {
        navigate('/newsport');
    };
    const handleEditSport = (sportId) => {
        const sport = sports.find(s => s.id === sportId);

        // Ensure 'proficiencies' is an array
        const proficiencies = sport.proficiencies ? sport.proficiencies.split(', ') : [];

        setSelectedSport(sport);
        setFormData({
            name: sport.name,
            description: sport.description,
            area: sport.area,
            bookingAllowed: sport.bookingAllowed,
            proficiencies: sport.proficiencies,
            enabled: sport.enabled,
            createdAt: sport.createdAt,
            updatedAt: sport.updatedAt,
            image: sport.image,
            courtBookingNotes: sport.courtBookingNotes,
            requestAllowed: sport.requestAllowed,
            isRegistrationFees: sport.isRegistrationFees,
            registrationFees: sport.registrationFees,
            renewalFees: sport.renewalFees,
            imageUrl: sport.imageUrl,
        });

        setAction('edit');
        setIsModalOpen(true);
        navigate('/newsport', { state: { action: 'edit', sport } });
        console.log("Sport sending", sport); // Check the sport data
    };



    const handleDeleteSport = (sportId) => {
        setSelectedSport(sports.find(sport => sport.id === sportId));
        setAction('delete');
        setIsModalOpen(true);
    };

    const confirmDelete = () => {
        const sportId = selectedSport.id;

        // Send DELETE request to the backend API
        fetch(`https://admin.stage.machaxi.com/api/admin/delete-sport/${sportId}`, {
            method: 'DELETE',
            headers: {
                'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your token or other header values
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete sport');
                }
                return response.json();
            })
            .then(data => {
                // Assuming the response returns a success message
                const updatedSports = sports.filter(sport => sport.id !== sportId);
                setSports(updatedSports);
                setIsModalOpen(false);
            })
            .catch(error => {
                console.error('Error deleting sport:', error);
                // Handle any error that occurs during the fetch
            });
    };

    const handleSaveEdit = () => {
        const updatedSports = sports.map(sport =>
            sport.id === selectedSport.id ? { ...sport, ...formData } : sport
        );
        setSports(updatedSports);
        setIsModalOpen(false);
    };

    const cancelAction = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const filteredSports = sports.filter((sport) => {
        const bookingAllowedText = sport.bookingAllowed ? 'yes' : 'no';
        return Object.values(sport)
            .concat(bookingAllowedText)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
    });


    const paginatedSports = filteredSports.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const toggleDropdown = (sportId) => {
        setDropdownOpen(dropdownOpen === sportId ? null : sportId); // Toggle dropdown visibility
    };

    const handleProgressAttribute = (sportId) => {
        navigate('/ProgresAttribute');
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const generatePageNumbers = () => {
        const pages = [];
        const totalPagesToShow = 5;

        if (totalPages <= totalPagesToShow) {
            // If total pages are less than the limit, show all
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Show first, last, and surrounding pages
            pages.push(1);
            if (currentPage > 3) pages.push('...');
            for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                pages.push(i);
            }
            if (currentPage < totalPages - 2) pages.push('...');
            pages.push(totalPages);
        }
        return pages;
    };


    const renderPagination = () => {
        return generatePageNumbers().map((page, index) =>
            typeof page === 'number' ? (
                <button
                    key={index}
                    className={`page-number ${currentPage === page ? 'active' : ''}`}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </button>
            ) : (
                <span key={index} className="pagination-dots">
                    {page}
                </span>
            )
        );
    };


    return (
        <div className="sport-list-container">


            <div className="search-bar">
                <div className="input-container">
                    <input
                        type="text"
                        id="searchInput"
                        placeholder=""
                        className={`search-input ${searchTerm ? "has-value" : ""}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <label htmlFor="searchInput" className="floating-label">
                        🔍 Search
                    </label>
                </div>
                <button className="create-button" onClick={handleCreateSport}>
                    + Create Sport
                </button>
            </div>
            <select className="items-per-page-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value={10}>10 items per page</option>
                <option value={25}>25 items per page</option>
                <option value={50}>50 items per page</option>
                <option value={75}>75 items per page</option>
            </select>
            <table className="sport-table">
                <thead>
                    <tr>
                        <th>Sport Name</th>
                        <th>Description</th>
                        <th>Playing Area</th>
                        <th>Entire Court Booking Allowed?</th>
                        <th>Proficiencies</th>
                        {hasPrivilege(userData, 'SPORT_LIST_MODIFY') && (<th>Action</th>)}
                    </tr>
                </thead>
                <tbody>
                    {paginatedSports.map((sport) => (
                        <tr key={sport.id}>
                            <td>{sport.name}</td>
                            <td>{sport.description}</td>
                            <td>{sport.area}</td>
                            <td>{sport.bookingAllowed ? 'Yes' : 'No'}</td>
                            <td>{sport.proficiencies}</td>
                            {hasPrivilege(userData, 'SPORT_LIST_MODIFY') && (<td className="sport-actions-column">
                                <button className="action-button" onClick={() => toggleDropdown(sport.id)}>
                                    &#x22EE;
                                </button>
                                {dropdownOpen === sport.id && (
                                    <div className="action-dropdown-menu" ref={dropdownRef}>
                                        <button onClick={() => handleEditSport(sport.id)}>Edit</button>
                                        <button onClick={() => handleDeleteSport(sport.id)}>Delete</button>
                                        <button onClick={() => handleProgressAttribute(sport.id)}>Progress Attribute</button>
                                    </div>
                                )}
                            </td>)}

                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    className="prev-button"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {renderPagination()}
                <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content" >
                        <button className="close-btn" onClick={cancelAction}>&times;</button>
                        <div>
                            <p>
                                Are you sure you want to delete the sport: {selectedSport.name}?
                            </p>
                            <div className="modal-actions">
                                <button className="confirm-btn-academy" onClick={confirmDelete}>
                                    Confirm Delete
                                </button>
                                <button className="cancel-btn-academy" onClick={cancelAction}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SportList;

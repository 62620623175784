import React from 'react'

const DailyAttendanceStatus = () => {
  return (
    <div>
      DailyAttendanceStatus
    </div>
  )
}

export default DailyAttendanceStatus

import React, { useState } from 'react';
import '../Challenges/challenges.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const BlockCourt = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [BlockCourt, setBlockCourt] = useState([
    {
      id: 1, // Add an id for each item to uniquely identify them
      courtName: "Court A",
      startDate: "2024-01-15",
      endDate: "2024-01-20",
      startTime: "09:00 AM",
      endTime: "11:00 AM",
      weekDays: "Mon, Wed, Fri",
      remark: "Reserved for training",
    },
    {
      id: 2,
      courtName: "Court B",
      startDate: "2024-02-10",
      endDate: "2024-02-15",
      startTime: "11:00 AM",
      endTime: "01:00 PM",
      weekDays: "Tue, Thu",
      remark: "Available for booking",
    },
    {
      id: 3,
      courtName: "Court C",
      startDate: "2024-03-01",
      endDate: "2024-03-05",
      startTime: "02:00 PM",
      endTime: "04:00 PM",
      weekDays: "Mon, Wed, Sat",
      remark: "Maintenance scheduled",
    },
    {
      id: 4,
      courtName: "Court D",
      startDate: "2024-04-10",
      endDate: "2024-04-15",
      startTime: "09:00 AM",
      endTime: "11:00 AM",
      weekDays: "Sun, Tue, Thu",
      remark: "Available for tournaments",
    },
  ]);

  const [editedChallenge, setEditedChallenge] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredBlockCourt = BlockCourt.filter((Court) =>
    Object.values(Court)
      .join(' ') // Join all fields into a single string
      .toLowerCase() // Case-insensitive comparison
      .includes(searchTerm.toLowerCase()) // Filter based on the search term
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChallenge((prevChallenge) => ({
      ...prevChallenge,
      [name]: value,
    }));
  };
  
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredBlockCourt.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredBlockCourt.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search Challenge"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <table className="challenges-table">
        <thead>
          <tr>
            <th>Court Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Week Days</th>
            <th>Remark</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((Court) => (
            <tr key={Court.id}>
              <td>{Court.courtName}</td>
              <td>{Court.startDate}</td>
              <td>{Court.endDate}</td>
              <td>{Court.startTime}</td>
              <td>{Court.endTime}</td>
              <td>{Court.weekDays}</td>
              <td>{Court.remark}</td>
              <td>
                <button className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BlockCourt;

import React, { useState } from 'react';
import './Challenge.css';

const CourtBooking = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [CourtBooking, setCourtBooking] = useState([
    { id: 1, academyName: 'Academy A', month: 'January', year: '2024', DisputePending: '1' ,cancelledBooking:'3'},
    { id: 2, academyName: 'Academy B', month: 'February', year: '2024', DisputePending: '2',cancelledBooking:'3' },
    { id: 3, academyName: 'Academy C', month: 'March', year: '2024', DisputePending: '3',cancelledBooking:'3' },
    { id: 4, academyName: 'Academy D', month: 'April', year: '2024', DisputePending: '4' ,cancelledBooking:'3'},
    { id: 5, academyName: 'Academy E', month: 'May', year: '2024', DisputePending: '5' ,cancelledBooking:'3'},
    { id: 6, academyName: 'Academy F', month: 'June', year: '2024', DisputePending: '6',cancelledBooking:'3' },
    { id: 7, academyName: 'Academy G', month: 'July', year: '2024', DisputePending: '7',cancelledBooking:'3' },
    { id: 8, academyName: 'Academy H', month: 'August', year: '2024', DisputePending: '8' ,cancelledBooking:'3'},
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCourtBooking = CourtBooking.filter((CourtBook) =>
    Object.values(CourtBook)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCourtBooking.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCourtBooking.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
      <h2 className="challenges-header">Court Bookings</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button">
          Export CSV
        </button>
      </div>
      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
            <th className="challenges-column">Academy Name</th>
            <th className="challenges-column">Month</th>
            <th className="challenges-column">Year</th>
            <th className="challenges-column">Total Bookings</th>
            <th className="challenges-column">Cancelled Bookings</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((CourtBook) => (
            <tr key={CourtBook.id} className="challenges-row">
              <td className="challenges-cell">{CourtBook.academyName}</td>
              <td className="challenges-cell">{CourtBook.month}</td>
              <td className="challenges-cell">{CourtBook.year}</td>
              <td className="challenges-cell">{CourtBook.DisputePending}</td>
              <td className="challenges-cell">{CourtBook.cancelledBooking}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CourtBooking

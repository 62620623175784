import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProgressAttribute.css';
import { Link } from 'react-router-dom';

const ProgressAttribute = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    // Define the headers
    const headers = {
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
    };

    // Fetch data from the API with headers
    fetch('https://admin.stage.machaxi.com/api/admin/sport/progress-attribute?sportId=1', {
      method: 'GET',  // default is GET, you can change if necessary
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const progressData = data.data.progressAttributes.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
          }));
          setProgress(progressData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateCourt = () => {
    navigate('/newProgress');
  };
  

  const handleEdit = () => {
    navigate('/newProgress');
  };
  
  const handleprogessparameter = () => {
    navigate('/ProgessParameter');
  };
  
  const handleProgessQa = () => {
    navigate('/AttributeQa');
  };

  const filteredProgress = progress.filter((prog) =>
    Object.values(prog)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredProgress.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredProgress.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const toggleDropdown = (courtId) => {
    setDropdownOpen(dropdownOpen === courtId ? null : courtId);
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.progressattribute-dropdown-menu');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.progressattribute-dots-btn')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="progressattribute-container">
      <div className="breadcrumb">
          <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; Progress Attribute
        </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleCreateCourt}>
          + Create Progress Attribute
        </button>
      </div>
      <table className="progressattribute-table">
        <thead>
          <tr className="progressattribute-header-row">
            <th className="progressattribute-column progressattribute-column-name">Name</th>
            <th className="progressattribute-column progressattribute-column-sport">Description</th>
            <th className="progressattribute-column progressattribute-column-action">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((prog) => (
            <tr key={prog.id} className="progressattribute-row">
              <td className="progressattribute-cell progressattribute-cell-name">{prog.name}</td>
              <td className="progressattribute-cell progressattribute-cell-sport">{prog.description}</td>
              <td className="progressattribute-cell progressattribute-cell-action">
                <button
                  className="progressattribute-dots-btn"
                  onClick={() => toggleDropdown(prog.id)}
                >
                  &#x22EE;
                </button>
                <div
                  className={`progressattribute-dropdown-menu ${
                    dropdownOpen === prog.id ? 'visible' : ''
                  }`}
                >
                  <button className="progressattribute-dropdown-item" onClick={handleEdit}>Edit</button>
                  <button className="progressattribute-dropdown-item" >Delete</button>
                  <button className="progressattribute-dropdown-item" onClick={handleprogessparameter}>Progress Parameter</button>
                  <button className="progressattribute-dropdown-item" onClick={handleProgessQa}>Progress QA</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProgressAttribute;

import React from 'react';
import './BankDetails.css';

const BankDetails = () => {
  return (
    <div className="update-bank-container">
      <div className="form-group">
        <label htmlFor="beneficiaryName">Beneficiary Name</label>
        <input
          type="text"
          id="beneficiaryName"
          placeholder="Enter Beneficiary Name"
          className="form-input"
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="accountNumber">Account Number</label>
        <input
          type="text"
          id="accountNumber"
          placeholder="Enter Account Number"
          className="form-input"
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="accountType">Account Type</label>
        <select
          id="accountType"
          className="form-input"
        >
          <option value="">Select Account Type</option>
          <option value="savings">Savings</option>
          <option value="current">Current</option>
        </select>
      </div>
      
      <div className="form-group">
        <label htmlFor="ifscCode">IFSC Code</label>
        <input
          type="text"
          id="ifscCode"
          placeholder="Enter IFSC Code"
          className="form-input"
        />
      </div>
      
      <button
        className="bank-button disabled"
      >
        Disable Bank Transfer
      </button>
    </div>
  );
};

export default BankDetails;

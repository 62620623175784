import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../UserRole/RoleList.css";

const RoleList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [action, setAction] = useState("");
  const [roleName, setRoleName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rolesPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get("https://admin.stage.machaxi.com/api/admin/role-list", {
          headers: {
            "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", // Replace with your token
            "Content-Type": "application/json",
          },
        });

        console.log("API Response:", response); 

        // Validate and set roles
        if (response?.data?.data?.roles && Array.isArray(response.data.data.roles)) {
          setRoles(response.data.data.roles); // Use the correct path to access roles
        } else {
          console.error("Roles data is missing or invalid in the response");
          setRoles([]); // Fallback to an empty array
        }
      } catch (error) {
        console.error("Error fetching roles:", error.message);
        setRoles([]); // Fallback to an empty array
      }
    };

    fetchRoles();
  }, []);


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateRole = () => {
    navigate("/create-role");
  };

  const handleEditRole = (roleId) => {
    // Find the role object that matches the selected roleId
    const role = roles.find((r) => r.id === roleId);
    if (role) {
      // Extract privileges from the role
      const privileges = role.privileges[0]; // Get the privileges object (first item in the array)

      // Map over the privileges object to format the sections and subsections
      const sectionsAndSubsections = Object.entries(privileges).map(([section, subsections]) => ({
        section,      
        subsections    
      }));
      console.log('section', sectionsAndSubsections)
      // Pass the role data to the "create-role" page, including sections and subsections
      navigate("/create-role", {
        state: {
          roleId: role.id,
          roleName: role.roleName,
          sectionsAndSubsections,  // Send sections and subsections
        },
      });
    }
  };
  const handleDeleteRole = (roleId) => {
    const role = roles.find((r) => r.id === roleId);
    if (role) {
      setSelectedRole(role);
      setAction("delete");
      setIsModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    try {
      // Include roleId as a query parameter
      const response = await axios.delete(
        `https://admin.stage.machaxi.com/api/admin/delete-role?roleId=${selectedRole.id}`, // roleId as query parameter
        {
          headers: {
            "x-authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ", // Replace with your token
            "Content-Type": "application/json",
          },
        }
      );
  
      // Check if the delete was successful
      if (response.status === 200) {
        const updatedRoles = roles.filter((role) => role.id !== selectedRole.id);
        setRoles(updatedRoles);
        setIsModalOpen(false);
        alert("Role deleted successfully"); // Show success message to user
      } else {
        console.error("Failed to delete the role");
        alert("Failed to delete the role. Please try again later."); // Show generic error to user
      }
    } catch (error) {
      // Handling specific errors
      if (error.response) {
        // Server responded with a status code outside 2xx range
        console.error("Error response:", error.response.data);
        alert(`Error: ${error.response.data.message || "An error occurred on the server."}`);
      } else if (error.request) {
        // No response was received from the server
        console.error("Error request:", error.request);
        alert("Network error: Unable to reach the server. Please check your internet connection.");
      } else {
        // Other errors (e.g., problems setting up the request)
        console.error("Error message:", error.message);
        alert(`An unexpected error occurred: ${error.message}`);
      }
  
      // Close the modal in case of error
      setIsModalOpen(false);
    }
  };
  
  
  const handleSaveEdit = () => {
    const updatedRoles = roles.map((role) =>
      role.id === selectedRole.id ? { ...role, name: roleName } : role
    );
    setRoles(updatedRoles);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const filteredRoles = roles.filter((role) =>
    role?.roleName?.toLowerCase().includes(searchTerm.toLowerCase())
  );


  // Pagination logic
  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = filteredRoles.reverse().slice(indexOfFirstRole, indexOfLastRole);

  const totalPages = Math.ceil(filteredRoles.length / rolesPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
// Display only a few pages in pagination
const generatePageNumbers = () => {
  const pages = [];
  const totalPagesToShow = 5;

  if (totalPages <= totalPagesToShow) {
    // If total pages are less than the limit, show all
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Show first, last, and surrounding pages
    pages.push(1);
    if (currentPage > 3) pages.push('...');
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      pages.push(i);
    }
    if (currentPage < totalPages - 2) pages.push('...');
    pages.push(totalPages);
  }
  return pages;
};

// Render Pagination
const renderPagination = () => {
  return generatePageNumbers().map((page, index) =>
    typeof page === 'number' ? (
      <button
        key={index}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ) : (
      <span key={index} className="pagination-dots">
        {page}
      </span>
    )
  );
};
console.log(selectedRole); 
  return (
    <div className="role-list-container">
  
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search roles "
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleCreateRole}>
          + Create Role
        </button>
      </div>

      <table className="role-table">
        <thead>
          <tr>
            {/* <th>Role Id</th> */}
            <th>Role Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentRoles.map((role) => (
            <tr key={role.id}>
              {/* <td>{role.id}</td> */}
              <td>{role.roleName}</td>
              <td className="action-buttons">
                <button
                  className="edit-btn"
                  onClick={() => handleEditRole(role.id)}
                >
                  Edit
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteRole(role.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          className="prev-btn"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-btn"
          onClick={nextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>
              &times;
            </button>
            <h2>{action === "edit" ? "Edit Role" : "Delete Role"}</h2>
            {action === "edit" ? (
              <div>
                <label>
                  Role Name:
                  <input
                    type="text"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </label>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={handleSaveEdit}>
                    Save Changes
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Are you sure you want to delete the role: {selectedRole?.roleName}
                </p>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={confirmDelete}>
                    Confirm Delete
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleList;
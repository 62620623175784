import React, { useState } from 'react';
import './Challenge.css';

const CouponsUsageReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [CouponsUsage, setCouponsUsage] = useState([
    {
      coupon: "SUMMER10",
      userName: "John Doe",
      mobileNumber: "+1234567890",
      usedFor: "Electronics",
      orderAmount: "$500",
      couponDiscount: "50",
    },
    {
      coupon: "WINTER20",
      userName: "Jane Smith",
      mobileNumber: "+1987654321",
      usedFor: "Fashion",
      orderAmount: "$300",
      couponDiscount: "60",
    },
    {
      coupon: "SPRING5",
      userName: "Emily Johnson",
      mobileNumber: "+1122334455",
      usedFor: "Home Decor",
      orderAmount: "$150",
      couponDiscount: "15",
    },
    {
      coupon: "NEWYEAR25",
      userName: "Michael Brown",
      mobileNumber: "+1444556677",
      usedFor: "Groceries",
      orderAmount: "$100",
      couponDiscount: "25",
    },
    {
      coupon: "FESTIVE15",
      userName: "Sarah Lee",
      mobileNumber: "+1777888999",
      usedFor: "Beauty Products",
      orderAmount: "$250",
      couponDiscount: "35",
    },
    {
      coupon: "FESTIVE15",
      userName: "Sarah Lee",
      mobileNumber: "+1777888999",
      usedFor: "Beauty Products",
      orderAmount: "$250",
      couponDiscount: "35",
    },
    {
      coupon: "FESTIVE15",
      userName: "Sarah Lee",
      mobileNumber: "+1777888999",
      usedFor: "Beauty Products",
      orderAmount: "$250",
      couponDiscount: "35",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCouponsUsage = CouponsUsage.filter((usage) =>
    Object.values(usage)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCouponsUsage.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCouponsUsage.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
      <h2 className="challenges-header">Coupons Usage Report</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button">
          Export CSV
        </button>
      </div>
      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
            <th className="challenges-column">Coupon</th>
            <th className="challenges-column">User Name</th>
            <th className="challenges-column">Mobile Number</th>
            <th className="challenges-column">Used For</th>
            <th className="challenges-column">Order Amonut</th>
            <th className="challenges-column">Coupon Discount</th>
           
          </tr>
        </thead>
        <tbody>
          {currentRows.map((usage) => (
            <tr key={usage.id} className="challenges-row">
              <td className="challenges-cell">{usage.coupon}</td>
              <td className="challenges-cell">{usage.userName}</td>
              <td className="challenges-cell">{usage.mobileNumber}</td>
              <td className="challenges-cell">{usage.usedFor}</td>
              <td className="challenges-cell">{usage.orderAmount}</td>
              <td className="challenges-cell">{usage.couponDiscount}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};


export default CouponsUsageReport

import React, { useState } from 'react';
import '../Academy/NewAcademy.css';
import { Link } from 'react-router-dom';
const EditProfile = () => {
  const [isParentAcademy, setIsParentAcademy] = useState(true);
  const [academy, setAcademy] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [about, setAbout] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [sport, setSport] = useState('');
  const [facility, setFacility] = useState('');
  const [locality, setLocality] = useState('');
  const [coverPicture, setCoverPicture] = useState(null);
  const [Trial,setTrial]=useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [maxActiveMembership, setMaxActiveMembership] = useState('');

  const handleParentAcademyChange = () => {
    setIsParentAcademy(!isParentAcademy);
    if (isParentAcademy) {
      setAcademy(''); // Clear the selected academy if unchecked
    }
  };

  const handleCoverPictureChange = (e) => {
    setCoverPicture(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name,
      email,
      password,
      about,
      mobile,
      address,
      sport,
      facility,
      locality,
      coverPicture,
      Trial,
      notificationsEnabled,
      maxActiveMembership,
      parentAcademy: isParentAcademy ? null : academy,
    };
    console.log(formData); // Replace with API call or further processing
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="academy-form">
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Parent Academy:</label>
          <input
            type="checkbox"
            checked={isParentAcademy}
            onChange={handleParentAcademyChange}
            className="form-checkbox"
          />
          {!isParentAcademy && (
            <div className="form-group">
              <label className="form-label">Academy:</label>
              <select
                value={academy}
                onChange={(e) => setAcademy(e.target.value)}
                required
                className="form-select"
              >
                <option value="">Select Academy</option>
                <option value="academy1">Academy 1</option>
                <option value="academy2">Academy 2</option>
                {/* Add more options as needed */}
              </select>
            </div>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">About:</label>
          <textarea
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            required
            className="form-textarea"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Mobile Number:</label>
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Address:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Sport:</label>
          <select
            value={sport}
            onChange={(e) => setSport(e.target.value)}
            required
            className="form-select"
          >
            <option value="">Select Sport</option>
            <option value="football">Football</option>
            <option value="badminton">Badminton</option>
            {/* Add more options as needed */}
          </select>
        </div>

        <div className="form-group">
          <label className="form-label">Facilities:</label>
          <select
            value={facility}
            onChange={(e) => setFacility(e.target.value)}
            required
            className="form-select"
          >
            <option value="">Select Facility</option>
            <option value="gym">Gym</option>
            <option value="court">Court</option>
            {/* Add more options as needed */}
          </select>
        </div>

        <div className="form-group">
          <label className="form-label">Locality:</label>
          <input
            type="text"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Cover Picture:</label>
          <input
            type="file"
            onChange={handleCoverPictureChange}
            required
            className="form-file"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Enable Notifications:</label>
          <input
            type="checkbox"
            checked={notificationsEnabled}
            onChange={() => setNotificationsEnabled(!notificationsEnabled)}
            className="form-checkbox"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Is available for Machaxi Trial:</label>
          <input
            type="checkbox"
            checked={Trial}
            onChange={() => setTrial(!Trial)}
            className="form-checkbox"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Max Active Playing Membership:</label>
          <input
            type="number"
            value={maxActiveMembership}
            onChange={(e) => setMaxActiveMembership(e.target.value)}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <button type="submit" className="form-submit-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};


export default EditProfile;

import React, { useState } from 'react';
import './Challenge.css';

const LeaveReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [LeaveReport, setLeaveReport] = useState([
    {
      playerName: "Alice Johnson",
      mobileNumber: "9876543210",
      parentMobileNumber: "9123456789",
      academyName: "Elite Academy",
      batchName: "Batch A",
      leaveCredit: "5 days",
      leaveDays: "2 days",
      amount: "$50",
      status: "Approved",
      appliedOn: "2024-01-10",
      missedSession: "2",
      startDate: "2024-01-12",
      endDate: "2024-01-14",
    },
    {
      playerName: "Bob Smith",
      mobileNumber: "9887766554",
      parentMobileNumber: "9034567890",
      academyName: "Pro Academy",
      batchName: "Batch B",
      leaveCredit: "10 days",
      leaveDays: "4 days",
      amount: "$80",
      status: "Pending",
      appliedOn: "2024-02-05",
      missedSession: "3",
      startDate: "2024-02-07",
      endDate: "2024-02-11",
    },
    {
      playerName: "Charlie Davis",
      mobileNumber: "9776677889",
      parentMobileNumber: "9156784321",
      academyName: "Super Academy",
      batchName: "Batch C",
      leaveCredit: "8 days",
      leaveDays: "5 days",
      amount: "$100",
      status: "Rejected",
      appliedOn: "2024-03-15",
      missedSession: "4",
      startDate: "2024-03-18",
      endDate: "2024-03-22",
    },
    {
      playerName: "David Brown",
      mobileNumber: "9654433221",
      parentMobileNumber: "9456123456",
      academyName: "Champion Academy",
      batchName: "Batch D",
      leaveCredit: "7 days",
      leaveDays: "3 days",
      amount: "$60",
      status: "Approved",
      appliedOn: "2024-04-03",
      missedSession: "2",
      startDate: "2024-04-05",
      endDate: "2024-04-08",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLeaveReport =LeaveReport.filter((Leave) =>
    Object.values(Leave)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredLeaveReport.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredLeaveReport.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container" style={{ maxWidth: '1100px', overflowY: 'auto' }}>
      <h2 className="challenges-header">Leave Report</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button">
          Export CSV
        </button>
      </div>
      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
            <th className="challenges-column">Player Name</th>
            <th className="challenges-column">Mobile Number</th>
            <th className="challenges-column">Parent Mobile Number</th>
            <th className="challenges-column">Academy Name</th>
            <th className="challenges-column">Batch Name</th>
            <th className="challenges-column">Leave Credit</th>
            <th className="challenges-column">Leave Days</th>
            <th className="challenges-column">Amount</th>
            <th className="challenges-column">Status</th>
            <th className="challenges-column">Applied On</th>
            <th className="challenges-column">Missed Session</th>
            <th className="challenges-column">Start Date</th>
            <th className="challenges-column">End Date</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((Leave) => (
            <tr key={Leave} className="challenges-row">
              <td className="challenges-cell">{Leave.playerName}</td>
              <td className="challenges-cell">{Leave.mobileNumber}</td>
              <td className="challenges-cell">{Leave.parentMobileNumber}</td>
              <td className="challenges-cell">{Leave.academyName}</td>
              <td className="challenges-cell">{Leave.batchName}</td>
              <td className="challenges-cell">{Leave.leaveCredit}</td>
              <td className="challenges-cell">{Leave.leaveDays}</td>
              <td className="challenges-cell">{Leave.amount}</td>
              <td className="challenges-cell">{Leave.status}</td>
              <td className="challenges-cell">{Leave.appliedOn}</td>
              <td className="challenges-cell">{Leave.missedSession}</td>
              <td className="challenges-cell">{Leave.startDate}</td>
              <td className="challenges-cell">{Leave.endDate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LeaveReport

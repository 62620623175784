import React, { useState } from 'react';
import '../Feedback/academyfeedback.css';
import { useUser } from '../context/UserContext'; 
import { hasPrivilege } from '../utils/hasPrivilege';

const AcademyFeedback = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [feedbacks, setFeedbacks] = useState([
    { id: 1, review: 'Great academy!', rating: 4, createdAt: '2024-11-15', givenBy: 'John Doe' },
    { id: 2, review: 'Very good facilities.', rating: 5, createdAt: '2024-11-16', givenBy: 'Jane Smith' },
    { id: 3, review: 'Needs improvement in swimming pool.', rating: 3, createdAt: '2024-11-17', givenBy: 'Sam Wilson' },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  // State for modal and edited feedback
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedFeedback, setEditedFeedback] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);  // State for delete confirmation modal
  const [feedbackToDelete, setFeedbackToDelete] = useState(null); // Feedback to delete

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFeedbacks = feedbacks.filter((feedback) =>
    Object.values(feedback)
      .join(' ') // Join all fields into a single string
      .toLowerCase() // Case-insensitive comparison
      .includes(searchTerm.toLowerCase()) // Filter based on the search term
  );

  const handleEditClick = (feedback) => {
    setEditedFeedback(feedback);  // Set the selected feedback to be edited
    setIsModalOpen(true);  // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setEditedFeedback(null); // Clear the edited feedback
  };

  const handleSaveChanges = () => {
    const updatedFeedbacks = feedbacks.map((feedback) =>
      feedback.id === editedFeedback.id ? editedFeedback : feedback
    );
    setFeedbacks(updatedFeedbacks); // Save changes to the feedbacks
    handleCloseModal(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleDeleteClick = (feedback) => {
    setFeedbackToDelete(feedback);  // Set the feedback to be deleted
    setIsDeleteModalOpen(true);  // Open the delete confirmation modal
  };

  const handleDeleteConfirm = () => {
    setFeedbacks(feedbacks.filter((feedback) => feedback.id !== feedbackToDelete.id));  // Delete the feedback
    setIsDeleteModalOpen(false);  // Close the delete modal
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);  // Close the delete modal
    setFeedbackToDelete(null);  // Clear the feedback to be deleted
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredFeedbacks.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredFeedbacks.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="academy-feedback-container">
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search Feedback"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <table className="academy-feedback-table">
        <thead>
          <tr>
            <th>Review</th>
            <th>Rating</th>
            <th>Created At</th>
            <th>Given By</th>
            {hasPrivilege(userData ,'ACADEMY_FEEDBACKS_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((feedback) => (
            <tr key={feedback.id}>
              <td>{feedback.review}</td>
              <td>{feedback.rating}</td>
              <td>{feedback.createdAt}</td>
              <td>{feedback.givenBy}</td>
              {hasPrivilege(userData ,'ACADEMY_FEEDBACKS_MODIFY') && (<td>
                <button className="edit-button" onClick={() => handleEditClick(feedback)}>Edit</button>
                <button className="delete-button" onClick={() => handleDeleteClick(feedback)}>Delete</button>
              </td>)}
              
            </tr>
          ))}
        </tbody>
      </table>
 {/* Pagination */}
       <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {/* Edit Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Edit Feedback</h2>
            <div className="modal-body">
              <textarea
                name="review"
                value={editedFeedback.review}
                onChange={handleInputChange}
              />
              <input
                type="number"
                name="rating"
                value={editedFeedback.rating}
                onChange={handleInputChange}
                min="1"
                max="5"
              />
              <input
                type="date"
                name="createdAt"
                value={editedFeedback.createdAt}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="givenBy"
                value={editedFeedback.givenBy}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-footer">
              <button className="save-button" onClick={handleSaveChanges}>Save</button>
              <button className="cancel-button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Are you sure you want to delete this feedback?</h2>
            <div className="modal-footer">
              <button className="confirm-button" onClick={handleDeleteConfirm}>Yes, Delete</button>
              <button className="cancel-button" onClick={handleDeleteCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcademyFeedback;

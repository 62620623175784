import React, { useState } from 'react';
import './Challenge.css';

const PlayTrailReport = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [PlayTrail, setPlayTrail] = useState([
    { name: "John Doe", phone: "123-456-7890", type: "Appointment", date: "2024-12-04", time: "10:00 AM" },
    { name: "Jane Smith", phone: "987-654-3210", type: "Meeting", date: "2024-12-05", time: "2:00 PM" },
    { name: "Alice Johnson", phone: "456-789-1234", type: "Event", date: "2024-12-06", time: "4:30 PM" },
    { name: "Bob Brown", phone: "789-123-4567", type: "Consultation", date: "2024-12-07", time: "1:15 PM" },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPlayTrail = PlayTrail.filter((play) => {
    const matchesSearch = Object.values(play)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  
    const matchesAcademy =
      selectedAcademy === '' || play.preferredCenter === selectedAcademy;
  
    const matchesDate =
      (fromDate === '' || new Date(play.date) >= new Date(fromDate)) &&
      (toDate === '' || new Date(play.date) <= new Date(toDate));
  
    return matchesSearch && matchesAcademy && matchesDate;
  });
  

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPlayTrail.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPlayTrail.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const renderPagination = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
      <button
        key={page}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ));
  };

  return (
    <div className="challenges-container">
      <div className="sales-filters">
        <select
          className="sales-filter-academy"
          value={selectedAcademy}
          onChange={(e) => setSelectedAcademy(e.target.value)}
        >
          <option value="">Select Academy</option>
          <option value="Elite Sports Academy">Elite Sports Academy</option>
          <option value="Pro Fitness Hub">Pro Fitness Hub</option>
          <option value="Champion's League">Champion's League</option>
        </select>

        <input
          type="date"
          className="sales-filter-from-date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          onFocus={(e) => e.target.showPicker()}

        />

        <input
          type="date"
          className="sales-filter-to-date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          onFocus={(e) => e.target.showPicker()}

        />
      </div>
      
        <h2 className="challenges-header">Play Trail Reports</h2>

        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="create-button">Export CSV</button>
        </div>
        <div style={{ maxWidth: '1100px', overflowY: 'auto' }}>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th>Name</th>
              <th>Phone</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((play) => (
              <tr key={play.id} >
                <td>{play.name}</td>
                <td>{play.phone}</td>
                <td>{play.type}</td>
                <td>{play.date}</td>
                <td>{play.time}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            className="prev-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {renderPagination()}
          <button
            className="next-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlayTrailReport

import React, { useState } from 'react';
import '../Challenges/challenges.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const AcademySport = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [AcademySport, setAcademySport] = useState([
    {
      sportName: 'Badminton',
      description: 'A fast-paced game played with rackets and shuttlecock.',
      bookingEnabled: true,
    },
    {
      sportName: 'Football',
      description: 'A team sport played with a round ball on a rectangular field.',
      bookingEnabled: false,
    },
    {
      sportName: 'Tennis',
      description: 'A racket sport played on a court with a net dividing the two players.',
      bookingEnabled: true,
    },
    {
      sportName: 'Basketball',
      description: 'A team sport where two teams compete to score by shooting a ball into a hoop.',
      bookingEnabled: true,
    },
  ]);

  const [editedChallenge, setEditedChallenge] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAcademySport = AcademySport.filter((Sport) =>
  Object.entries(Sport)
    .map(([key, value]) => {
      if (key === 'bookingEnabled') {
        return value ? 'Yes' : 'No';
      }
      return value; 
    })
    .join(' ')
    .toLowerCase()
    .includes(searchTerm.toLowerCase())
);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChallenge((prevChallenge) => ({
      ...prevChallenge,
      [name]: value,
    }));
  };
  
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredAcademySport.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAcademySport.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search Challenge"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <table className="challenges-table">
        <thead>
          <tr>
            <th>Sport Name</th>
            <th>Description</th>
            <th>Booking Enabled</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((Sport) => (
            <tr key={Sport.id}>
              <td>{Sport.sportName}</td>
              <td>{Sport.description}</td>
              <td>{Sport.bookingEnabled ? 'Yes' : 'No'}</td>
              <td>
                <button className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AcademySport;

import React, { useState } from 'react';
import '../sliders/NewSlider.css'; // Import the external CSS file
import { Link } from 'react-router-dom';

const NewProgress = () => {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      name: heading,
      description: subHeading,
    };

    try {
      setIsLoading(true);
      setErrorMessage('');
      setSuccessMessage('');

      const response = await fetch(
        'https://admin.stage.machaxi.com/api/admin/sports/1/new-progress-attribute',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      const data = await response.json();
      setSuccessMessage('Progress attribute created successfully!');
      alert('Progress attribute created successfully!');
      setHeading('');
      setSubHeading('');
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="new-slider-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; 
        <Link to="/ProgresAttribute" className="breadcrumb-link">Progres Attribute</Link> &gt; 
        New Progress Attribute
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Enter Name"
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <input
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter Description"
            required
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default NewProgress;

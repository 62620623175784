import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NewPlayer.css'
const NewPlayer = () => {
  const [mobileNumber, setMobileNumber] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("Searching for mobile number:", mobileNumber);
  };

  return (
    <div className="new-player-wrapper">
      <div className="breadcrumb">
        <Link to="/academyPlayerList" className="breadcrumb-link">Player List</Link> &gt; New Player
      </div>
      <form onSubmit={handleSearch} className="new-player-form">
        <div className="input-container">
          <label htmlFor="mobileNumber" className="mobile-label">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter mobile number"
            required
            className="mobile-input-field"
          />
        </div>
        <button type="submit" className="submit-search-button">Search</button>
      </form>
    </div>
  );
};

export default NewPlayer;

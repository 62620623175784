import React, { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import './CoachBatch.css';
// import './CoachBatch.css';  // You can create a specific CSS for this component or reuse the existing one.

const CoachBatch = () => {
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [coaches, setCoaches] = useState([
    { id: 1, name: 'Coach A', about: 'Experienced in football', experience: 5, batch: 'Batch A', headCoach: 'Yes' },
    { id: 2, name: 'Coach B', about: 'Specialized in basketball', experience: 4, batch: 'Batch B', headCoach: 'No' },
    { id: 3, name: 'Coach C', about: 'Expert in swimming', experience: 6, batch: 'Batch C', headCoach: 'Yes' },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ name: '', about: '', experience: '', batch: '', headCoach: '' });
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const toggleDropdown = (coachId) => {
    setDropdownOpen(dropdownOpen === coachId ? null : coachId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewCoach = () => {
    navigate('/createNewCoach');
  };

  const handleEditCoach = (coachId) => {
    const coach = coaches.find(c => c.id === coachId);
    setSelectedCoach(coach);
    setFormData({ ...coach });
    setAction('edit');
    setIsModalOpen(true);
  };

  const handleDeleteCoach = (coachId) => {
    setSelectedCoach(coaches.find(coach => coach.id === coachId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedCoaches = coaches.filter(coach => coach.id !== selectedCoach.id);
    setCoaches(updatedCoaches);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedCoaches = coaches.map(coach =>
      coach.id === selectedCoach.id ? { ...coach, ...formData } : coach
    );
    setCoaches(updatedCoaches);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredCoaches = coaches.filter((coach) =>
    Object.values(coach)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCoaches.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCoaches.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-player');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="coach-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewCoach}>
          + Create Coach
        </button>
      </div>

      <table className="coach-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>About</th>
            <th>Experience</th>
            <th>Batch</th>
            <th>Head Coach</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((coach) => (
            <tr key={coach.id}>
              <td>{coach.name}</td>
              <td>{coach.about}</td>
              <td>{coach.experience}</td>
              <td>{coach.batch}</td>
              <td>{coach.headCoach}</td>

              <td className="action-buttons-player">
                <button
                  className="dots-btn-player"
                  onClick={() => toggleDropdown(coach.id)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                >
                  &#x22EE; {/* Unicode for vertical ellipsis (three dots) */}
                </button>
                {dropdownOpen === coach.id && (
                  <div className="dropdown-menu-player">
                    <button className="dropdown-item">Attendance </button>
                    <button className="dropdown-item">Feedback</button>
                    <button className="dropdown-item">Edit</button>
                    <button className="dropdown-item">Remove</button>
                  </div>
                )}
              </td>

            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CoachBatch;

import React, { useState } from 'react';
import './RazorPayauth.css'; // Import the CSS file

const RazorPayauth = () => {
  const [orderType, setOrderType] = useState('');
  const [dueOrderId, setDueOrderId] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentId, setPaymentId] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({
      orderType,
      dueOrderId,
      amount,
      paymentId,
    });
  };

  return (
    <div className="razorpayauth-container">
      <h3>Razorpay Authorization</h3>
      <form onSubmit={handleSubmit} className="razorpayauth-form">
        <div className="input-group">
          <label htmlFor="orderType">Order Type *</label>
          <select
            id="orderType"
            value={orderType}
            onChange={(e) => setOrderType(e.target.value)}
            required
          >
            <option value="">Select Order Type</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
            <option value="subscription">Subscription</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="dueOrderId">Due Order ID *</label>
          <input
            type="text"
            id="dueOrderId"
            value={dueOrderId}
            onChange={(e) => setDueOrderId(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="paymentId">Razorpay Payment ID *</label>
          <input
            type="text"
            id="paymentId"
            value={paymentId}
            onChange={(e) => setPaymentId(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default RazorPayauth;

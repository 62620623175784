import React, { useState } from 'react';
import './NewReferee.css';
import { Link } from 'react-router-dom';

const NewReferee = () => {
  const [referees, setReferees] = useState([
    { name: '', mobile: '', gender: '' }
  ]);

  const handleInputChange = (index, event) => {
    const newReferees = [...referees];
    const { name, value } = event.target;

    // For name field: Allow only letters and spaces
    if (name === "name") {
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetical characters
      newReferees[index][name] = filteredValue;
    } 
    // For mobile field: Allow only digits
    else if (name === "mobile") {
      const filteredValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      newReferees[index][name] = filteredValue;
    } 
    // For gender field: No validation needed
    else {
      newReferees[index][name] = value;
    }

    setReferees(newReferees);
  };


  const handleRemoveRow = (index) => {
    const newReferees = referees.filter((_, i) => i !== index);
    setReferees(newReferees);
  };

  const handleAddRow = () => {
    setReferees([...referees, { name: '', mobile: '', gender: '' }]);
  };

  const handleSave = () => {
    // Handle save logic (e.g., API call)
    alert("refrees data saved")
    console.log("Referees data saved:", referees);
  };

  return (
    <div className="new-refree-container">
        <div className="breadcrumb">
        <Link to="/tournamentList" className="breadcrumb-link">Tournament List</Link> &gt; 
        <Link to="/Referee" className="breadcrumb-link">Referee List</Link> &gt; New Referee
      </div>
      <h2 className="new-refree-title">New Refree</h2>
      
      <button className="add-row-btn" onClick={handleAddRow}>Add Row</button>
      
      <table className="referee-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>Gender</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {referees.map((referee, index) => (
            <tr key={index}>
              <td>
                <input
                  className="input-field"
                  type="text"
                  name="name"
                  value={referee.name}
                  onChange={(event) => handleInputChange(index, event)}
                  placeholder="Enter name"
                />
              </td>
              <td>
                <input
                  className="input-field"
                  type="text"
                  name="mobile"
                  value={referee.mobile}
                  onChange={(event) => handleInputChange(index, event)}
                  placeholder="Enter mobile number"
                  maxLength={10}
                />
              </td>
              <td>
                <select
                  className="input-field"
                  name="gender"
                  value={referee.gender}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value="">Select gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </td>
              <td>
              <button className="remove-btn" onClick={() => handleRemoveRow(index)}>Remove</button>
                {/* {index !== 0 && (
                  <button className="remove-btn" onClick={() => handleRemoveRow(index)}>Remove</button>
                )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="save-btn" onClick={handleSave}>Save</button>
    </div>
  );
};

export default NewReferee;

import React, { useState } from 'react';
import './SearchUser.css'; // Import the CSS file for styling

const SearchUser = () => {
  const [mobileNumber, setMobileNumber] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    // Handle the search functionality here (e.g., API call)
    console.log("Searching for mobile number:", mobileNumber);
  };

  return (
    <div className="searchuser-container">
      <h3>Search User</h3>
      <form onSubmit={handleSearch} className="searchuser-form">
        <div className="input-group">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter mobile number"
            required
          />
        </div>
        <button type="submit" className="search-btn">Search</button>
      </form>
    </div>
  );
};

export default SearchUser;

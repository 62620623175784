import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import './SliderList.css';

const SliderList = () => {
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [academies, setAcademies] = useState([
    { id: 1, heading: 'Academy A', subheading: 'Top sports academy' },
    { id: 2, heading: 'Academy B', subheading: 'Focus on fitness and wellness' },
    { id: 3, heading: 'Academy C', subheading: 'Premier cricket academy' },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ heading: '', subheading: '' });
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewSlider = () => {
    navigate('/newslider');
  };

  const handleEditAcademy = () => {
   navigate('/newslider')
  };

  const handleDeleteAcademy = (academyId) => {
    setSelectedAcademy(academies.find(academy => academy.id === academyId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedAcademies = academies.filter(academy => academy.id !== selectedAcademy.id);
    setAcademies(updatedAcademies);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedAcademies = academies.map(academy =>
      academy.id === selectedAcademy.id ? { ...academy, ...formData } : academy
    );
    setAcademies(updatedAcademies);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredAcademies = academies.filter((academy) =>
    Object.values(academy)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredAcademies.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAcademies.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="slider-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewSlider}>
          + Create Slider
        </button>
      </div>

      <table className="academy-table">
        <thead>
          <tr>
            <th>Heading</th>
            <th>Subheading</th>
            {hasPrivilege(userData, 'SLIDER_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((academy) => (
            <tr key={academy.id}>
              <td>{academy.heading}</td>
              <td>{academy.subheading}</td>
              {hasPrivilege(userData, 'SLIDER_LIST_MODIFY') && (<td className="action-buttons">
                <button className="edit-btn" onClick={() => handleEditAcademy(academy.id)}>Edit</button>
                <button className="delete-btn" onClick={() => handleDeleteAcademy(academy.id)}>Delete</button>
              </td>)}

            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" >
            <button className="close-btn" onClick={cancelAction}>&times;</button>
            <div>
              <p>
                Are you sure you want to delete the Slider?
              </p>
              <div className="modal-actions">
                <button className="confirm-btn-academy" onClick={confirmDelete}>
                  Confirm Delete
                </button>
                <button className="cancel-btn-academy" onClick={cancelAction}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};



export default SliderList;

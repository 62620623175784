import React, { useState, useEffect } from 'react';
import './PaymentList.css';

const PaymentList = () => {
  
  const [payments, setPayments] = useState([
    {
      id: 1,
      academyName: 'Elite Sports Academy',
      paymentType: 'Monthly',
      paymentMethod: 'Credit Card',
      paidBy: 'John Doe',
      amountPaid: '$500',
      commission: '$50',
      gatewayCharges: '$10',
      amountTransferred: '$440',
      paymentDate: '2024-11-20',
      status: 'Completed',
      dateOfPaymentTransfer: '2024-11-21',
      referenceID: 'ABC12345',
      playerName: 'Sam Smith',
      playerMobile: '9876543210',
      parentMobile: '8765432109',
      remark: 'First payment for Batch A',
      batch: 'Batch A',
      term: 'Term 1',
      amount: '$500',
    },
    {
      id: 2,
      academyName: 'Champions Academy',
      paymentType: 'Quarterly',
      paymentMethod: 'Bank Transfer',
      paidBy: 'Jane Doe',
      amountPaid: '$1500',
      commission: '$150',
      gatewayCharges: '$20',
      amountTransferred: '$1330',
      paymentDate: '2024-11-18',
      status: 'Pending',
      dateOfPaymentTransfer: '-',
      referenceID: 'XYZ67890',
      playerName: 'Emma Brown',
      playerMobile: '9123456789',
      parentMobile: '9823456712',
      remark: 'Quarterly payment for Batch B',
      batch: 'Batch B',
      term: 'Term 2',
      amount: '$1500',
    },
    // Add more payment data as needed
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [academyFilter, setAcademyFilter] = useState('All');
  const [paymentMethodFilter, setPaymentMethodFilter] = useState('');
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const academyNames = ['All', ...new Set(payments.map(payment => payment.academyName))];
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleAcademyFilterChange = (e) => {
    setAcademyFilter(e.target.value);
  };

  const handlePaymentMethodFilterChange = (e) => {
    setPaymentMethodFilter(e.target.value);
  };

  const handlePaymentStatusFilterChange = (e) => {
    setPaymentStatusFilter(e.target.value);
  };

  const handlePaymentTypeFilterChange = (e) => {
    setPaymentTypeFilter(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDateFilter(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDateFilter(e.target.value);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen !== null && !event.target.closest('.dropdown-container')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredPayments = payments.filter((payment) => {
    return (
      (academyFilter === 'All' || payment.academyName === academyFilter) &&
      (payment.paymentMethod.toLowerCase().includes(paymentMethodFilter.toLowerCase())) &&
      (payment.status.toLowerCase().includes(paymentStatusFilter.toLowerCase())) &&
      (payment.paymentType.toLowerCase().includes(paymentTypeFilter.toLowerCase())) &&
      (fromDateFilter ? new Date(payment.paymentDate) >= new Date(fromDateFilter) : true) &&
      (toDateFilter ? new Date(payment.paymentDate) <= new Date(toDateFilter) : true) &&
      Object.values(payment).some((value) => value.toString().toLowerCase().includes(searchTerm))
    );
  });


  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPayments.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPayments.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  // Calculate total revenue and number of payments
  const totalRevenue = payments.reduce((acc, payment) => acc + parseFloat(payment.amount.replace('$', '').replace(',', '')), 0).toFixed(2);
  const numberOfPayments = payments.length;

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };


  return (
    <div className="payment-list-container" style={{ maxWidth: '1100px', overflowY: 'auto' }}>
      {/* Filter Section */}
      <div className="filter-container">
        <select onChange={handleAcademyFilterChange} value={academyFilter}>
          {academyNames.map((academyName, index) => (
            <option key={index} value={academyName}>
              {academyName}
            </option>
          ))}
          {/* Add more academy options here */}
        </select>

        <select onChange={handlePaymentMethodFilterChange} value={paymentMethodFilter}>
          <option value="">All Payment Methods</option>
          <option value="Credit Card">Credit Card</option>
          <option value="Bank Transfer">Bank Transfer</option>
          {/* Add more payment methods here */}
        </select>

        <select onChange={handlePaymentStatusFilterChange} value={paymentStatusFilter}>
          <option value="">All Status</option>
          <option value="Completed">Completed</option>
          <option value="Pending">Pending</option>
          {/* Add more statuses here */}
        </select>

        <select onChange={handlePaymentTypeFilterChange} value={paymentTypeFilter}>
          <option value="">All Payment Types</option>
          <option value="Monthly">Monthly</option>
          <option value="Quarterly">Quarterly</option>
          {/* Add more payment types here */}
        </select>

        <input
          type="date"
          value={fromDateFilter}
          onChange={handleFromDateChange}
          placeholder="From Date"
          onClick={(e) => e.target.showPicker()}
        />

        <input
          type="date"
          value={toDateFilter}
          onChange={handleToDateChange}
          placeholder="To Date"
          onClick={(e) => e.target.showPicker()}
        />
      </div>
      {/* Payment Report Summary */}
      <div className="payment-summary">
        <p><strong>Payment Reports</strong></p>
        <p>Total Revenue: ₹{totalRevenue}</p>
        <p>Number of Payments: {numberOfPayments}</p>
      </div>

      <div className="search-create-container">
        <input
          type="text"
          placeholder="🔍 Search"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
          <button className="create-button">
          Export CSV
        </button>
      </div>
      <div className="payment-list-scroll-container">
        <table className="payment-list-table">
          <thead>
            <tr>
              <th>Academy Name</th>
              <th>Payment Type</th>
              <th>Payment Method</th>
              <th>Paid By</th>
              <th>Amount Paid</th>
              <th>Commission</th>
              <th>Gateway Charges</th>
              <th>Amount Transferred</th>
              <th>Payment Date</th>
              <th>Status</th>
              <th>Date of Payment Transfer</th>
              <th>Reference ID</th>
              <th>Player Name</th>
              <th>Player Mobile Number</th>
              <th>Parent Mobile Number</th>
              <th>Remark</th>
              <th>Batch</th>
              <th>Term</th>
              <th>Amount</th>
             
            </tr>
          </thead>
          <tbody>
            {currentRows.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.academyName}</td>
                <td>{payment.paymentType}</td>
                <td>{payment.paymentMethod}</td>
                <td>{payment.paidBy}</td>
                <td>{payment.amountPaid}</td>
                <td>{payment.commission}</td>
                <td>{payment.gatewayCharges}</td>
                <td>{payment.amountTransferred}</td>
                <td>{payment.paymentDate}</td>
                <td>{payment.status}</td>
                <td>{payment.dateOfPaymentTransfer}</td>
                <td>{payment.referenceID}</td>
                <td>{payment.playerName}</td>
                <td>{payment.playerMobile}</td>
                <td>{payment.parentMobile}</td>
                <td>{payment.remark}</td>
                <td>{payment.batch}</td>
                <td>{payment.term}</td>
                <td>{payment.amount}</td>
               
              </tr>
            ))}
          </tbody>
        </table>

      </div>
     {/* Pagination */}
     <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaymentList;
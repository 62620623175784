// LogoutPage.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const LogoutPage = () => {
  const navigate = useNavigate();
  const { setSelectedCenter, setUser } = useUser();

  
  useEffect(() => {
    const performLogout = async () => {
      try {
        // Clear login status from localStorage
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userData');
        localStorage.removeItem('selectedCenter');

        // Reset the context state
        setSelectedCenter(null);
        setUser(null);

        // Redirect to the Login page
        navigate('/login');
      } catch (error) {
        console.error('Error during logout:', error);

        // Optional: Provide fallback behavior, e.g., stay on the current page or notify the user
        alert('An error occurred during logout. Please try again.');
      }
    };

    performLogout();
  }, [navigate, setSelectedCenter, setUser]);

  return null; // No UI needed, just a redirect
};

export default LogoutPage;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PlayerBatch.css';

const PlayerBatch = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [players, setPlayers] = useState([
    {
      id: 1,
      name: 'Player A',
      parentName: 'Parent A',
      category: 'Football',
      level: 'Intermediate',
      mobileNumber: '1234567890',
      parentNumber: '0987654321',
      dob: '2000-01-01',
      batch: 'Batch A',
      primary: 'Yes',
      status: 'Active',
    },
    {
      id: 2,
      name: 'Player B',
      parentName: 'Parent B',
      category: 'Basketball',
      level: 'Beginner',
      mobileNumber: '1234567891',
      parentNumber: '0987654322',
      dob: '2001-02-02',
      batch: 'Batch B',
      primary: 'No',
      status: 'Inactive',
    },
    {
      id: 3,
      name: 'Player C',
      parentName: 'Parent C',
      category: 'Swimming',
      level: 'Advanced',
      mobileNumber: '1234567892',
      parentNumber: '0987654323',
      dob: '1999-03-03',
      batch: 'Batch C',
      primary: 'Yes',
      status: 'Active',
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    parentName: '',
    category: '',
    level: '',
    mobileNumber: '',
    parentNumber: '',
    dob: '',
    batch: '',
    primary: '',
    status: '',
  });

  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (playerId) => {
    setDropdownOpen(dropdownOpen === playerId ? null : playerId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewPlayer = () => {
    navigate('/createNewPlayer');
  };

  const handleEditPlayer = (playerId) => {
    const player = players.find((p) => p.id === playerId);
    setSelectedPlayer(player);
    setFormData({ ...player });
    setAction('edit');
    setIsModalOpen(true);
  };

  const handleDeletePlayer = (playerId) => {
    setSelectedPlayer(players.find((player) => player.id === playerId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedPlayers = players.filter((player) => player.id !== selectedPlayer.id);
    setPlayers(updatedPlayers);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedPlayers = players.map((player) =>
      player.id === selectedPlayer.id ? { ...player, ...formData } : player
    );
    setPlayers(updatedPlayers);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredPlayers = players.filter((player) =>
    Object.values(player)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPlayers.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPlayers.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-player');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="player-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewPlayer}>
          + Create Player
        </button>
      </div>

      <table className="player-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Parent Name</th>
            <th>Category</th>
            <th>Level</th>
            <th>Mobile Number</th>
            <th>Parent's Number</th>
            <th>Date of Birth</th>
            <th>Batch</th>
            <th>Primary</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((player) => (
            <tr key={player.id}>
              <td>{player.name}</td>
              <td>{player.parentName}</td>
              <td>{player.category}</td>
              <td>{player.level}</td>
              <td>{player.mobileNumber}</td>
              <td>{player.parentNumber}</td>
              <td>{player.dob}</td>
              <td>{player.batch}</td>
              <td>{player.primary}</td>
              <td>{player.status}</td>

              <td className="action-buttons-player">
                <button
                  className="dots-btn-player"
                  onClick={() => toggleDropdown(player.id)}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '24px',
                  }}
                >
                  &#x22EE; {/* Unicode for vertical ellipsis (three dots) */}
                </button>
                {dropdownOpen === player.id && (
                  <div className="dropdown-menu-player">
                    <button className="dropdown-item" >Attendance</button>
                    <button className="dropdown-item" >Performance</button>
                    <button className="dropdown-item" >Feedback</button>
                    <button className="dropdown-item" >Rewards</button>
                    <button className="dropdown-item" >Update Player Payment </button>
                    <button className="dropdown-item" >Remove</button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlayerBatch;

import React, { useState } from 'react';
import '../Payment/summary.css';

const Summary = () => {
  // Sample data including payment method, payment type, and date
  const [summaries, setSummaries] = useState([
    { id: 1, academyName: 'Academy A', amountReceived: 5000, amountPaid: 3000, amountDues: 2000, paymentMethod: 'Cash', paymentType: 'Received', date: '2024-11-01' },
    { id: 2, academyName: 'Academy B', amountReceived: 4000, amountPaid: 2500, amountDues: 1500, paymentMethod: 'Card', paymentType: 'Paid', date: '2024-11-10' },
    { id: 3, academyName: 'Academy C', amountReceived: 6000, amountPaid: 4000, amountDues: 2000, paymentMethod: 'Cash', paymentType: 'Received', date: '2024-11-15' },
  ]);

  const [searchTerm, setSearchTerm] = useState('');
  const [academyFilter, setAcademyFilter] = useState('All');
  const [paymentMethodFilter, setPaymentMethodFilter] = useState('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAcademyChange = (event) => {
    setAcademyFilter(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethodFilter(event.target.value);
  };

  const handlePaymentTypeChange = (event) => {
    setPaymentTypeFilter(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  // Filtering logic
  const filteredSummaries = summaries.filter((summary) => {
    const matchesSearchTerm =
      summary.academyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      summary.amountReceived.toString().includes(searchTerm) ||
      summary.amountPaid.toString().includes(searchTerm) ||
      summary.amountDues.toString().includes(searchTerm);

    const matchesAcademy = academyFilter === 'All' || summary.academyName === academyFilter;

    const matchesPaymentMethod = !paymentMethodFilter || summary.paymentMethod === paymentMethodFilter;

    const matchesPaymentType = !paymentTypeFilter || summary.paymentType === paymentTypeFilter;

    const matchesDateRange =
      (!fromDate || new Date(summary.date) >= new Date(fromDate)) &&
      (!toDate || new Date(summary.date) <= new Date(toDate));

    return matchesSearchTerm && matchesAcademy && matchesPaymentMethod && matchesPaymentType && matchesDateRange;
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredSummaries.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredSummaries.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="summary-container">
       {/* Academy Filter */}
       <div className="filter">
          <select value={academyFilter} onChange={handleAcademyChange}>
            <option value="All">All Academies</option>
            <option value="Academy A">Academy A</option>
            <option value="Academy B">Academy B</option>
            <option value="Academy C">Academy C</option>
          </select>
       
          <select value={paymentMethodFilter} onChange={handlePaymentMethodChange}>
            <option value="">Payment Method</option>
            <option value="Cash">Cash</option>
            <option value="Card">Card</option>
          </select>
        
          <select value={paymentTypeFilter} onChange={handlePaymentTypeChange}>
            <option value="">Payment Type</option>
            <option value="Received">Received</option>
            <option value="Paid">Paid</option>
          </select>
        
          <input type="date" value={fromDate}   onClick={(e) => e.target.showPicker()} onChange={handleFromDateChange} />
        
          <input type="date" value={toDate}    onClick={(e) => e.target.showPicker()}onChange={handleToDateChange} />
        
        </div>


      {/* Filters */}
      <div className="filter-row">
        {/* Search bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {/* Table */}
      <table className="summary-table">
        <thead>
          <tr>
            <th>Academy Name</th>
            <th>Amount Received</th>
            <th>Amount Paid</th>
            <th>Amount Dues</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map(summary => (
            <tr key={summary.id}>
              <td>{summary.academyName}</td>
              <td>{summary.amountReceived}</td>
              <td>{summary.amountPaid}</td>
              <td>{summary.amountDues}</td>
            </tr>
          ))}
        </tbody>
      </table>
       {/* Pagination */}
       <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Summary;

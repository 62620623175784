import React, { useState } from 'react';
import './Notification.css'; // Assuming you have a corresponding CSS file

const Notification = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [notifications, setNotifications] = useState([
    { id: 1, name: 'Batch A', proficiency: 'Beginner', players: 15, monthlyFee: 100 },
    { id: 2, name: 'Batch B', proficiency: 'Intermediate', players: 10, monthlyFee: 150 },
    { id: 3, name: 'Batch C', proficiency: 'Advanced', players: 8, monthlyFee: 200 },
  ]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle notification selection
  const handleNotificationSelect = (notificationId) => {
    const updatedSelection = selectedNotifications.includes(notificationId)
      ? selectedNotifications.filter(id => id !== notificationId)
      : [...selectedNotifications, notificationId];
    setSelectedNotifications(updatedSelection);
  };

  // Handle "Select All" functionality
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(notifications.map(notification => notification.id));
    }
    setSelectAll(!selectAll);
  };

  const filteredNotifications = notifications.filter((notification) =>
  notification.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  notification.proficiency.toLowerCase().includes(searchTerm.toLowerCase()) ||
  String(notification.players).includes(searchTerm) ||
  String(notification.monthlyFee).includes(searchTerm)
);

// Pagination calculations
const indexOfLastRow = currentPage * rowsPerPage;
const indexOfFirstRow = indexOfLastRow - rowsPerPage;
const currentRows = filteredNotifications.slice(indexOfFirstRow, indexOfLastRow);
const totalPages = Math.ceil(filteredNotifications.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prevPage => prevPage - 1);
  };

  // Generate page numbers for pagination
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="notification-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
         <button className="create-button">
          Next
        </button>
      </div>

      <table className="notification-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>Name</th>
            <th>Batch Proficiency</th>
            <th>Number of Players</th>
            <th>Monthly Fee</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((notification) => (
            <tr key={notification.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedNotifications.includes(notification.id)}
                  onChange={() => handleNotificationSelect(notification.id)}
                />
              </td>
              <td>{notification.name}</td>
              <td>{notification.proficiency}</td>
              <td>{notification.players}</td>
              <td>{notification.monthlyFee}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Notification;

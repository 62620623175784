import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CancelBatchList.css';

const CancelBatchList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [batches, setBatches] = useState([
    { id: 1, batchName: 'Batch A', cancelledOn: '2024-12-01', cancellationReason: 'Low enrollment' },
    { id: 2, batchName: 'Batch B', cancelledOn: '2024-12-02', cancellationReason: 'Instructor unavailability' },
    { id: 3, batchName: 'Batch C', cancelledOn: '2024-12-03', cancellationReason: 'Scheduling conflict' },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [action, setAction] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const navigate = useNavigate();

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle batch selection
  const handleBatchSelect = (batchId) => {
    const updatedSelection = selectedBatches.includes(batchId)
      ? selectedBatches.filter(id => id !== batchId)
      : [...selectedBatches, batchId];
    setSelectedBatches(updatedSelection);
  };

  // Handle "Select All" functionality
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedBatches([]);
    } else {
      setSelectedBatches(batches.map(batch => batch.id));
    }
    setSelectAll(!selectAll);
  };

  // Open modal for editing a batch
  const handleEditBatch = (batchId) => {
    const batch = batches.find(b => b.id === batchId);
    setSelectedBatch(batch);
    setAction('edit');
    setIsModalOpen(true);
  };

  // Open modal for deleting a batch
  const handleDeleteBatch = (batchId) => {
    setSelectedBatch(batches.find(batch => batch.id === batchId));
    setAction('delete');
    setIsModalOpen(true);
  };

  // Confirm batch deletion
  const confirmDelete = () => {
    const updatedBatches = batches.filter(batch => batch.id !== selectedBatch.id);
    setBatches(updatedBatches);
    setIsModalOpen(false);
  };

  const filteredBatches = batches.filter(batch => 
    batch.batchName.toLowerCase().includes(searchTerm.toLowerCase()) || 
    batch.cancelledOn.toLowerCase().includes(searchTerm.toLowerCase()) || 
    batch.cancellationReason.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredBatches.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredBatches.length / rowsPerPage);
  

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prevPage => prevPage - 1);
  };

  // Generate page numbers for pagination
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const handleUncancel = () => {
    navigate('/newslider');
  };
  return (
    <div className="cancel-batch-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleUncancel}>
          Uncancel
        </button>
      </div>

      <table className="cancel-batch-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>Batch Name</th>
            <th>Cancelled On</th>
            <th>Cancellation Reason</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((batch) => (
            <tr key={batch.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedBatches.includes(batch.id)}
                  onChange={() => handleBatchSelect(batch.id)}
                />
              </td>
              <td>{batch.batchName}</td>
              <td>{batch.cancelledOn}</td>
              <td>{batch.cancellationReason}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CancelBatchList;

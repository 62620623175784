import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Payment/dues.css';
import { useUser } from '../context/UserContext'; 
import { hasPrivilege } from '../utils/hasPrivilege';

const DuesList = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [dueWithFilter, setDueWithFilter] = useState('');
  const [playerFilter, setPlayerFilter] = useState('');
  const [batchFilter, setBatchFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const [dues, setDues] = useState([
    { id: 1, player: 'John Doe', mobile: '1234567890', parentMobile: '0987654321', batch: 'Batch A', amount: 500, dueOn: '2024-12-01', term: 'Annual', startDate: '2024-12-01', endDate: '2024-12-31' },
    { id: 2, player: 'Jane Smith', mobile: '9876543210', parentMobile: '0123456789', batch: 'Batch B', amount: 600, dueOn: '2024-11-25', term: 'Monthly', startDate: '2024-11-25', endDate: '2024-11-30' },
    { id: 3, player: 'Alice Johnson', mobile: '5555555555', parentMobile: '1111111111', batch: 'Batch C', amount: 450, dueOn: '2024-11-30', term: 'Quarterly', startDate: '2024-11-01', endDate: '2024-11-30' },
  ]);
  

  const [dropdownVisible, setDropdownVisible] = useState(null); // State to manage the visibility of dropdowns
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleDropdown = (id) => {
    setDropdownVisible((prev) => (prev === id ? null : id)); // Toggle the dropdown for the clicked row
  };

  const handleSettlePayment = (dueId) => {
    navigate('/settlePayment');
 // Close the dropdown after selecting an option
  };

   // Extract unique players and batches
   const playersList = [...new Set(dues.map((due) => due.player))];
   const batchesList = [...new Set(dues.map((due) => due.batch))];
 
   const handleDueWithFilterChange = (e) => {
     setDueWithFilter(e.target.value);
   };
 
   const handlePlayerFilterChange = (e) => {
     setPlayerFilter(e.target.value);
   };
 
   const handleBatchFilterChange = (e) => {
     setBatchFilter(e.target.value);
   };
 
   const handleFromDateChange = (e) => {
     setFromDateFilter(e.target.value);
   };
 
   const handleToDateChange = (e) => {
     setToDateFilter(e.target.value);
   };
 

   const filteredDues = dues.filter((due) => {
    const matchesDueWith = !dueWithFilter || (() => {
      const dueDate = new Date(due.dueOn);
      const today = new Date();
      if (dueWithFilter === 'Week') {
        const oneWeekLater = new Date();
        oneWeekLater.setDate(today.getDate() + 7);
        return dueDate >= today && dueDate <= oneWeekLater;
      }
      if (dueWithFilter === 'Month') {
        return dueDate.getMonth() === today.getMonth() && dueDate.getFullYear() === today.getFullYear();
      }
      if (dueWithFilter === 'Year') {
        return dueDate.getFullYear() === today.getFullYear();
      }
      return true;
    })();
  
    const matchesPlayer = !playerFilter || due.player === playerFilter;
    const matchesBatch = !batchFilter || due.batch === batchFilter;
    const matchesFromDate = !fromDateFilter || new Date(due.dueOn) >= new Date(fromDateFilter);
    const matchesToDate = !toDateFilter || new Date(due.dueOn) <= new Date(toDateFilter);
  
    return matchesDueWith && matchesPlayer && matchesBatch && matchesFromDate && matchesToDate;
  });
  
  // Calculate the total dues
  const totalDues = dues.reduce((acc, due) => acc + due.amount, 0);

  // Close the dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-dues');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.three-dots-btn')) {
        setDropdownVisible(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredDues.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredDues.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  
  return (
    <div className="dues-list-container">
       {/* Filters */}
       <div className="filter-container-dues">
        {/* Due With Filter */}
        <select onChange={handleDueWithFilterChange} value={dueWithFilter}>
          <option value="">Due With in</option>
          <option value="Week">Week</option>
          <option value="Month">Month</option>
          <option value="Year">Year</option>
        </select>

        {/* Player Filter */}
        <select onChange={handlePlayerFilterChange} value={playerFilter}>
          <option value="">Player</option>
          {playersList.map((player, index) => (
            <option key={index} value={player}>
              {player}
            </option>
          ))}
        </select>

        {/* Batch Filter */}
        <select onChange={handleBatchFilterChange} value={batchFilter}>
          <option value="">Batch</option>
          {batchesList.map((batch, index) => (
            <option key={index} value={batch}>
              {batch}
            </option>
          ))}
        </select>

        {/* From Date Filter */}
        <input
          type="date"
          value={fromDateFilter}
          onChange={handleFromDateChange}
          placeholder="From Date"
          onClick={(e) => e.target.showPicker()}
        />

        {/* To Date Filter */}
        <input
          type="date"
          value={toDateFilter}
          onChange={handleToDateChange}
          placeholder="To Date"
          onClick={(e) => e.target.showPicker()}
        />
      </div>

      {/* Row for search bar and total dues */}
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="total-dues">
          <strong>Total Dues: ₹{totalDues}</strong>
        </div>
        <button className="create-button" >
        Export CSV
        </button>
      </div>

      <table className="dues-table">
        <thead>
          <tr>
            <th>Player</th>
            <th>Mobile Number</th>
            <th>Parents Mobile Number</th>
            <th>Batch</th>
            <th>Dues Amount</th>
            <th>Due On</th>
            <th>Term</th>
            
            {hasPrivilege(userData ,'PAYMENT_DUES_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((due) => (
            <tr key={due.id}>
              <td>{due.player}</td>
              <td>{due.mobile}</td>
              <td>{due.parentMobile}</td>
              <td>{due.batch}</td>
              <td>{due.amount}</td>
              <td>{due.dueOn}</td>
              <td>
                {new Date(due.startDate).toLocaleDateString()} :{' '}
                {new Date(due.endDate).toLocaleDateString()}
              </td>
              {hasPrivilege(userData ,'PAYMENT_DUES_MODIFY') && (<td>
                <div className="action-menu">
                  <button onClick={() => toggleDropdown(due.id)} className="three-dots-btn">&#x22EE;</button>
                  {dropdownVisible === due.id && (
                    <div className="dropdown-menu-dues">
                      <button onClick={handleSettlePayment} className="dropdown-item">
                        Settle Payment
                      </button>
                    </div>
                  )}
                </div>
              </td>)}
            </tr>
          ))}
        </tbody>
      </table>
       {/* Pagination */}
       <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DuesList;

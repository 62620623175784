import React, { useState, useEffect ,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import '../UserRole/UserList.css';
import DeleteUserModal from '../modal/DeleteUserModal';

const UserList = () => {
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({ name: '', email: '', mobile: '', userType: '', roles: [] });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const dropdownRef = useRef(null);
  useEffect(() => {
    // Close dropdown on click outside
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null); // Close the dropdown
      }
    };

    if (dropdownOpen !== null) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownOpen]);
  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://admin.stage.machaxi.com/api/admin/assign-user-role', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with a valid token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("This is the user list " , data) ;
        setUsers(data.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
// Filter users based on search term
const filteredUsers = users.filter((user) =>
  Object.values(user).some((value) =>
    value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
);


  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredUsers.reverse().slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCreateUser = () => navigate('/create-user');

  // Handle dropdown toggle
  const toggleDropdown = (userId) => {
    setDropdownOpen((prev) => (prev === userId ? null : userId));
  };

  // Handle Edit button click
  const handleEditClick = (user) => {
    console.log("This is the user data " , user) ;
    navigate('/create-user', {
      state: {
        userId: user.id,
        name: user.name,
        email: user.email,
        mobile: user.mobile_number,
        userType: user.user_type,
        roles: user.roles,
      },
    });
    setDropdownOpen(null);
  };

  // Handle input change in Edit modal
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'roles') {
      // If the field is 'roles', handle it as an array
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.split(',').map((role) => role.trim()), // Handle comma-separated values
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  // Handle Delete button click
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
    setDropdownOpen(null);
  };

  // Confirm user deletion
  const handleConfirmDelete = async () => {
    if (!userToDelete) return;
  
    console.log('user to delete', userToDelete);  // Ensure you have the correct userId
  
    const apiUrlBase = 'https://admin.stage.machaxi.com/api/admin/delete-user'; // Use localhost for local dev
    const apiUrl = `${apiUrlBase}?userId=${userToDelete.id}`;
  
    try {
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with a valid token
        },
      });
  
      // Check if the response is ok (status 200)
      if (response.ok) {
        // Optionally read response text or parse as JSON
        const data = await response.text();
  
        if (data) {
          const jsonData = JSON.parse(data); // Parse as JSON if the body is not empty
          console.log('User deleted successfully:', jsonData); // Log the success response
        } else {
          console.log('User deleted successfully, but no data returned');
        }
  
        alert('User deleted successfully!'); 
        setShowDeleteModal(false)
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));
         // Show the success alert here
        // Perform any additional logic, like updating the UI or refetching the user list
  
      } else {
        const error = await response.json(); // Handle the error if response is not OK
        console.error('Failed to delete user:', error.message || error);
        alert('Failed to delete user. Please try again later.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('An error occurred while deleting the user.');
    }
  };
  
  
  
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const closeDeleteModal = () => setShowDeleteModal(false);

   // Display only a few pages in pagination
   const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  // Render Pagination
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="user-list-container">
      

      {/* Search Bar */}
      <div className="search-bar">
      <input
          type="text"
          placeholder=" 🔍 Search roles "
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleCreateUser}>
          + Create User
        </button>
      </div>

      {/* User Table */}
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>User Type</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.mobile_number || '-'}</td> {/* Handle if no mobile */}
                <td>{user.user_type}</td>
                <td>{user.roles.join(', ')}</td> {/* Join roles into a string */}
                <td className="action-buttons">
                  <div className="dropdown-container" ref={dropdownRef}>
                    <button
                      className="dropdown-trigger"
                      onClick={() => toggleDropdown(user.id)}
                    >
                      &#x22EE; {/* Vertical three dots */}
                    </button>
                    {dropdownOpen === user.id && (
                      <div
                        className="dropdown-user"
                        onMouseDown={(e) => e.stopPropagation()} // Prevent propagation for dropdown menu
                      >
                        <button onClick={() => handleEditClick(user)}>Edit</button>
                        <button onClick={() => handleDeleteClick(user)}>Delete</button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

       {/* Pagination */}
       <div className="pagination">
        <button
           className="prev-btn"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {showDeleteModal && (
        <DeleteUserModal
          userToDelete={userToDelete}
          handleConfirmDelete={handleConfirmDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
};

export default UserList;
import React, { useState } from 'react';
import './BatchCancellation.css';

const BatchCancellation = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [batches, setBatches] = useState([]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setToDate(''); // Reset 'toDate' when unchecked
  };

  const handleDateChange = (e) => {
    if (e.target.name === 'fromDate') {
      setFromDate(e.target.value);
    } else if (e.target.name === 'toDate') {
      setToDate(e.target.value);
    }
  };

  const handleListBatches = () => {
    if (fromDate && (isChecked ? toDate : true)) {
      setBatches([
        { id: 1, name: 'Batch A', date: '2024-01-15' },
        { id: 2, name: 'Batch B', date: '2024-02-20' },
        { id: 3, name: 'Batch C', date: '2024-03-10' },
      ]);
    }
  };

  return (
    <div className="batch-cancellation-container">

      <div className="checkbox-container">
        <input
          type="checkbox"
          id="date-range-checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="date-range-checkbox">Select Date Range</label>
      </div>

      <div className="date-range-container">
        <div className="date-inputs">
          <label htmlFor="fromDate">
            {isChecked ? 'From Date' : 'Date'}:
          </label>
          <input
            type="date"
            id="fromDate"
            name="fromDate"
            value={fromDate}
            onChange={handleDateChange}
            onFocus={(e) => e.target.showPicker()} 
          />
        </div>

        {isChecked && (
          <div className="date-inputs">
            <label htmlFor="toDate">To Date:</label>
            <input
              type="date"
              id="toDate"
              name="toDate"
              value={toDate}
              onChange={handleDateChange}
              onFocus={(e) => e.target.showPicker()} 
            />
          </div>
        )}
      </div>

      <button
        className="list-batches-button"
        onClick={handleListBatches}
        disabled={!fromDate || (isChecked && !toDate)}
      >
        List Batches
      </button>

      {batches.length > 0 && (
        <div className="batch-list">
          <h3>Batches List:</h3>
          <ul>
            {batches.map((batch) => (
              <li key={batch.id}>
                {batch.name} - {batch.date}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BatchCancellation;

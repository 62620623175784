import React, { useState } from 'react';
import './NewSlider.css'; // Import the external CSS file
import { Link } from 'react-router-dom';

const NewSlider = () => {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="new-slider-container">
       <div className="breadcrumb">
          <Link to="/sliderlist" className="breadcrumb-link">Slider List</Link> &gt; New Slider
        </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Heading:</label>
          <input
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            placeholder="Enter heading"
          />
        </div>
        <div>
          <label>Sub Heading:</label>
          <input
            type="text"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
            placeholder="Enter subheading"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default NewSlider;

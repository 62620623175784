import React, { useState } from 'react';
import './Challenge.css';

const SalesRegister = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAcademy, setSelectedAcademy] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [SalesRegister, setSalesRegister] = useState([
    {
      name: "John Doe",
      phone: "9876543210",
      preferredCenter: "Elite Sports Academy",
      purchaseDate: "2024-12-01",
      joiningDate: "2024-12-05",
      expiryDate: "2024-12-31",
      planType: "Monthly",
      sportType: "Badminton",
      preferredSport: "Badminton",
      selfRating: 4.5,
      totalPlayingHours: 50,
      hoursUsed: 20,
    },
    {
      name: "Jane Smith",
      phone: "8765432109",
      preferredCenter: "Pro Fitness Hub",
      purchaseDate: "2024-11-15",
      joiningDate: "2024-11-20",
      expiryDate: "2025-11-14",
      planType: "Yearly",
      sportType: "Tennis",
      preferredSport: "Tennis",
      selfRating: 4.8,
      totalPlayingHours: 300,
      hoursUsed: 120,
    },
    {
      name: "Mike Johnson",
      phone: "7654321098",
      preferredCenter: "Champion's League",
      purchaseDate: "2024-10-20",
      joiningDate: "2024-10-25",
      expiryDate: "2025-01-23",
      planType: "Quarterly",
      sportType: "Football",
      preferredSport: "Football",
      selfRating: 4.3,
      totalPlayingHours: 90,
      hoursUsed: 60,
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSalesRegister = SalesRegister.filter((Sales) => {
    const matchesSearch = Object.values(Sales)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const matchesAcademy =
      selectedAcademy === '' || Sales.preferredCenter === selectedAcademy;

      const matchesDate =
      (fromDate === '' || new Date(Sales.purchaseDate) >= new Date(fromDate)) &&
      (toDate === '' || new Date(Sales.purchaseDate) <= new Date(toDate));    

    return matchesSearch && matchesAcademy && matchesDate;
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredSalesRegister.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredSalesRegister.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const renderPagination = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
      <button
        key={page}
        className={`page-number ${currentPage === page ? 'active' : ''}`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </button>
    ));
  };

  return (
    <div className="challenges-container">
      <div className="sales-filters">
        <select
          className="sales-filter-academy"
          value={selectedAcademy}
          onChange={(e) => setSelectedAcademy(e.target.value)}
        >
          <option value="">Select Academy</option>
          <option value="Elite Sports Academy">Elite Sports Academy</option>
          <option value="Pro Fitness Hub">Pro Fitness Hub</option>
          <option value="Champion's League">Champion's League</option>
        </select>

        <input
          type="date"
          className="sales-filter-from-date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          onFocus={(e) => e.target.showPicker()}

        />

        <input
          type="date"
          className="sales-filter-to-date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          onFocus={(e) => e.target.showPicker()}

        />
      </div>
      
        <h2 className="challenges-header">Playing Subscriptions</h2>

        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="create-button">Export CSV</button>
        </div>
        <div style={{ maxWidth: '1100px', overflowY: 'auto' }}>
        <table className="challenges-table">
          <thead>
            <tr className="challenges-header-row">
              <th>Name</th>
              <th>Phone</th>
              <th>Preferred Center</th>
              <th>Purchase Date</th>
              <th>Joining Date</th>
              <th>Expiry Date</th>
              <th>Plan Type</th>
              <th>Sport Type</th>
              <th>Preferred Sport</th>
              <th>Self Rating</th>
              <th>Total Playing Hours</th>
              <th>Hours Used</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((Sales, index) => (
              <tr key={index}>
                <td>{Sales.name}</td>
                <td>{Sales.phone}</td>
                <td>{Sales.preferredCenter}</td>
                <td>{Sales.purchaseDate}</td>
                <td>{Sales.joiningDate}</td>
                <td>{Sales.expiryDate}</td>
                <td>{Sales.planType}</td>
                <td>{Sales.sportType}</td>
                <td>{Sales.preferredSport}</td>
                <td>{Sales.selfRating}</td>
                <td>{Sales.totalPlayingHours}</td>
                <td>{Sales.hoursUsed}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            className="prev-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {renderPagination()}
          <button
            className="next-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesRegister;

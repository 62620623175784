import React, { useState } from 'react';
import './Challenge.css';

const TournamentRegistration = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [TournamentRegistration, setTournamentRegistration] = useState([
    {
      academyName: "Elite Sports Academy",
      tournamentName: "Badminton Championship",
      totalRegistration: 120,
    },
    {
      academyName: "Pro Fitness Hub",
      tournamentName: "Tennis Open",
      totalRegistration: 80,
    },
    {
      academyName: "Champion's League",
      tournamentName: "Football Cup",
      totalRegistration: 150,
    },
    {
      academyName: "Victory Sports Center",
      tournamentName: "Basketball Invitational",
      totalRegistration: 95,
    },
    {
      academyName: "Supreme Sports Academy",
      tournamentName: "Volleyball Championship",
      totalRegistration: 110,
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTournamentRegistration = TournamentRegistration.filter((registration) =>
    Object.values(registration)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredTournamentRegistration.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredTournamentRegistration.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
      <h2 className="challenges-header">Tournament Registration</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button">
          Export CSV
        </button>
      </div>
      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
            <th className="challenges-column">Academy Name</th>
            <th className="challenges-column">Tournament Name</th>
            <th className="challenges-column">Total Registration</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((registration) => (
            <tr key={registration.id} className="challenges-row">
              <td className="challenges-cell">{registration.academyName}</td>
              <td className="challenges-cell">{registration.tournamentName}</td>
              <td className="challenges-cell">{registration.totalRegistration}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TournamentRegistration

import React, { useState, useEffect } from 'react';
import '../Coaching/academyplayerlist.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { useNavigate } from 'react-router-dom';

const AcademyPlayerList = () => {
  const { userData } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [players, setPlayers] = useState([
    {
      id: 1,
      name: 'John Doe',
      parentName: 'Jane Doe',
      parentMobile: '9876543210',
      category: 'U-16',
      level: 'Intermediate',
      mobile: '9123456780',
      dob: '2008-05-14',
      isEnrolled: true,
      playingMember: true,
    },
    {
      id: 2,
      name: 'Alice Smith',
      parentName: 'Robert Smith',
      parentMobile: '8765432109',
      category: 'U-14',
      level: 'Beginner',
      mobile: '8987654321',
      dob: '2010-02-20',
      isEnrolled: false,
      playingMember: false,
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [formData, setFormData] = useState({
    name: '',
    parentName: '',
    parentMobile: '',
    category: '',
    level: '',
    mobile: '',
    dob: '',
    isEnrolled: false,
    playingMember: false,
  });

  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === 'isEnrolled' || name === 'playingMember' ? e.target.checked : value });
  };

  const filteredPlayers = players.filter((player) =>
    Object.values(player)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const toggleDropdown = (playerId) => {
    setDropdownOpen(dropdownOpen === playerId ? null : playerId);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPlayers.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPlayers.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleEdit=()=>{
    navigate('/createplayer')
  }
const handlePlayerMember=()=>{
  const confirmed = window.confirm('Are you sure you want to send an SMS?');
  if (confirmed) {
    navigate(`/academyPlayerList`);
  }
}

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.dropdown-menu-player');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.dots-btn-player')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleCreateplayer = () => navigate('/createplayer');

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const hanldeshop=()=>navigate('/shop')
  return (
    <div className="academy-player-list-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
          <button className="create-button" onClick={handleCreateplayer}>
          + Create Player
        </button>
      </div>

      <table className="player-table" style={{ maxWidth: '1100px', overflowY: 'auto' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Parent Name</th>
            <th>Parent Mobile</th>
            <th>Category</th>
            <th>Level</th>
            <th>Mobile</th>
            <th>Date of Birth</th>
            <th>Is Enrolled</th>
            <th>Playing Member</th>
            {hasPrivilege(userData, 'ACADEMY_PLAYER_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((player) => (
            <tr key={player.id}>
              <td>{player.name}</td>
              <td>{player.parentName}</td>
              <td>{player.parentMobile}</td>
              <td>{player.category}</td>
              <td>{player.level}</td>
              <td>{player.mobile}</td>
              <td>{player.dob}</td>
              <td>{player.isEnrolled ? 'Yes' : 'No'}</td>
              <td>{player.playingMember ? 'Yes' : 'No'}</td>
              {hasPrivilege(userData, 'ACADEMY_PLAYER_LIST_MODIFY') && (<td className="action-buttons-player">
                <button
                  className="dots-btn-player"
                  onClick={() => toggleDropdown(player.id)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                >
                  &#x22EE; {/* Unicode for vertical ellipsis (three dots) */}
                </button>
                {dropdownOpen === player.id && (
                  <div className="dropdown-menu-player">
                    <button className="dropdown-item" onClick={handleEdit}>Details</button>
                    <button className="dropdown-item">Attendance</button>
                    <button className="dropdown-item">Feedbacks</button>
                    <button className="dropdown-item"onClick={handleEdit}>Edit</button>
                    <button className="dropdown-item">Remove</button>
                    <button className="dropdown-item" onClick={handlePlayerMember}>Add to Playing Members</button>
                    <button className="dropdown-item" onClick={hanldeshop}>Shop</button>
                  </div>
                )}
              </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>&times;</button>
            <div>
              <p>
                Are you sure you want to delete?
              </p>
              <div className="modal-actions">
                <button className="confirm-btn-academy" >
                  Confirm Disable
                </button>
                <button className="cancel-btn-academy" onClick={cancelAction}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcademyPlayerList;

import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the UserContext
const UserContext = createContext();

// UserProvider to manage global user and selected center states
export const UserProvider = ({ children }) => {
  const [userData, setUser] = useState(() =>
    JSON.parse(localStorage.getItem('userData')) || null
  );
  const [selectedCenter, setSelectedCenter] = useState(
    localStorage.getItem('selectedCenter') || null
  );

  // Sync userData with localStorage
  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
      
    } else {
      localStorage.removeItem('userData');
    }
  }, [userData]);

  // Sync selectedCenter with localStorage
  useEffect(() => {
    if (selectedCenter) {
      localStorage.setItem('selectedCenter', selectedCenter);
      
    } else {
      localStorage.removeItem('selectedCenter');
    }
  }, [selectedCenter]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUser,
        selectedCenter,
        setSelectedCenter,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to consume the UserContext
export const useUser = () => useContext(UserContext);
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../coupons/Coupon.css';

import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const CouponList = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    // Fetch the coupons data from the API with headers
    fetch('https://admin.stage.machaxi.com/api/react/coupons/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', // Set the content type
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with your actual token if required
        // Add other headers as needed
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const fetchedCoupons = data.data.coupons.map((coupon) => ({
            id: coupon.id,
            title: coupon.title,
            couponCode: coupon.couponCode,
            minOrderAmount: coupon.minOrderAmount,
            maxDiscount: coupon.maxDiscountAmount, // Using maxDiscountAmount from the API response
            maxUsagePerUser: coupon.maxUsagePerUser,
            percentage: coupon.percentage,
            startTime: new Date(coupon.startTime).toISOString().split('T')[0], // Extract only the date part
            endTime: new Date(coupon.endTime).toISOString().split('T')[0], // Extract only the date part
            description: coupon.description,
            forAllUsers:coupon.forAllUsers,
            mobileNumbers:coupon.mobileNumbers,
          }));
          setCoupons(fetchedCoupons);
        }
      })
      .catch((error) => {
        console.error('Error fetching coupons:', error);
      });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ title: '', couponCode: '', minOrderAmount: '', maxDiscount: '', maxUsagePerUser: '', percentage: '', startTime: '', endTime: '', description: '' });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default to 10 items per page
  const [items, setItems] = useState([]); // Your data (e.g., from API)

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateCoupon = () => {
    navigate('/newCoupon');
  };
  const handleDetails = (couponId) => {
    const coupon = coupons.find(c => c.id === couponId);
  
    if (coupon) {
      // Navigate to the 'newCoupon' page with coupon data passed via state
      navigate('/newCoupon', { state: { couponData: coupon } });
    }
  };
  
const handleEditCoupon = (couponId) => {
  // Find the coupon by its ID from the coupons array
  const coupon = coupons.find(c => c.id === couponId);

  // Set the selected coupon and form data for editing
  setSelectedCoupon(coupon);
  setFormData({ ...coupon });

  // Set action type for conditional rendering (edit mode)
  setAction('edit');

  // Open the modal
  setIsModalOpen(true);

  // Navigate to the 'newCoupon' route and pass the coupon data via state
  navigate('/newCoupon', { state: { couponData: coupon } });
};

const handleDeleteCoupon = (couponId) => {
  // Find the coupon to delete
  const coupon = coupons.find(coupon => coupon.id === couponId);

  // Set the selected coupon and action for delete
  setSelectedCoupon(coupon);
  setAction('delete');
  setIsModalOpen(true);
};

const confirmDelete = async () => {
  try {
    const response = await fetch(`https://admin.stage.machaxi.com/api/react/coupons/remove-coupon-academy?coupon_id=${selectedCoupon.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with a valid token if needed
      },
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(`Failed to delete coupon: ${errorDetails.message || response.statusText}`);
    }

    const updatedCoupons = coupons.filter(coupon => coupon.id !== selectedCoupon.id);
    setCoupons(updatedCoupons);
    setIsModalOpen(false);
    alert('Coupon deleted successfully');
  } catch (error) {
    console.error('Error deleting coupon:', error);
    alert(`Error deleting coupon: ${error.message}`);
  }
};


  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredCoupons = coupons.filter((coupon) =>
    Object.values(coupon)
      .join(' ') // Join all the fields into one string
      .toLowerCase() // Make it case-insensitive
      .includes(searchTerm.toLowerCase()) // Check if it includes the search term
  );
  // Pagination logic
  const totalPages = Math.ceil(filteredCoupons.length / itemsPerPage);
  const displayedCoupons = filteredCoupons.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen !== null && !event.target.closest('.dropdown-container')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = (couponId) => {
    setDropdownOpen(dropdownOpen === couponId ? null : couponId); // Toggle dropdown
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };


  return (
    <div className="coupon-list-container" >
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleCreateCoupon}>
          + Create Coupon 🏷️ 
        </button>
      </div>
      
      <select className="items-per-page-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
        <option value={10}>10 items per page</option>
        <option value={25}>25 items per page</option>
        <option value={50}>50 items per page</option>
        <option value={75}>75 items per page</option>
      </select>

<div style={{ maxWidth: '1200px', overflowY: 'auto' }}>
      <table className="coupon-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Coupon Code</th>
            <th>Min Order Amount</th>
            <th>Max Discount Amount</th>
            <th>Max Usage Per User</th>
            <th>Percentage</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Description</th>
            {hasPrivilege(userData, 'COUPONS_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {displayedCoupons.map((coupon) => (
            <tr key={coupon.id}>
              <td>{coupon.title}</td>
              <td>{coupon.couponCode}</td>
              <td>{coupon.minOrderAmount}</td>
              <td>{coupon.maxDiscount}</td>
              <td>{coupon.maxUsagePerUser}</td>
              <td>{coupon.percentage}%</td>
              <td>{coupon.startTime}</td>
              <td>{coupon.endTime}</td>
              <td>{coupon.description}</td>
              {hasPrivilege(userData, 'COUPONS_LIST_MODIFY') && (<td >
                <button
                  className="dropdown-toggle-btn"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    toggleDropdown(coupon.id); // Toggle dropdown
                  }}
                >
                  &#x22EE;
                </button>
                {dropdownOpen === coupon.id && (
                  <div className="dropdown-menu-container">
                    <button onClick={() => handleEditCoupon(coupon.id)}>Edit</button>
                    <button onClick={() => handleDeleteCoupon(coupon.id)}>Disable</button>
                    <button onClick={() =>  handleDetails(coupon.id)}>Details</button>
                  </div>
                )}
              </td>)}

            </tr>
          ))}
        </tbody>
      </table>
      </div>
       {/* Pagination */}
       <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>&times;</button>
              <div>
                <p>
                  Are you sure you want to delete the coupon: {selectedCoupon.title}?
                </p>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={confirmDelete}>
                    Confirm Disable
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponList;

import React, { useState } from 'react';
import './PatnersContactUs.css';

const PatnersContactUs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [partners, setPartners] = useState([
    { id: 1, academyName: 'Academy A', mobile: '123-456-7890', contactPerson: 'John Doe' },
    { id: 2, academyName: 'Academy B', mobile: '234-567-8901', contactPerson: 'Jane Smith' },
    { id: 3, academyName: 'Academy C', mobile: '345-678-9012', contactPerson: 'Samuel Green' },
    { id: 4, academyName: 'Academy D', mobile: '456-789-0123', contactPerson: 'Alice Brown' },
    { id: 5, academyName: 'Academy E', mobile: '567-890-1234', contactPerson: 'Bob White' },
    { id: 6, academyName: 'Academy F', mobile: '678-901-2345', contactPerson: 'Charlie Black' },
    { id: 7, academyName: 'Academy G', mobile: '789-012-3456', contactPerson: 'Diana Blue' },
    { id: 8, academyName: 'Academy H', mobile: '890-123-4567', contactPerson: 'Eva Red' },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPartners = partners.filter((partner) =>
    Object.values(partner)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPartners.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPartners.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  return (
    <div className="partners-contact-us-container">
      <h2 className="partners-contact-us-header">Partners Contact Us</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button">
          Export CSV
        </button>
      </div>
      <table className="partners-contact-us-table">
        <thead>
          <tr className="partners-contact-us-header-row">
            <th className="partners-contact-us-column">Academy Name</th>
            <th className="partners-contact-us-column">Mobile Number</th>
            <th className="partners-contact-us-column">Contact Person</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((partner) => (
            <tr key={partner.id} className="partners-contact-us-row">
              <td className="partners-contact-us-cell">{partner.academyName}</td>
              <td className="partners-contact-us-cell">{partner.mobile}</td>
              <td className="partners-contact-us-cell">{partner.contactPerson}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PatnersContactUs;

import React, { useState ,useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import '../sliders/SliderList.css';
import { Link } from 'react-router-dom';

const AttributeQA = () => {
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [academies, setAcademies] = useState([]);

  useEffect(() => {
    // Fetch data from the API with headers
    fetch('https://admin.stage.machaxi.com/api/admin/sport/progress-qa?progressAttributeId=6', {
      method: 'GET', // or 'POST' depending on your request type
      headers: {
        'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
    
      }
    })
      .then(response => response.json())
      .then(data => {
        // Check if the response is successful and contains the necessary data
        if (data.success && data.data.ProgressAttributeQA) {
          // Update the state with the fetched data
          const newAcademies = data.data.ProgressAttributeQA.map(item => ({
            id: item.id,
            question: item.question,
            answer: item.answer,
          }));
          setAcademies(newAcademies);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ heading: '', subheading: '' });
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewSlider = () => {
    navigate('/newAttribute');
  };
  const handleEdit = () => {
    navigate('/newAttribute');
  };


  const handleDeleteAcademy = (academyId) => {
    setSelectedAcademy(academies.find(academy => academy.id === academyId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedAcademies = academies.filter(academy => academy.id !== selectedAcademy.id);
    setAcademies(updatedAcademies);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedAcademies = academies.map(academy =>
      academy.id === selectedAcademy.id ? { ...academy, ...formData } : academy
    );
    setAcademies(updatedAcademies);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredAcademies = academies.filter((academy) =>
    Object.values(academy)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredAcademies.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAcademies.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="slider-container">
         <div className="breadcrumb">
       <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; 
       <Link to="/ProgresAttribute" className="breadcrumb-link">Progres Attribute</Link> &gt; Attribute QA
        </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewSlider}>
          + Add QA
        </button>
      </div>

      <table className="academy-table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            {hasPrivilege(userData, 'SLIDER_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((academy) => (
            <tr key={academy.id}>
              <td>{academy.question}</td>
              <td>{academy.answer}</td>
              {hasPrivilege(userData, 'SLIDER_LIST_MODIFY') && (<td className="action-buttons">
                <button className="edit-btn" onClick={handleEdit}>Edit</button>
                <button className="delete-btn" onClick={() => handleDeleteAcademy(academy.id)}>Delete</button>
              </td>)}

            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AttributeQA

import React, { useState } from 'react';
import '../Challenges/challenges.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const CourtBooking = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [CourtBooking, setCourtBooking] = useState([
    {
      courtName: 'Court A',
      openTime: '06:00 AM',
      closeTime: '09:00 PM',
      bookingDate: '2024-12-10',
      user: 'John Doe',
      contactNo: '123-456-7890',
      guestCount: 3,
      trialBooking: 'Yes',
    },
    {
      courtName: 'Court B',
      openTime: '08:00 AM',
      closeTime: '10:00 PM',
      bookingDate: '2024-12-12',
      user: 'Jane Smith',
      contactNo: '987-654-3210',
      guestCount: 5,
      trialBooking: 'No',
    },
    {
      courtName: 'Court C',
      openTime: '07:00 AM',
      closeTime: '08:00 PM',
      bookingDate: '2024-12-14',
      user: 'Mark Johnson',
      contactNo: '555-123-4567',
      guestCount: 2,
      trialBooking: 'Yes',
    },
    {
      courtName: 'Court D',
      openTime: '09:00 AM',
      closeTime: '11:00 PM',
      bookingDate: '2024-12-15',
      user: 'Emily Davis',
      contactNo: '444-777-8888',
      guestCount: 4,
      trialBooking: 'No',
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedChallenge, setEditedChallenge] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const filteredCourtBooking = CourtBooking.filter((booking) => {
    const isSearchMatch = Object.values(booking)
      .join(' ') // Join all fields into a single string
      .toLowerCase()
      .includes(searchTerm.toLowerCase()); // Filter based on the search term

    const isDateInRange =
      (startDate ? new Date(booking.bookingDate) >= new Date(startDate) : true) &&
      (endDate ? new Date(booking.bookingDate) <= new Date(endDate) : true);

    const isTimeInRange =
      (startTime ? booking.openTime >= startTime : true) &&
      (endTime ? booking.closeTime <= endTime : true);

    return isSearchMatch && isDateInRange && isTimeInRange;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedChallenge((prevChallenge) => ({
      ...prevChallenge,
      [name]: value,
    }));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCourtBooking.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCourtBooking.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
       {/* Filter Inputs */}
       <div className="filters">
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="filter-input"
            placeholder="Start Date"
            onFocus={(e) => e.target.showPicker()} 
          />
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="filter-input"
            placeholder="End Date"
            onFocus={(e) => e.target.showPicker()} 
          />
          <input
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
            className="filter-input"
            placeholder="Start Time"
            onFocus={(e) => e.target.showPicker()} 
          />
          <input
            type="time"
            value={endTime}
            onChange={handleEndTimeChange}
            className="filter-input"
            placeholder="End Time"
            onFocus={(e) => e.target.showPicker()} 
          />
        </div>
    
      <div className="top-row">
        <div className="search-bar">
          <input
            type="text"
            placeholder=" 🔍 Search Challenge"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <table className="challenges-table">
        <thead>
          <tr>
            <th>Court Name</th>
            <th>Open Time</th>
            <th>Close Time</th>
            <th>Booking Date</th>
            <th>User</th>
            <th>Contact No.</th>
            <th>Guest Count</th>
            <th>Trial Booking</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((booking) => (
            <tr key={booking.id}>
              <td>{booking.courtName}</td>
              <td>{booking.openTime}</td>
              <td>{booking.closeTime}</td>
              <td>{booking.bookingDate}</td>
              <td>{booking.user}</td>
              <td>{booking.contactNo}</td>
              <td>{booking.guestCount}</td>
              <td>{booking.trialBooking}</td>
              <td>
                <button className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CourtBooking;

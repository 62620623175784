import React, { useState } from 'react';
import './EditJobVacancy.css'; // Assuming the CSS file is in the same folder

const EditJobVacency = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleSave = () => {
    alert(`Changes Saved! Vacancy is ${isOpen ? "Open" : "Closed"}`);
  };

  return (
    <div className="edit-job-vacancy-container">
      <div className="checkbox-container">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={isOpen}
            onChange={handleCheckboxChange}
            className="checkbox"
          />
          <span className="checkbox-text">Open Vacancy</span>
        </label>
      </div>

      {/* Save Button */}
      <div className="button-container">
        <button className="save-button" onClick={handleSave}>Save Changes</button>
      </div>
    </div>
  );
};

export default EditJobVacency;

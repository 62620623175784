import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import '../sidebar/Sidebar.css';
import { hasPrivilege } from '../utils/hasPrivilege';

const Sidebar = () => {
  const { userData } = useUser();
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(
    localStorage.getItem('selectedCenter') || ''
  );

  const [showOptions, setShowOptions] = useState(
    JSON.parse(localStorage.getItem('showOptions')) || false
  );
  const [activeSection, setActiveSection] = useState(
    localStorage.getItem('activeSection') || null
  );
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('activeSection', activeSection);
  }, [activeSection]);

  useEffect(() => {
    const fetchAcademies = async () => {
      try {
        const response = await fetch('https://admin.stage.machaxi.com/api/admin/academies', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });
        const data = await response.json();
        console.log("This is the data i'm getting ", data);
        if (data && data.data.academies) {
          setCenters(data.data.academies);
        } else {
          console.error("No academies found");
        }
      } catch (error) {
        console.error('Error fetching academies:', error);
      }
    };

    fetchAcademies();
  }, []);

  useEffect(() => {
    if (selectedCenter) {
      localStorage.setItem('selectedCenter', selectedCenter);
    }
  }, [selectedCenter]);

  const handleCenterChange = (event) => {
    const centerId = event.target.value;
    setSelectedCenter(centerId);
    localStorage.setItem('selectedCenter', centerId);
    navigate('/checkinreport');
    window.location.reload();
  };

  const handleClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const handleSubsectionClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      
      <nav>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        {isCollapsed ? "▶" : "◀"}
      </div>
        <ul>
          <li>
            <Link to="/" onClick={() => setActiveSection(null)}>
            📊 Dashboard
            </Link>
          </li>
          {userData.userType === "ADMIN" && (
            <li>
              <select onChange={handleCenterChange} value={selectedCenter}>
                <option value="">📍 Select a Center</option>
                {centers.map((center) => (
                  <option key={center.id} value={center.id}>
                    {center.name}
                  </option>
                ))}
              </select>
            </li>
          )}

          {hasPrivilege(userData, 'ACADEMY_VIEW') && (
            <li className="menu-item">
              <Link>🏫 Academy</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'ACADEMY_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/academylist"
                        className={window.location.pathname === '/academylist' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Academy List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'CREATE_NEW_ACADEMY_VIEW') && (
                    <li>
                      <Link
                        to="/newAcademy"
                        className={window.location.pathname === '/newAcademy' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create Academy
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'DISABLED_ACADEMY_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/disabledAcademy"
                        className={window.location.pathname === '/disabledAcademy' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Disabled Academy
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'SPORT_VIEW') && (
            <li className="menu-item">
              <Link>🏸 Sport</Link>
                <ul className='subsection'>

                  {hasPrivilege(userData, 'SPORT_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/sportList"
                        className={window.location.pathname === '/sportList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                      Sport List
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'CREATE_NEW_SPORT_VIEW') && (
                    <li>
                      <Link
                        to="/newSport"
                        className={window.location.pathname === '/newSport' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                       Create Sport
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'COUPONS_VIEW') && (
            <li className="menu-item">
              <Link>🏷️ Coupons</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'COUPONS_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/coupon"
                        className={window.location.pathname === '/coupon' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Coupon List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'CREATE_NEW_COUPON') && (
                    <li>
                      <Link
                        to="/newCoupon"
                        className={window.location.pathname === '/newCoupon' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create Coupon
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'PAYMENT_VIEW') && (
            <li className="menu-item">
              <Link>💵 Payment</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'PAYMENT_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/paymentlist"
                        className={window.location.pathname === '/paymentlist' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Payment List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'PAYMENT_DUES_VIEW') && (
                    <li>
                      <Link
                        to="/dues"
                        className={window.location.pathname === '/dues' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                       Dues
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'PAYMENT_SUMMARY_VIEW') && (
                    <li>
                      <Link
                        to="/summary"
                        className={window.location.pathname === '/summary' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                       Summary
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'SPORTS_PAYMENT_SUMMARY_VIEW') && (
                    <li>
                      <Link
                        to="/sportPaymentsummary"
                        className={window.location.pathname === '/sportPaymentsummary' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                       Sport Payment
                      </Link>
                    </li>
                  )}

                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'SLIDER_VIEW') && (
            <li className="menu-item">
              <Link>↔ Slider</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'SLIDER_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/sliderlist"
                        className={window.location.pathname === '/sliderlist' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Slider List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'CREATE_NEW_SLIDER_VIEW') && (
                    <li>
                      <Link
                        to="/newslider"
                        className={window.location.pathname === '/newslider' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        New Slider
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'COACHING_VIEW') && (
            <li className="menu-item">
              <Link>👨‍🏫 Coaching</Link>
                <ul className='subsection'>
                  <li>
                    <Link
                      to="/academyPlayerList"
                      className={window.location.pathname === '/academyPlayerList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Academy Player List
                    </Link>
                  </li>
                  {/* )} */}

                  {hasPrivilege(userData, 'ACADEMY_COACH_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/academyCoachList"
                        className={window.location.pathname === '/academyCoachList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Academy Coach List
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'CREATE_NEW_BATCH_VIEW') && (
                    <li>
                      <Link
                        to="/createNewBatch"
                        className={window.location.pathname === '/createNewBatch' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create New Batch
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'CREATE_NEW_PLAYER_VIEW') && (
                    <li>
                      <Link
                        to="/createNewPlayer"
                        className={window.location.pathname === '/createNewPlayer' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create New Player
                      </Link>
                    </li>
                  )}


                  {hasPrivilege(userData, 'CREATE_NEW_COACH_VIEW') && (
                    <li>
                      <Link
                        to="/createNewCoach"
                        className={window.location.pathname === '/createNewCoach' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create New Coach
                      </Link>
                    </li>
                  )}


                  {hasPrivilege(userData, 'BATCH_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/batchList"
                        className={window.location.pathname === '/batchList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Batch List
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'PLAYER_BATCHES_VIEW') && (
                    <li>
                      <Link
                        to="/playerBatch"
                        className={window.location.pathname === '/playerBatch' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Player Batch
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'COACH_BATCHES_VIEW') && (
                    <li>
                      <Link
                        to="/coachBatch"
                        className={window.location.pathname === '/coachBatch' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Coach Batch
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'BATCH_CANCELLATION_VIEW') && (
                    <li>
                      <Link
                        to="/batchCancellation"
                        className={window.location.pathname === '/batchCancellation' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Batch Cancellation
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'CANCELLED_BATCHES_VIEW') && (
                    <li>
                      <Link
                        to="/cancelBatchList"
                        className={window.location.pathname === '/cancelBatchList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Cancel Batch List
                      </Link>
                    </li>)}


                  {hasPrivilege(userData, 'DIET_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/diet"
                        className={window.location.pathname === '/diet' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Diet
                      </Link>
                    </li>
                  )}

                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'TOURNAMENT_VIEW') && (
            <li className="menu-item" >
              <Link>⚔️ Tournament</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'TOURNAMENT_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/tournamentList"
                        className={window.location.pathname === '/tournamentList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Tournament List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'CREATE_NEW_TOURNAMENT_VIEW') && (
                    <li>
                      <Link
                        to="/createTournament"
                        className={window.location.pathname === '/createTournament' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create Tournament
                      </Link>
                    </li>
                  )}

                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'FEEDBACK_VIEW') && (
            <li className="menu-item">
              <Link>💬 Feedbacks</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'ACADEMY_FEEDBACKS_VIEW') && (
                    <li>
                      <Link
                        to="/academyfeedback"
                        className={window.location.pathname === '/academyfeedback' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Academy Feedbacks
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'COACH_FEEDBACKS_VIEW') && (
                    <li>
                      <Link
                        to="/coachfeedback"
                        className={window.location.pathname === '/coachfeedback' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Coach Feedbacks
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'CHALLENGES_VIEW') && (
            <li className="menu-item">
              <Link>🎯 Challenges</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'CHALLENGE_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/challengeList"
                        className={window.location.pathname === '/challengeList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Challenge List
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'NOTIFICATION_VIEW') && (
            <li className="menu-item">
              <Link>🔔 Send Notification</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'NOTIFICATION_VIEW') && (
                    <li>
                      <Link
                        to="/notification"
                        className={window.location.pathname === '/notification' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Send Notification
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'COURTS_VIEW') && (
            <li className="menu-item">
              <Link>🏛️ Court Booking</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'COURT_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/courtlist"
                        className={window.location.pathname === '/courtlist' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Court List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'BOOKING_CALENDAR_VIEW') && (
                    <li>
                      <Link
                        to="/bookingcalendar"
                        className={window.location.pathname === '/bookingcalendar' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Booking Calendar
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'COURT_BOOKINGS_VIEW') && (<li>
                    <Link
                      to="/courtbookings"
                      className={window.location.pathname === '/courtbookings' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Bookings
                    </Link>
                  </li>)}
                  {hasPrivilege(userData, 'COURT_PRICING_VIEW') && (<li>
                    <Link
                      to="/courtprice"
                      className={window.location.pathname === '/courtprice' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Pricing
                    </Link>
                  </li>)}
                  {hasPrivilege(userData, 'COURT_BULK_BOOKING_VIEW') && (<li>
                    <Link
                      to="/courtbulkbooking"
                      className={window.location.pathname === '/courtbulkbooking' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Bulk Booking
                    </Link>
                  </li>)}

                  {hasPrivilege(userData, 'BULK_BOOKING_LIST_VIEW') && (<li>
                    <Link
                      to="/bulkbookinglist"
                      className={window.location.pathname === '/bulkbookinglist' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Bulk Booking List
                    </Link>
                  </li>)}
                  {hasPrivilege(userData, 'ADD_NEW_COURT_VIEW') && (<li>
                    <Link
                      to="/newcourt"
                      className={window.location.pathname === '/newcourt' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Add New Court
                    </Link>
                  </li>)}
                  {hasPrivilege(userData, 'BLOCK_COURT_LIST_VIEW') && (<li>
                    <Link
                      to="/blockcourt"
                      className={window.location.pathname === '/blockcourt' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Block Court List
                    </Link>
                  </li>)}

                  {hasPrivilege(userData, 'ACADEMY_SPORTS_VIEW') && (<li>
                    <Link
                      to="/academysport"
                      className={window.location.pathname === '/academysport' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Academy Sport
                    </Link>
                  </li>)}

                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'ACADEMY_PROFILE_VIEW') && (
            <li className="menu-item">
              <Link>🎓 Academy Profile</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'EDIT_PROFILE_VIEW') && (
                    <li>
                      <Link
                        to="/editprofile"
                        className={window.location.pathname === '/editprofile' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Edit Profile
                      </Link>
                    </li>)}
                  {hasPrivilege(userData, 'EDIT_JOB_VACANCY_VIEW') && (
                    <li>
                      <Link
                        to="/editjobvacancy"
                        className={window.location.pathname === '/editjobvacancy' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Edit Job Vacancy
                      </Link>
                    </li>)}

                  {hasPrivilege(userData, '"BANK_DETAILS_VIEW') && (
                    <li>
                      <Link
                        to="/bankdetails"
                        className={window.location.pathname === '/bankdetails' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Bank Details
                      </Link>
                    </li>)}

                  {hasPrivilege(userData, 'FEES_SETTINGS_VIEW') && (
                    <li>
                      <Link
                        to="/feesSetting"
                        className={window.location.pathname === '/feesSetting' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Fees Settings
                      </Link>
                    </li>)}

                  {hasPrivilege(userData, 'ACADEMY_GALLERY_VIEW') && (
                    <li>
                      <Link
                        to="/academygallery"
                        className={window.location.pathname === '/academygallery' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Academy Gallery
                      </Link>
                    </li>)}

                  {hasPrivilege(userData, 'RESET_PASSWORD_VIEW') && (
                    <li>
                      <Link
                        to="/resetPassword"
                        className={window.location.pathname === '/resetPassword' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Reset Password
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'REPORTS_VIEW') && (
            <li className="menu-item">
              <Link>📋 Reports</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'PARTNERS_CONTACT_US_VIEW') && (
                    <li>
                      <Link
                        to="/patnerscontactus"
                        className={window.location.pathname === '/patnerscontactus' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Patners Contact Us
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'CHALLENGE_VIEW') && (
                    <li>
                      <Link
                        to="/challenge"
                        className={window.location.pathname === '/challenge' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Challenge
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'DISPUTE_PENDING_VIEW') && (
                    <li>
                      <Link
                        to="/disputepending"
                        className={window.location.pathname === '/disputepending' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Dispute Pending
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'DISPUTE_RESOLVED_VIEW') && (
                    <li>
                      <Link
                        to="/disputeresolved"
                        className={window.location.pathname === '/disputeresolved' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Dispute Resolved
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'TOURNAMENT_REGISTRATION_VIEW') && (
                    <li>
                      <Link
                        to="/tournamentRegistration"
                        className={window.location.pathname === '/tournamentRegistration' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Tournament Registration
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'REWARD_DUES_COACH_VIEW') && (
                    <li>
                      <Link
                        to="/rewardDuesCoach"
                        className={window.location.pathname === '/rewardDuesCoach' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Reward Dues (Coach)
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'REWARD_DUES_FAMILY_VIEW') && (
                    <li>
                      <Link
                        to="/rewardDuesfamily"
                        className={window.location.pathname === '/rewardDuesfamily' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Reward Dues (Family)
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'PERFORMANCE_DUES_COACH_VIEW') && (
                    <li>
                      <Link
                        to="/performanceduescoach"
                        className={window.location.pathname === '/performanceduescoach' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Performance Dues (Coach)
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'ATTENDANCE_VIEW') && (
                    <li>
                      <Link
                        to="/attendance"
                        className={window.location.pathname === '/attendance' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Attendance
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      to="/courtbooking"
                      className={window.location.pathname === '/courtbooking' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Court Booking
                    </Link>
                  </li>

                  {hasPrivilege(userData, 'DAILY_ATTENDANCE_STATUS_VIEW') && (
                    <li>
                      <Link
                        to="/dailyattendancestatus"
                        className={window.location.pathname === '/dailyattendancestatus' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Daily Attendance Status
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'LOGIN_REPORT_VIEW') && (
                    <li>
                      <Link
                        to="/loginreport"
                        className={window.location.pathname === '/loginreport' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Login Report
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'COUPONS_USAGE_REPORT_VIEW') && (
                    <li>
                      <Link
                        to="/couponsusagereport"
                        className={window.location.pathname === '/couponsusagereport' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Coupons Usage Report
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'PLAY_TRIAL_REPORT_VIEW') && (
                    <li>
                      <Link
                        to="/playtrailreport"
                        className={window.location.pathname === '/playtrailreport' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Play Trail Report
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'LEARN_TRIAL_REPORT_VIEW') && (
                    <li>
                      <Link
                        to="/learntrailreports"
                        className={window.location.pathname === '/learntrailreports' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Learn Trail Reports
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'CHECKIN_REPORT_VIEW') && (
                    <li>
                      <Link
                        to="/checkinreport"
                        className={window.location.pathname === '/checkinreport' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Checkin Reports
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'PLAYING_SUBSCRIPTIONS_VIEW') && (
                    <li>
                      <Link
                        to="/playingsubscription"
                        className={window.location.pathname === '/playingsubscription' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Playing Subscription
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'LEAVE_REPORT_VIEW') && (
                    <li>
                      <Link
                        to="/leavereport"
                        className={window.location.pathname === '/leavereport' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Leave Report
                      </Link>
                    </li>
                  )}

                  {hasPrivilege(userData, 'SALES_REGISTER_VIEW') && (
                    <li>
                      <Link
                        to="/salesRegister"
                        className={window.location.pathname === '/salesRegister' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Sales Register
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'RAZORPAY_AUTHORIZATION_VIEW') && (
            <li className="menu-item">
              <Link>💳 RazorPay Authorization</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'RAZORPAY_AUTHORIZATION_VIEW') && (
                    <li>
                      <Link
                        to="/razorpayauth"
                        className={window.location.pathname === '/razorpayauth' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Razorpay Auth Form
                      </Link>
                    </li>
                  )}
                </ul>
              
            </li>
          )}

          {hasPrivilege(userData, 'CHECKIN_ATTENDANCE_VIEW') && (
            <li className="menu-item">
              <Link>🛎️ Checkin Attendance</Link>
              {activeSection === 'searchuser' && (
                <ul className='subsection'>
                  {hasPrivilege(userData, 'CHECKIN_ATTENDANCE_VIEW') && (
                    <li>
                      <Link
                        to="/searchuser"
                        className={window.location.pathname === '/searchuser' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Search User
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}

          {userData.userType === "ADMIN" && (
            <li className="menu-item">
              <Link>👤 User Role</Link>
                <ul className='subsection'>
                  <li>
                    <Link
                      to="/UserList"
                      className={window.location.pathname === '/UserList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      User List
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/RoleList"
                      className={window.location.pathname === '/RoleList' ? 'active' : ''}
                      onClick={handleSubsectionClick}
                    >
                      Role List
                    </Link>
                  </li>
                </ul>
            </li>
          )}

          {hasPrivilege(userData, 'PLAYING_PLAN_VIEW') && (
            <li className="menu-item">
              <Link >🎮 Playing Plans</Link>
                <ul className='subsection'>
                  {hasPrivilege(userData, 'PLANS_LIST_VIEW') && (
                    <li>
                      <Link
                        to="/planList"
                        className={window.location.pathname === '/planList' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Plan List
                      </Link>
                    </li>
                  )}
                  {hasPrivilege(userData, 'CREATE_PLAN_VIEW') && (
                    <li>
                      <Link
                        to="/newPlan"
                        className={window.location.pathname === '/newPlan' ? 'active' : ''}
                        onClick={handleSubsectionClick}
                      >
                        Create Plan
                      </Link>
                    </li>
                  )}
                </ul>
            </li>
          )}

        </ul>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
        {isCollapsed ? "▶" : "◀"}
      </div>
      </nav>
      
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import "./NewSport.css";
import { Link } from 'react-router-dom';
import Select from "react-select";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const NewSport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action, sport } = location.state || {};
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    playingAreaName: "",
    isEntireCourtAllowed: false,
    isRequestAllowed: false,
    isRegistrationFeesApplicable: false,
    registrationFees: "",
    renewalFees: "",
    proficiency: [],
    courtBookingNotes: "",
    sportsImage: null,
  });

  const [proficiencyOptions, setProficiencyOptions] = useState([]);

  useEffect(() => {
    // Fetch data from the API with headers
    const fetchData = async () => {
      try {
        const response = await fetch("https://admin.stage.machaxi.com/api/admin/sportnew", {
          method: "GET", // Use the appropriate HTTP method
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          },
        });

        const data = await response.json();

        if (data.success) {
          const proficiencyList = data.data.allProficiencies.map((proficiency) => {
            return { value: proficiency, label: proficiency };
          });
          setProficiencyOptions(proficiencyList);
        } else {
          console.error("Error fetching data:", data.success_message);
        }
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("useeffect data", sport)
    if (action === 'edit' && sport) {
      setFormData({
        id: sport.id || '',
        name: sport.name || '',
        description: sport.description || '',
        playingAreaName: sport.area || '',
        isEntireCourtAllowed: !!sport.bookingAllowed,  // Ensure boolean value
        isRequestAllowed: !!sport.requestAllowed,
        isRegistrationFeesApplicable: !!sport.isRegistrationFees,
        proficiency: sport.proficiencies.split(', ') || [],
        registrationFees: sport.registrationFees || '',
        renewalFees: sport.renewalFees || '',
        courtBookingNotes: sport.courtBookingNotes || '',
        sportsImage: sport.imageUrl || '',
      });
    }
  }, [action, sport]);


  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      // Handle file change
      const file = files[0];
      if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          // Set the new image URL and replace the old one
          setFormData(prevState => ({
            ...prevState,
            sportsImage: fileReader.result, // Use the new file URL from FileReader
          }));
        };
    
        fileReader.readAsDataURL(file); // Read the image file as a data URL
      }
    }
    
     else if (type === 'checkbox') {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: checked,  // For checkboxes, use 'checked' to update the state
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };





  const handleSelectChange = (selectedOptions) => {
    setFormData(prevState => ({
      ...prevState,
      proficiency: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };
  useEffect(() => {
    console.log("useeffect data", sport);
    if (action === 'edit' && sport) {
      setFormData({
        id: sport.id || '', // Ensure id is included
        name: sport.name || '',
        description: sport.description || '',
        playingAreaName: sport.area || '',
        isEntireCourtAllowed: !!sport.bookingAllowed,  // Ensure boolean value
        isRequestAllowed: !!sport.requestAllowed,
        isRegistrationFeesApplicable: !!sport.isRegistrationFees,
        proficiency: sport.proficiencies.split(', ') || [],
        registrationFees: sport.registrationFees || '',
        renewalFees: sport.renewalFees || '',
        courtBookingNotes: sport.courtBookingNotes || '',
        sportsImage: sport.imageUrl || '',
      });
    }
  }, [action, sport]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Datas of sport:", formData);

    // Construct the data to submit in the correct format
    const dataToSubmit = {
      id: formData.id, // Include the id for updating
      name: formData.name,
      description: formData.description,
      playingAreaName: formData.playingAreaName,
      proficiencies: formData.proficiency, // Array of proficiencies
      allowEntireCourtBooking: formData.isEntireCourtAllowed,
      courtBookingNotes: formData.courtBookingNotes,
      requestAllowed: formData.isRequestAllowed,
      isRegistrationFees: formData.isRegistrationFeesApplicable,
      registrationFees: formData.registrationFees,
      renewalFees: formData.renewalFees,
      file: formData.imageUrl? formData.imageUrl : null, // This will send the file if it's available
    };

    try {
      const apiUrlBase = 'https://admin.stage.machaxi.com/api/admin/sport-new-add';
      const apiUrl = formData.id
        ? `${apiUrlBase}?userId=${formData.id}`
        : apiUrlBase;

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
          "Content-Type": "application/json", // Sending data as JSON
        },
        body: JSON.stringify(dataToSubmit), // Convert the object to JSON
      });

      const data = await response.json();
      console.log("API Response:", data);

      if (data.success) {
        console.log("Form submitted successfully:", data);
        alert(formData.id ? "Sport updated" : "Sport created");
        navigate('/sportList');
        // Handle success (e.g., reset form, show success message)
      } else {
        console.error("Error submitting form:", data.success_message || "No message provided");
      }
    } catch (error) {
      console.error("Error during submission:", error.message);
    }
  };


  return (
    <div className="new-sport-container">
      <div className="breadcrumb">
        <Link to="/sportList" className="breadcrumb-link">Sport List</Link> &gt; New Sport
      </div>
      <form className="new-sport-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            type="text"
            name="name"
            className="form-input"
            value={formData.name || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Description:</label>
          <input
            type="text"
            name="description"
            className="form-input"
            value={formData.description || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Playing Area Name (e.g., Court, Pool, Turf):</label>
          <input
            type="text"
            name="playingAreaName"
            className="form-input"
            value={formData.playingAreaName || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              name="isEntireCourtAllowed"
              className="form-checkbox"
              checked={formData.isEntireCourtAllowed}  // No need for fallback to false
              onChange={handleChange}
            />
            Is Entire Court Booking Allowed?
          </label>
        </div>

        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              name="isRequestAllowed"
              className="form-checkbox"
              checked={formData.isRequestAllowed}  // No need for fallback to false
              onChange={handleChange}
            />
            Is Request Allowed For Sport?
          </label>
        </div>

        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              name="isRegistrationFeesApplicable"
              className="form-checkbox"
              checked={formData.isRegistrationFeesApplicable || false}
              onChange={handleChange}
            />
            Is Registration Fees Applicable?
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">Registration Fees:</label>
          <input
            type="text"
            name="registrationFees"
            className="form-input"
            value={formData.registrationFees || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Renewal Fees:</label>
          <input
            type="text"
            name="renewalFees"
            className="form-input"
            value={formData.renewalFees || ''}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Select Proficiency:</label>
          <Select
            isMulti
            name="proficiency"
            options={proficiencyOptions}
            value={proficiencyOptions.filter(option => formData.proficiency?.includes(option.value))}

            onChange={handleSelectChange}
            closeMenuOnSelect={false}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Court Booking Notes:</label>
          <textarea
            name="courtBookingNotes"
            className="form-textarea"
            value={formData.courtBookingNotes || ''}
            onChange={handleChange}
            placeholder="Use # to add new point. To highlight a text, wrap it in <highlight></highlight> tag. Ex: <highlight>TEXT</highlight>"
            rows="5"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Sports Image:</label>

          {formData.sportsImage && (
            <div className="image-preview">
              <img
                src={formData.sportsImage}
                alt="Current Sport Image"
                className="sport-image"
                style={{ maxWidth: '100%', height: 'auto' }} // Adjust the styles as needed
              />
            </div>
          )}

          {/* Input to change the image */}
          <input
            type="file"
            name="sportsImage"
            className="form-file"
            accept="image/*"
            onChange={handleChange}
          />
        </div>

        <div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewSport;
import React, { useState } from 'react';
import './Challenge.css';

const PerformanceDuesCoach = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [PerformanceDues, setPerformanceDues] = useState([
    {
      academyName: "Elite Academy",
      batchName: "Batch A",
      coachName: "John Doe",
      month: "January",
      year: 2024,
      noOfPlayersDue: 5,
    },
    {
      academyName: "Pro Academy",
      batchName: "Batch B",
      coachName: "Jane Smith",
      month: "February",
      year: 2024,
      noOfPlayersDue: 3,
    },
    {
      academyName: "Super Academy",
      batchName: "Batch C",
      coachName: "Mike Johnson",
      month: "March",
      year: 2024,
      noOfPlayersDue: 8,
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPerformanceDues = PerformanceDues.filter((Due) =>
    Object.values(Due)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredPerformanceDues.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredPerformanceDues.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="challenges-container">
      <h2 className="challenges-header">Performance Dues</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button">
          Export CSV
        </button>
      </div>
      <table className="challenges-table">
        <thead>
          <tr className="challenges-header-row">
            <th className="challenges-column">Academy Name</th>
            <th className="challenges-column">Batch Name</th>
            <th className="challenges-column">Coach Name</th>
            <th className="challenges-column">Month</th>
            <th className="challenges-column">Year</th>
            <th className="challenges-column">No. of Players-Due</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((Due) => (
            <tr key={Due.id} className="challenges-row">
              <td className="challenges-cell">{Due.academyName}</td>
              <td className="challenges-cell">{Due.batchName}</td>
              <td className="challenges-cell">{Due.coachName}</td>
              <td className="challenges-cell">{Due.month}</td>
              <td className="challenges-cell">{Due.year}</td>
              <td className="challenges-cell">{Due.noOfPlayersDue}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};


export default PerformanceDuesCoach

import React, { useState, useEffect } from 'react';
import './NewCoupon.css';
import Select from "react-select";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const NewCoupon = () => {
  const location = useLocation();
  const edit = location.state?.couponData;
  console.log("edit", edit);

  // State for form fields
  const [title, setTitle] = useState(edit ? edit.title : '');
  const [description, setDescription] = useState(edit ? edit.description : '');
  const [price, setPrice] = useState(edit ? edit.price : '');
  const [mobileNumbers, setMobileNumbers] = useState(edit ? edit.mobileNumbers || [''] : ['']);
  const [selectedScope, setSelectedScope] = useState(edit ? edit.scope || [] : []);
  const [selectedAcademy, setSelectedAcademy] = useState(edit ? edit.academy || [] : []);
  const [existingcoupon, setExistingcoupon] = useState(edit ? edit.existingcoupon || [] : []);
  const [minOrderAmount, setMinOrderAmount] = useState(edit ? edit.minOrderAmount : '');
  const [maxDiscount, setMaxDiscount] = useState(edit ? edit.maxDiscount : '');
  const [percentage, setPercentage] = useState(edit ? edit.percentage : '');
  const [maxUsagePerUser, setMaxUsagePerUser] = useState(edit ? edit.maxUsagePerUser : '');
  const [couponCode, setCouponCode] = useState(edit ? edit.couponCode : '');
  const [startTime, setStartTime] = useState(edit ? edit.startTime : '');
  const [endTime, setEndTime] = useState(edit ? edit.endTime : '');
  const [forAllUsers, setForAllUsers] = useState(edit ? edit.forAllUsers : 'None');

  // Options for select fields
  const scopeOptions = [
    { value: 'local', label: 'Local' },
    { value: 'global', label: 'Global' },
  ];

  const academyOptions = [
    { value: 'academy1', label: 'Academy 1' },
    { value: 'academy2', label: 'Academy 2' },
  ];

  const ExistingCouponOptions = [
    { value: 'coupon1', label: 'Coupon 1' },
    { value: 'coupon2', label: 'Coupon 2' },
  ];

  // Handlers for various form elements
  const handleAddMobileNumber = () => {
    setMobileNumbers([...mobileNumbers, '']);
  };

  const handleScopeChange = (selected) => {
    setSelectedScope(selected);
  };

  const handleAcademyChange = (selected) => {
    setSelectedAcademy(selected);
  };

  const handleExistingCoupon = (selected) => {
    setExistingcoupon(selected);
  };

  const handleMobileNumberChange = (index, value) => {
    const updatedNumbers = [...mobileNumbers];
    updatedNumbers[index] = value;
    setMobileNumbers(updatedNumbers);
  };

  const handleRemoveMobileNumber = (index) => {
    const updatedNumbers = mobileNumbers.filter((_, i) => i !== index);
    setMobileNumbers(updatedNumbers);
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Collecting all form data
    const formData = {
      title,
      description,
      price,
      academy: selectedAcademy.map(option => option.value),
      existingcoupon: existingcoupon.map(option => option.value),
      mobileNumbers,
      scope: selectedScope.map(option => option.value),
      minOrderAmount,
      maxDiscount,
      percentage,
      maxUsagePerUser,
      couponCode,
      startTime,
      endTime,
      forAllUsers: e.target.forAllUsers.value === 'yes',
    };

    console.log('Form Data:', formData); // For debugging purposes

    // Headers for the API request
    const headers = {
      'Content-Type': 'application/json',
      'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
    };

    // Sending the POST request using axios
    axios.post('https://admin.stage.machaxi.com/api/react/coupons/new', formData, { headers })
    .then(response => {
      console.log('Coupon created/updated:', response.data);
    })
    .catch(error => {
      console.error('There was an error submitting the form!', error.response?.data || error.message);
    });
  
  };

  return (
    <div className="form-container">
      <div className="breadcrumb">
        <Link to="/coupon" className="breadcrumb-link">Coupon List</Link> &gt; New Coupon
      </div>
      <form className="academy-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-input"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // Update state on change
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Description</label>
          <textarea
            className="form-textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)} // Update state on change
            required
          />
        </div>

        {/* Add all other fields */}
        <div className="form-group">
          <label className="form-label">Minimum Order Amount</label>
          <input
            type="text"
            className="form-input"
            value={minOrderAmount}
            onChange={(e) => setMinOrderAmount(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Maximum Discount Amount</label>
          <input
            type="text"
            className="form-input"
            value={maxDiscount}
            onChange={(e) => setMaxDiscount(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Percentage</label>
          <input
            type="text"
            className="form-input"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Maximum Usage per User</label>
          <input
            type="text"
            className="form-input"
            value={maxUsagePerUser}
            onChange={(e) => setMaxUsagePerUser(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Coupon Code</label>
          <input
            type="text"
            className="form-input"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-input date-picker-input"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-input date-picker-input"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            onClick={(e) => e.target.showPicker()}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Select Scope</label>
          <Select
            isMulti
            name="scope"
            options={scopeOptions}
            value={selectedScope}
            onChange={handleScopeChange}
            placeholder="Select scope..."
            closeMenuOnSelect={false}
            className="custom-select"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Select Academy</label>
          <Select
            isMulti
            name="academy"
            options={academyOptions}
            value={selectedAcademy}
            onChange={handleAcademyChange}
            placeholder="Select academy..."
            closeMenuOnSelect={false}
            className="custom-select"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Existing Coupon</label>
          <Select
            isMulti
            name="existingcoupon"
            options={ExistingCouponOptions}
            value={existingcoupon}
            onChange={handleExistingCoupon}
            placeholder="Select coupon..."
            closeMenuOnSelect={false}
            className="custom-select"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Mobile Number</label>
          {mobileNumbers.map((number, index) => (
            <div key={index} className="add-more-container">
              <input
                type="text"
                className="form-input"
                value={number}
                onChange={(e) => handleMobileNumberChange(index, e.target.value)}
                required
                maxLength={10}
              />
              {index > 0 && (
                <button
                  type="button"
                  className="remove-btn-mobile"
                  onClick={() => handleRemoveMobileNumber(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" className="add-more-btn-mobile" onClick={handleAddMobileNumber}>
            Add More
          </button>
        </div>
        <div className="form-group">
  <label className="form-label">Apply to All Users</label>
  <select
    name="forAllUsers" // Add the name attribute
    className="form-select"
    defaultValue={edit ? (edit.forAllUsers ? 'yes' : 'no') : 'none'}
    required
  >
    <option value="none">None</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</div>




        {/* Submit Button */}
        <div className="form-actions">
          <button type="submit" className="form-submit-button">
            {edit ? 'Update Coupon' : 'Create Coupon'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCoupon;

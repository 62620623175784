import React, { useState } from 'react';
import './CreatePlayer.css'; 
import { Link } from 'react-router-dom';
const CreatePlayer = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    profilePicture: '',
    gender: '',
    playerCategory: '',
    playerLevel: '',
    playerProfile: '',
    dob: '',
    parentInfo: [
      { parentName: '', parentMobileNumber: '', parentRelation: '' },
      { parentName: '', parentMobileNumber: '', parentRelation: '' },
    ], // State for two parent rows
    hideProfileStats: false,  // New state for the checkbox
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith('parent')) {
      const index = parseInt(name.charAt(name.length - 1));  // Get the parent index from the name (e.g., parentName0)
      const updatedParentInfo = [...formData.parentInfo];
      updatedParentInfo[index][name] = value;
      setFormData({ ...formData, parentInfo: updatedParentInfo });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form Submitted:', formData);
  };

  return (
    <div className="create-player-container">
        <div className="breadcrumb">
        <Link to="/academyPlayerList" className="breadcrumb-link">Academy Player List</Link> &gt; New Player
      </div>
      <form onSubmit={handleSubmit} className="create-player-form">
        {/* Player Information */}
        <div className="-player-name">
          <label htmlFor="name" className="input-label">Player Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-mobile-number">
          <label htmlFor="mobileNumber" className="input-label">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-profile-picture">
          <label htmlFor="profilePicture" className="input-label">Profile Picture</label>
          <input
            type="file"
            id="profilePicture"
            name="profilePicture"
            onChange={handleChange}
            className="input-field"
          />
        </div>

        <div className="input-container-gender">
          <label htmlFor="gender" className="input-label">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="select-field"
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="input-container-player-category">
          <label htmlFor="playerCategory" className="input-label">Player Category</label>
          <input
            type="text"
            id="playerCategory"
            name="playerCategory"
            value={formData.playerCategory}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-player-level">
          <label htmlFor="playerLevel" className="input-label">Player Level</label>
          <input
            type="text"
            id="playerLevel"
            name="playerLevel"
            value={formData.playerLevel}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container-player-profile">
          <label htmlFor="playerProfile" className="input-label">Player Profile</label>
          <select
            id="playerProfile"
            name="playerProfile"
            value={formData.playerProfile}
            onChange={handleChange}
            className="select-field"
            required
          >
            <option value="">Select Profile</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
        </div>

        <div className="input-container-dob">
          <label htmlFor="dob" className="input-label">Date of Birth</label>
          <input
            type="date"
            id="dob"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            className="input-field"
            required
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        {/* Hide Profile Stats checkbox */}
        <div className="input-container-hide-profile-stats">
          <input
            type="checkbox"
            id="hideProfileStats"
            name="hideProfileStats"
            checked={formData.hideProfileStats}
            onChange={handleChange}
            className="checkbox-field"
          />
          <label htmlFor="hideProfileStats" className="checkbox-label">Hide Profile Stats</label>
        </div>


        {/* Parent Information Table */}
        <h3 className="parent-info-title">Parent Information</h3>
        <table className="parent-info-table">
          <thead>
            <tr>
              <th>Parent Name</th>
              <th>Parent Mobile Number</th>
              <th>Relation</th>
            </tr>
          </thead>
          <tbody>
            {formData.parentInfo.map((parent, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name={`parentName${index}`}
                    value={parent.parentName}
                    onChange={handleChange}
                    className="input-field"
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`parentMobileNumber${index}`}
                    value={parent.parentMobileNumber}
                    onChange={handleChange}
                    className="input-field"
                    required
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`parentRelation${index}`}
                    value={parent.parentRelation}
                    onChange={handleChange}
                    className="input-field"
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default CreatePlayer;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Tournament/tournament.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';

const TournamentList = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [tournaments, setTournaments] = useState([
    { id: 1, name: 'Tournament A', academicType: 'Sports', startTime: '2024-11-22', endTime: '2024-11-22', gender: 'Male', categories: 'Open', tournamentType: 'Knockout' },
    { id: 2, name: 'Tournament B', academicType: 'Fitness', startTime: '2024-11-23', endTime: '2024-11-23', gender: 'Female', categories: 'Amateur', tournamentType: 'League' },
    { id: 3, name: 'Tournament C', academicType: 'Wellness', startTime: '2024-11-24', endTime: '2024-11-24', gender: 'Mixed', categories: 'Pro', tournamentType: 'Round Robin' },
  ]);
  const dropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [action, setAction] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [formData, setFormData] = useState({
    name: '',
    academicType: '',
    startTime: '',
    endTime: '',
    gender: '',
    categories: '',
    tournamentType: '',
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Close the dropdown if clicked outside
        setSelectedDropdownId(null);
      }
    };

    // Add the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const [selectedDropdownId, setSelectedDropdownId] = useState(null);

  const handleToggleDropdown = (tournamentId) => {
    // Toggle dropdown visibility for the clicked row
    setSelectedDropdownId((prevId) => (prevId === tournamentId ? null : tournamentId));
  };


  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateTournament = () => {
    navigate('/createTournament');
  };

  const handleGallery  = () => {
    navigate('/newGallery');
  };

  const handleRefree  = () => {
    navigate('/Referee');
  };

  const handleSms  = () => {
    navigate('/sms');
  };

  const handleEditTournament = (tournamentId) => {
    const tournament = tournaments.find(t => t.id === tournamentId);
    setSelectedTournament(tournament);
    setFormData({ ...tournament });
    setAction('edit');
    setIsModalOpen(true);
    navigate('/createTournament')
  };


  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredTournaments = tournaments.filter((tournament) =>
    Object.values(tournament)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredTournaments.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredTournaments.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="tournament-list-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleCreateTournament}>
          + Create Tournament
        </button>
      </div>

      <table className="tournament-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Academic Type</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Gender</th>
            <th>Categories</th>
            <th>Tournament Type</th>
            {hasPrivilege(userData, 'TOURNAMENT_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((tournament) => (
            <tr key={tournament.id}>
              <td>{tournament.name}</td>
              <td>{tournament.academicType}</td>
              <td>{tournament.startTime}</td>
              <td>{tournament.endTime}</td>
              <td>{tournament.gender}</td>
              <td>{tournament.categories}</td>
              <td>{tournament.tournamentType}</td>
              {hasPrivilege(userData, 'TOURNAMENT_LIST_MODIFY') && (
                <td className="action-buttons">
                  <button
                    className="three-dots-btn"
                    onClick={() => handleToggleDropdown(tournament.id)}
                  >
                    &#x22EE;
                  </button>

                  {selectedDropdownId === tournament.id && (
                    <div ref={dropdownRef} className="action-dropdown">
                      <button
                        className="action-btn-tour"
                        onClick={() => {
                          handleEditTournament(tournament.id);
                          setSelectedDropdownId(null);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="action-btn-tour"
                        onClick={() => {
                         handleGallery(tournament.id)
                          setSelectedDropdownId(null);
                        }}
                      >
                      Gallery
                      </button>
                      <button
                        className="action-btn-tour"
                        onClick={() => {
                          handleRefree(tournament.id)
                          setSelectedDropdownId(null);
                        }}
                      >
                        Refrees
                      </button>
                      <button
                        className="action-btn-tour"
                        onClick={() => {
                          handleSms(tournament.id)
                          setSelectedDropdownId(null);
                        }}
                      >
                        Send SMS
                      </button>
                    </div>
                  )}
                </td>)}

            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TournamentList;

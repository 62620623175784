import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CourtList.css';

const CourtList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [courts, setCourts] = useState([
    { id: 1, name: 'Court A', sport: 'Badminton', timing: '6:00 AM - 10:00 PM' },
    { id: 2, name: 'Court B', sport: 'Tennis', timing: '7:00 AM - 9:00 PM' },
    { id: 3, name: 'Court C', sport: 'Basketball', timing: '5:00 AM - 11:00 PM' },
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateCourt = () => {
    navigate('/newcourt');
  };
  const filteredCourts = courts.filter((court) =>
    Object.values(court)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredCourts.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredCourts.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };
  const toggleDropdown = (courtId) => {
    setDropdownOpen(dropdownOpen === courtId ? null : courtId);
  };
  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`courtlist-page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="courtlist-pagination-dots">
          {page}
        </span>
      )
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.courtlist-dropdown-menu');
      if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.courtlist-dots-btn')) {
        setDropdownOpen(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="courtlist-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleCreateCourt}>
          + Create Court
        </button>
      </div>
      <table className="courtlist-table">
        <thead>
          <tr className="courtlist-header-row">
            <th className="courtlist-column courtlist-column-name">Court Name</th>
            <th className="courtlist-column courtlist-column-sport">Sport</th>
            <th className="courtlist-column courtlist-column-timing">Court Timing</th>
            <th className="courtlist-column courtlist-column-action">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((court) => (
            <tr key={court.id} className="courtlist-row">
              <td className="courtlist-cell courtlist-cell-name">{court.name}</td>
              <td className="courtlist-cell courtlist-cell-sport">{court.sport}</td>
              <td className="courtlist-cell courtlist-cell-timing">{court.timing}</td>
              <td className="courtlist-cell courtlist-cell-action">
                <button
                  className="courtlist-dots-btn"
                  onClick={() => toggleDropdown(court.id)}
                >
                  &#x22EE;
                </button>
                <div
                  className={`courtlist-dropdown-menu ${
                    dropdownOpen === court.id ? 'visible' : ''
                  }`}
                >
                  <button className="courtlist-dropdown-item">View Details</button>
                  <button className="courtlist-dropdown-item">Edit Court</button>
                  <button className="courtlist-dropdown-item">Remove Court</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CourtList;

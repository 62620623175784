import React, { useState } from 'react';
import './CourtPrice.css'; // Import CSS file

const CourtPrice = () => {
  // State to manage selected sport, multiple selected courts, and pricing data
  const [sport, setSport] = useState('');
  const [court, setCourt] = useState('');
  
  // Initialize pricing data
  const [pricingData, setPricingData] = useState(
    Array.from({ length: 12 }, () => Array(7).fill('')) // 12 time slots (5:30 AM to 5:30 PM) and 7 days (Monday to Sunday)
  );

  // Time array starting from 5:30 AM to 5:30 PM with 30-minute intervals
  const timeSlots = Array.from({ length: 12 }, (_, i) => {
    const startHour = Math.floor((i + 1) / 2) + 5; // Starting at 5 AM
    const startMinute = (i % 2) === 0 ? '30' : '00';
    const endHour = Math.floor((i + 2) / 2) + 5; // Next hour
    const endMinute = (i + 1) % 2 === 0 ? '00' : '30';
    const startAMPM = startHour < 12 ? 'AM' : 'PM';
    const endAMPM = endHour < 12 ? 'AM' : 'PM';
    return `${startHour}:${startMinute} ${startAMPM} - ${endHour}:${endMinute} ${endAMPM}`;
  });

  // Days of the week for columns
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // Handle price input changes
  const handlePriceChange = (timeIndex, dayIndex, value) => {
    const newPricingData = [...pricingData];
    newPricingData[timeIndex][dayIndex] = value;
    setPricingData(newPricingData);
  };

  return (
    <div className="court-price-container">
      <div className="select-container">
        <label className="select-label">
          Select Sport:
          <select className="select-input" value={sport} onChange={(e) => setSport(e.target.value)}>
            <option value="">Select a Sport</option>
            <option value="Badminton">Badminton</option>
            <option value="Table Tennis">Table Tennis</option>
            {/* Add more sports here */}
          </select>
        </label>
        
        <label className="select-label">
          Select Court:
          <select className="select-input" value={court} onChange={(e) => setCourt(e.target.value)}>
            <option value="">Select a Court</option>
            <option value="Court 1">Court 1</option>
            <option value="Court 2">Court 2</option>
            <option value="Court 3">Court 3</option>
            {/* Add more courts here */}
          </select>
        </label>
      </div>

      {/* Pricing Table */}
      <table className="pricing-table">
        <thead>
          <tr>
            <th>Time</th>
            {daysOfWeek.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {timeSlots.map((time, timeIndex) => (
            <tr key={time}>
              <td>{time}</td>
              {daysOfWeek.map((day, dayIndex) => (
                <td key={day}>
                  <input
                    className="price-input"
                    type="number"
                    placeholder="Price"
                    value={pricingData[timeIndex][dayIndex]}
                    onChange={(e) => handlePriceChange(timeIndex, dayIndex, e.target.value)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button className="create-button">Submit</button>
    </div>
  );
};

export default CourtPrice;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; 
import { hasPrivilege } from '../utils/hasPrivilege';
import './Diet.css';

const DietList = () => {
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [diets, setDiets] = useState([
    { id: 1, category: 'Keto', plan: 'High-fat, Low-carb' },
    { id: 2, category: 'Vegan', plan: 'Plant-based diet' },
    { id: 3, category: 'Mediterranean', plan: 'Balanced diet, rich in healthy fats' },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiet, setSelectedDiet] = useState(null);
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({ category: '', plan: '' });
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNewDiet = () => {
    navigate('/newdiet');
  };

  const handleEditDiet = (dietId) => {
    const diet = diets.find(d => d.id === dietId);
    setSelectedDiet(diet);
    setFormData({ ...diet });
    setAction('edit');
    setIsModalOpen(true);
  };

  const handleDeleteDiet = (dietId) => {
    setSelectedDiet(diets.find(diet => diet.id === dietId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedDiets = diets.filter(diet => diet.id !== selectedDiet.id);
    setDiets(updatedDiets);
    setIsModalOpen(false);
  };

  const handleSaveEdit = () => {
    const updatedDiets = diets.map(diet =>
      diet.id === selectedDiet.id ? { ...diet, ...formData } : diet
    );
    setDiets(updatedDiets);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredDiets = diets.filter((diet) =>
    Object.values(diet)
      .join(' ')
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination calculations
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredDiets.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredDiets.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      // If total pages are less than the limit, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first, last, and surrounding pages
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };

  return (
    <div className="diet-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewDiet}>
          + Create Diet Plan
        </button>
      </div>

      <table className="diet-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Plan</th>
            {hasPrivilege(userData, 'DIET_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((diet) => (
            <tr key={diet.id}>
              <td>{diet.category}</td>
              <td>{diet.plan}</td>
              {hasPrivilege(userData, 'DIET_LIST_MODIFY') && (
                <td className="action-buttons">
                  <button className="edit-btn" onClick={() => handleEditDiet(diet.id)}>Edit</button>
                  <button className="delete-btn" onClick={() => handleDeleteDiet(diet.id)}>Delete</button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>&times;</button>
            <h2>{action === 'edit' ? 'Edit Diet Plan' : 'Delete Diet Plan'}</h2>
            {action === 'edit' ? (
              <div>
                <label>
                  <input
                    type="text"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="plan"
                    value={formData.plan}
                    onChange={handleInputChange}
                  />
                </label>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={handleSaveEdit}>
                    Save Changes
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Are you sure you want to delete the diet plan: {selectedDiet.category}?
                </p>
                <div className="modal-actions">
                  <button className="confirm-btn" onClick={confirmDelete}>
                    Confirm Delete
                  </button>
                  <button className="cancel-btn" onClick={cancelAction}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DietList;

import React, { useState } from 'react';
import './NewBatch.css';

const NewBatch = () => {
  const [formData, setFormData] = useState({
    name: '',
    whatsappLink: '',
    batchType: '',
    sports: '',
    proficiency: '',
    batchCapacity: '',
    monthlyFees: '',
    weekType: '',
    startTime: '',
    endTime: '',
    batchTimings: {
      Monday: { startTime: '', endTime: '', routine: '' },
      Tuesday: { startTime: '', endTime: '', routine: '' },
      Wednesday: { startTime: '', endTime: '', routine: '' },
      Thursday: { startTime: '', endTime: '', routine: '' },
      Friday: { startTime: '', endTime: '', routine: '' },
      Saturday: { startTime: '', endTime: '', routine: '' },
      Sunday: { startTime: '', endTime: '', routine: '' },
    },
    sessionPlans: {
      numberOfSessions: '',
      monthlyFee: '',
      quarterlyFee: '',
      halfYearlyFee: '',
      yearlyFee: '',
    },
    gstApplicable: false,
  });

  const routineOptions = [
    'Fitness Drills',
    'Strokes',
    'Multifeeding',
    'Shuttle Picking',
    'Strengthening',
    'Service',
    'Shadows',
    'Agility',
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBatchTimingChange = (e, day, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      batchTimings: {
        ...prevData.batchTimings,
        [day]: {
          ...prevData.batchTimings[day],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission (e.g., API call)
  };

  return (
    <div className="new-batch-container">
      <form onSubmit={handleSubmit} className="new-batch-form">
        {/* Name Input */}
        <label className="form-label">
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* WhatsApp Group Link */}
        <label className="form-label">
          WhatsApp Group Link:
          <input
            type="url"
            name="whatsappLink"
            value={formData.whatsappLink}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* Batch Type */}
        <label className="form-label">
          Batch Type:
          <select
            name="batchType"
            value={formData.batchType}
            onChange={handleInputChange}
            className="form-select"
            required
          >
            <option value="Adults">Adults</option>
            <option value="Sports">Sports</option>
          </select>
        </label>

        {/* Sports Selection */}
        <label className="form-label">
          Sports:
          <select
            name="sports"
            value={formData.sports}
            onChange={handleInputChange}
            className="form-select"
            required
          >
            <option value="Football">Football</option>
          </select>
        </label>

        {/* Proficiency */}
        <label className="form-label">
          Proficiency:
          <select
            name="proficiency"
            value={formData.proficiency}
            onChange={handleInputChange}
            className="form-select"
            required
          >
            <option value="Beginner">Beginner</option>
          </select>
        </label>

        {/* Batch Capacity */}
        <label className="form-label">
          Batch Capacity:
          <input
            type="number"
            name="batchCapacity"
            value={formData.batchCapacity}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* Monthly Fees */}
        <label className="form-label">
          Monthly Fees:
          <input
            type="number"
            name="monthlyFees"
            value={formData.monthlyFees}
            onChange={handleInputChange}
            className="form-input"
            required
          />
        </label>

        {/* Week Type */}
        <label className="form-label">
          Week Type:
          <select
            name="weekType"
            value={formData.weekType}
            onChange={handleInputChange}
            className="form-select"
            required
          >
            <option value="5 Days Per Week Mon To Fri">5 Days Per Week Mon To Fri</option>
          </select>
        </label>

        {/* Start Time and End Time */}
        <label className="form-label">
          Start Time:
          <input
            type="time"
            name="startTime"
            value={formData.startTime}
            onChange={handleInputChange}
            className="form-input"
            required
            onFocus={(e) => e.target.showPicker()} 
          />
        </label>

        <label className="form-label">
          End Time:
          <input
            type="time"
            name="endTime"
            value={formData.endTime}
            onChange={handleInputChange}
            className="form-input"
            required
            onFocus={(e) => e.target.showPicker()} 
          />
        </label>

        {/* Batch Timings Table */}
        <h3 className="section-title">Batch Timings</h3>
        <table className="batch-timings-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Week Day</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Routine</th>
            </tr>
          </thead>
          <tbody>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <tr key={day}>
                <td>
                  <input type="checkbox" name={day} className="day-checkbox" />
                </td>
                <td>{day}</td>
                <td>
                  <input
                    type="time"
                    value={formData.batchTimings[day].startTime}
                    onChange={(e) => handleBatchTimingChange(e, day, 'startTime')}
                    onFocus={(e) => e.target.showPicker()} 
                    className="batch-time-input"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formData.batchTimings[day].endTime}
                    onFocus={(e) => e.target.showPicker()} 
                    onChange={(e) => handleBatchTimingChange(e, day, 'endTime')}
                    className="batch-time-input"
                  />
                </td>
                <td>
                  <select
                    value={formData.batchTimings[day].routine}
                    onChange={(e) => handleBatchTimingChange(e, day, 'routine')}
                    className="form-select"
                  >
                    <option value="">Select Routine</option>
                    {routineOptions.map((routine, index) => (
                      <option key={index} value={routine}>
                        {routine}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Weekly Session Plans Table */}
        <h3 className="section-title">Weekly Session Plans</h3>
        <table className="session-plans-table">
          <thead>
            <tr>
              <th>Number Of Sessions (Per Week)</th>
              <th>Monthly (Per Session Fee)</th>
              <th>Quarterly (Per Session Fee)</th>
              <th>Half Yearly (Per Session Fee)</th>
              <th>Yearly (Per Session Fee)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="number"
                  name="numberOfSessions"
                  value={formData.sessionPlans.numberOfSessions}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="monthlyFee"
                  value={formData.sessionPlans.monthlyFee}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="quarterlyFee"
                  value={formData.sessionPlans.quarterlyFee}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="halfYearlyFee"
                  value={formData.sessionPlans.halfYearlyFee}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="yearlyFee"
                  value={formData.sessionPlans.yearlyFee}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </td>
            </tr>
          </tbody>
        </table>

        {/* GST Checkbox */}
        <label className="checkbox-label">
          GST Applicable:
          <input
            type="checkbox"
            name="gstApplicable"
            checked={formData.gstApplicable}
            onChange={(e) => setFormData({ ...formData, gstApplicable: e.target.checked })}
            className="checkbox-input"
          />
        </label>

        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default NewBatch;

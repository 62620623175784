import React, { useState } from 'react';
import './NewCourt.css'; // Import the CSS file

const NewCourt = () => {
  const [timings, setTimings] = useState([{ openTime: '', closeTime: '' }]);
  const [courtName, setCourtName] = useState('');
  const [sport, setSport] = useState('');
  const [maxPlayers, setMaxPlayers] = useState('');

  const addTiming = () => {
    setTimings([...timings, { openTime: '', closeTime: '' }]);
  };

  const removeTiming = (index) => {
    if (timings.length > 1) {
      setTimings(timings.filter((_, i) => i !== index));
    } else {
      alert("At least one timing row must be present.");
    }
  };

  const updateTiming = (index, field, value) => {
    const updatedTimings = [...timings];
    updatedTimings[index][field] = value;
    setTimings(updatedTimings);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      courtName,
      sport,
      maxPlayers,
      timings,
    });
    alert('Court submitted successfully!');
  };

  return (
    <div className="new-court-container">
      <form onSubmit={handleSubmit}>
        <div className="new-court-form-group">
          <label className="new-court-label">Court Name:</label>
          <input
            type="text"
            value={courtName}
            onChange={(e) => setCourtName(e.target.value)}
            placeholder="Enter court name"
            className="new-court-input"
            required
          />
        </div>

        <div className="new-court-form-group">
          <label className="new-court-label">Sport:</label>
          <select
            value={sport}
            onChange={(e) => setSport(e.target.value)}
            className="new-court-select"
            required
          >
            <option value="">Select Sport</option>
            <option value="badminton">Badminton</option>
            <option value="tennis">Tennis</option>
            <option value="football">Football</option>
            <option value="basketball">Basketball</option>
          </select>
        </div>

        <div className="new-court-form-group">
          <label className="new-court-label">Max Players per Slot:</label>
          <input
            type="number"
            value={maxPlayers}
            onChange={(e) => setMaxPlayers(e.target.value)}
            placeholder="Enter max players"
            className="new-court-input"
            required
          />
        </div>

        <h3>Timings</h3>
        <table className="new-court-table">
          <thead>
            <tr>
              <th>Open Time</th>
              <th>Close Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {timings.map((timing, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="time"
                    value={timing.openTime}
                    onChange={(e) => updateTiming(index, 'openTime', e.target.value)}
                    className="new-court-time-input"
                    required
                    onClick={(e) => e.target.showPicker()}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={timing.closeTime}
                    onChange={(e) => updateTiming(index, 'closeTime', e.target.value)}
                    className="new-court-time-input"
                    onClick={(e) => e.target.showPicker()}
                    required
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => removeTiming(index)}
                    className="new-court-remove-button"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="new-court-button-group">
          <button
            type="button"
            onClick={addTiming}
            className="new-court-add-button"
          >
            Add More
          </button>
        </div>

        <div className="new-court-submit-container">
          <button type="submit" className="new-court-submit-button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCourt;

import React, { useState } from 'react';
import './CourtBulkBooking.css'; // Import the updated CSS

const CourtBulkBooking = () => {
  const [formData, setFormData] = useState({
    court: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    weekdays: '',
    mobile: '',
    name: '',
    remark: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add logic to handle form submission
  };

  return (
    <div className="bulk-booking-container">
      <form className="bulk-booking-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="court">Select Court:</label>
          <select
            id="court"
            name="court"
            className="input-select"
            value={formData.court}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Court 1">Court 1</option>
            <option value="Court 2">Court 2</option>
            <option value="Court 3">Court 3</option>
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            className="input-field"
            value={formData.startDate}
            onChange={handleChange}
            onClick={(e) => e.target.showPicker()}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            className="input-field"
            value={formData.endDate}
            onChange={handleChange}
            onClick={(e) => e.target.showPicker()}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="startTime">Start Time:</label>
          <input
            type="time"
            id="startTime"
            name="startTime"
            className="input-field"
            value={formData.startTime}
            onChange={handleChange}
            onClick={(e) => e.target.showPicker()}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="endTime">End Time:</label>
          <input
            type="time"
            id="endTime"
            name="endTime"
            className="input-field"
            value={formData.endTime}
            onClick={(e) => e.target.showPicker()}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="weekdays">Select Weekdays:</label>
          <select
            id="weekdays"
            name="weekdays"
            className="input-select"
            value={formData.weekdays}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Monday to Friday">Monday to Friday</option>
            <option value="Saturday and Sunday">Saturday and Sunday</option>
            <option value="All Weekdays">All Weekdays</option>
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="mobile">Mobile Number:</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            className="input-field"
            value={formData.mobile}
            onChange={handleChange}
            pattern="[0-9]{10}"
            placeholder="Enter 10-digit number"
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            className="input-field"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter name"
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="remark">Remark:</label>
          <textarea
            id="remark"
            name="remark"
            className="input-textarea"
            value={formData.remark}
            onChange={handleChange}
            placeholder="Enter remarks"
          ></textarea>
        </div>

        <div className="form-action">
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};


export default CourtBulkBooking;

export const getPageTitle = (pathname) => {
  let pageTitle = '';
  switch (pathname) {
    case '/academylist':
      pageTitle = 'Academy List';
      break;
    case '/disabledAcademy':
      pageTitle = 'Disabled Academy';
      break;
    case '/buy-plan':
      pageTitle = 'Buy Plan';
      break;
    case '/newAcademy':
      pageTitle = 'New Academy';
      break;
    case '/create-role':
      pageTitle = 'Create Role';
      break;
    case '/create-user':
      pageTitle = 'Create User';
      break;
    case '/RoleList':
      pageTitle = 'Role List';
      break;
    case '/UserList':
      pageTitle = 'User List';
      break;
    case '/sportList':
      pageTitle = 'Sport List';
      break;
    case '/newSport':
      pageTitle = 'New Sport';
      break;
    case '/coupon':
      pageTitle = 'Coupon';
      break;
    case '/newCoupon':
      pageTitle = 'New Coupon';
      break;
    case '/dues':
      pageTitle = 'Dues';
      break;
    case '/paymentlist':
      pageTitle = 'Payment List';
      break;
    case '/summary':
      pageTitle = 'Summary';
      break;
    case '/sportPaymentsummary':
      pageTitle = 'Sport Payment Summary';
      break;
    case '/academyPlayerList':
      pageTitle = 'Academy Player List';
      break;
    case '/academyCoachList':
      pageTitle = 'Academy Coach List';
      break;
    case '/createNewBatch':
      pageTitle = 'Create New Batch';
      break;
    case '/createNewPlayer':
      pageTitle = 'Create New Player';
      break;
    case '/createNewCoach':
      pageTitle = 'Create New Coach';
      break;
    case '/batchList':
      pageTitle = 'Batch List';
      break;
    case '/playerBatch':
      pageTitle = 'Player Batch';
      break;
    case '/coachBatch':
      pageTitle = 'Coach Batch';
      break;
    case '/batchCancellation':
      pageTitle = 'Batch Cancellation';
      break;
    case '/cancelBatchList':
      pageTitle = 'Cancel Batch List';
      break;
    case '/diet':
      pageTitle = 'Diet List';
      break;
    case '/tournamentList':
      pageTitle = 'Tournament List';
      break;
    case '/createTournament':
      pageTitle = 'Create Tournament';
      break;
    case '/academyfeedback':
      pageTitle = 'Academy Feedback';
      break;
    case '/coachfeedback':
      pageTitle = 'Coach Feedback';
      break;
    case '/challengeList':
      pageTitle = 'Challenge List';
      break;
    case '/notification':
      pageTitle = 'Notification';
      break;
    case '/planList':
      pageTitle = 'Plan List';
      break;
    case '/newPlan':
      pageTitle = 'New Plan';
      break;
    case '/courtlist':
      pageTitle = 'Court List';
      break;
    case '/bookingcalendar':
      pageTitle = 'Booking Calendar';
      break;
    case '/courtbookings':
      pageTitle = 'Court Bookings';
      break;
    case '/courtprice':
      pageTitle = 'Court Price';
      break;
    case '/courtbulkbooking':
      pageTitle = 'Court Bulk Booking';
      break;
    case '/bulkbookinglist':
      pageTitle = 'Bulk Booking List';
      break;
    case '/newcourt':
      pageTitle = 'New Court';
      break;
    case '/blockcourt':
      pageTitle = 'Block Court';
      break;
    case '/academysport':
      pageTitle = 'Academy Sport';
      break;
    case '/academygallery':
      pageTitle = 'Academy Gallery';
      break;
    case '/bankdetails':
      pageTitle = 'Bank Details';
      break;
    case '/editjobvacancy':
      pageTitle = 'Edit Job Vacancy';
      break;
    case '/editprofile':
      pageTitle = 'Edit Profile';
      break;
    case '/feesSetting':
      pageTitle = 'Fees Setting';
      break;
    case '/resetPassword':
      pageTitle = 'Reset Password';
      break;
    case '/razorpayauth':
      pageTitle = 'Razorpay Auth';
      break;
    case '/searchuser':
      pageTitle = 'Search User';
      break;
    case '/sliderlist':
      pageTitle = 'Slider List';
      break;
    case '/newslider':
      pageTitle = 'New Slider';
      break;
    case '/attendance':
      pageTitle = 'Attendance';
      break;
    case '/challenge':
      pageTitle = 'Challenge';
      break;
    case '/checkinreport':
      pageTitle = 'Check-in Reports';
      break;
    case '/couponsusagereport':
      pageTitle = 'Coupons Usage Report';
      break;
    case '/courtbooking':
      pageTitle = 'Court Booking';
      break;
    case '/dailyattendancestatus':
      pageTitle = 'Daily Attendance Status';
      break;
    case '/disputepending':
      pageTitle = 'Dispute Pending';
      break;
    case '/disputeresolved':
      pageTitle = 'Dispute Resolved';
      break;
    case '/learntrailreports':
      pageTitle = 'Learn Trail Reports';
      break;
    case '/leavereport':
      pageTitle = 'Leave Report';
      break;
    case '/loginreport':
      pageTitle = 'Login Report';
      break;
    case '/patnerscontactus':
      pageTitle = 'Partners Contact Us';
      break;
    case '/performanceduescoach':
      pageTitle = 'Performance Dues Coach';
      break;
    case '/playingsubscription':
      pageTitle = 'Playing Subscription';
      break;
    case '/playtrailreport':
      pageTitle = 'Play Trail Report';
      break;
    case '/rewardDuesfamily':
      pageTitle = 'Reward Dues Family';
      break;
    case '/rewardDuesCoach':
      pageTitle = 'Reward Dues Coach';
      break;
    case '/salesRegister':
      pageTitle = 'Sales Register';
      break;
    case '/tournamentRegistration':
      pageTitle = 'Tournament Registration';
      break;
    case '/CreateCoach':
      pageTitle = 'Create Coach';
      break;
    case '/ProgresAttribute':
      pageTitle = 'Progress Attribute';
      break;
    case '/NewProgress':
      pageTitle = 'New Progress';
      break;
    case '/AttributeQa':
      pageTitle = 'Attribute QA';
      break;
    case '/ProgessParameter':
      pageTitle = 'Progress Parameter';
      break;
    case '/newAttribute':
      pageTitle = 'New Attribute QA';
      break;
    case '/newProgessParameter':
      pageTitle = 'New Progress Parameter';
      break;
    case '/settlePayment':
      pageTitle = 'Settle Payment';
      break;
    case '/createplayer':
      pageTitle = 'Create Player';
      break;
    case '/shop':
      pageTitle = 'Shop';
      break;
    case '/newGallery':
      pageTitle = 'New Gallery';
      break;
    case '/newReferee':
      pageTitle = 'New Referee';
      break;
    case '/Referee':
      pageTitle = 'Referee';
      break;
    case '/sms':
      pageTitle = 'SMS';
      break;
    default:
      pageTitle = 'Dashboard';
  }

  return pageTitle;
};

import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../UserRole/CreateRole.css';

const CreateRolePage = () => {
  const location = useLocation(); // Get location state
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [formList, setFormList] = useState([{ section: '', subSections: [] }]);
  const [sections, setSections] = useState([]);
  const dropdownRef = useRef(null);

  // If location.state is present (which means data was passed), use it
  useEffect(() => {
    if (location.state) {
      const { roleName, sectionsAndSubsections } = location.state;
      console.log("This is the location state", location.state);
      setRoleName(roleName);
      setFormList(sectionsAndSubsections.map(item => ({
        section: item.section,
        subSections: item.subsections || []
      })));
      setDropdownOpen(sectionsAndSubsections.map(() => false)); // Initialize dropdown state
    }
  }, [location.state]);

  useEffect(() => {
    setDropdownOpen(formList.map(() => false));
  }, [formList]);

  if (location.state) {
    const roleId = location.state.roleId;
    console.log("This is the roleId", roleId);
  }



  // Fetch privileges on page load
  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        const response = await axios.get('https://admin.stage.machaxi.com/api/admin/create-role', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', // Replace with a valid token
          },
        });
        if (response.data.success) {
          const privileges = response.data.data.privileges;
          const sectionsArray = Object.entries(privileges).map(([key, value]) => ({
            name: key,
            subsections: value,
          }));
          setSections(sectionsArray);
        } else {
          console.error('Failed to fetch privileges:', response.data.success_message);
        }
      } catch (error) {
        console.error('Error fetching privileges:', error);
      }
    };
    fetchPrivileges();
  }, []);

  const handleSectionChange = (index, event) => {
    const newFormList = [...formList];
    newFormList[index].section = event.target.value;
    newFormList[index].subSections = [];
    setFormList(newFormList);
  };

  const handleSubSectionClick = (index, subsection) => {
    const newFormList = [...formList];
    const selectedSubsections = newFormList[index].subSections;
    if (selectedSubsections.includes(subsection)) {
      newFormList[index].subSections = selectedSubsections.filter((item) => item !== subsection);
    } else {
      newFormList[index].subSections = [...selectedSubsections, subsection];
    }
    setFormList(newFormList);
  };

  const handleSave = async () => {
    if (!roleName.trim()) {
      alert("Role name is required!");
      return;
    }
  
    const hasValidSection = formList.some(
      (form) => form.section && form.subSections.length > 0
    );
  
    if (!hasValidSection) {
      alert("At least one section with subsections is required!");
      return;
    }
  
    // Construct privilegeList in the required format
    const privilegeList = formList.reduce((acc, form) => {
      if (form.section && form.subSections.length > 0) {
        acc[form.section] = form.subSections;
      }
      return acc;
    }, {});


    // Prepare the payload
    const payload = {
      roleName,
      privilegeList,
    };

    try {
      const apiUrlBase = 'https://admin.stage.machaxi.com/api/admin/new-role';
      const apiUrl = location.state && location.state.roleId ? `${apiUrlBase}?roleId=${location.state.roleId}` : apiUrlBase;

      const response = await axios.post(apiUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ',
        },
      });

      if (response.data.success) {
        console.log("Updated Role : ", response.data);
        if (location.state) {
          alert('Role updated successfully!');
        }
        else {
          alert('Role created successfully!');
        }
        navigate('/RoleList');
      } else {
        console.error('Failed to create/update role:', response.data.success_message);
      }
    } catch (error) {
      console.error('Error creating/updating role:', error);
    }
  };


  const handleAddMore = () => {
    setFormList([...formList, { section: '', subSections: [] }]);
  };



  const handleRemove = (index) => {
    const newFormList = formList.filter((_, formIndex) => formIndex !== index);
    const newDropdownOpen = dropdownOpen.filter((_, dropIndex) => dropIndex !== index);
    setFormList(newFormList);
    setDropdownOpen(newDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current.every(
          (ref) => ref.current && !ref.current.contains(event.target)
        )
      ) {
        setDropdownOpen(formList.map(() => false));
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [formList]);



  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) =>
      prevState.map((state, idx) => (idx === index ? !state : state))
    );
  };

  const dropdownRefs = useRef([]);
  dropdownRefs.current = formList.map(
    (_, i) => dropdownRefs.current[i] || React.createRef()
  );


  const selectedSection = (section) => sections.find((sec) => sec.name === section);
  const selectedSections = formList.map((form) => form.section);

  return (
    <div>
      <div className="form-container" style={{minHeight:'500px' }}>
        <div className="breadcrumb">
          <Link to="/RoleList" className="breadcrumb-link">Role List</Link>  &gt;  New Role
        </div>

        <div className="form-group">
          <label>Role Name:</label>
          <input
            type="text"
            placeholder="Enter role name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            className="input-field"
            required
          />
        </div>

        {formList.map((form, index) => {
          const subsections = selectedSection(form.section)?.subsections || [];
          const availableSections = sections.filter((sec) => !selectedSections.includes(sec.name) || sec.name === form.section);

          return (
            <form key={index} className="role-form">
              {index > 0 && (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemove(index)}
                >
                  ✖
                </button>
              )}
              <div className="form-group">
                <label>Section:</label>
                <select
                  value={form.section}
                  onChange={(e) => handleSectionChange(index, e)}
                  className="input-field"
                  required
                >
                  <option value="">Select a Section</option>
                  {availableSections.map((sec) => (
                    <option key={sec.name} value={sec.name}>
                      {sec.name}
                    </option>
                  ))}
                </select>
              </div>

              {form.section && (
                <div className="form-group">
                  <label>Subsections:</label>
                  <div ref={dropdownRefs.current[index]} className="dropdown-container">
                    <button
                      type="button"
                      onClick={() => toggleDropdown(index)}
                      className="dropdown-toggle"
                    >
                      {form.subSections.length > 0 ? form.subSections.join(', ') : 'Select Subsections'}
                    </button>
                    {form.section && dropdownOpen[index] && (
                      <div className="dropdown-menu">
                        {subsections.map((sub) => (
                          <div
                            key={sub}
                            onClick={() => handleSubSectionClick(index, sub)}
                            className={`dropdown-item ${form.subSections.includes(sub) ? 'selected' : ''
                              }`}
                          >
                            {form.subSections.includes(sub) && '✔'} {sub}
                          </div>
                        ))}
                      </div>
                    )}


                  </div>
                </div>
              )}
            </form>
          );
        })}

        {formList.length < sections.length && (
          <div className="button-container">
            <button
              type="button"
              onClick={handleAddMore}
              className="add-more-button"
            >
              Add More
            </button>
          </div>
        )}

        <div className="button-container">
          <button
            type="button"
            onClick={handleSave}
            className="save-button"
          >
            Save Role
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRolePage;
import React, { useState } from 'react';
import './SettlePayment.css';  
import { Link } from 'react-router-dom';

const SettlePayment = () => {
  const [formData, setFormData] = useState({
    playerName: 'John Doe',          
    academy: 'Elite Sports Academy', 
    batch: 'Batch A',                 
    dueOn: '2024-12-15',             
    totalDueAmount: '5000',          
    collectedAmount: '',         
    collectionMethod: '',             
    remark: ''                       
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send data to an API or handle locally
    console.log('Form Submitted:', formData);
  };

  return (
    <div className="settle-payment-container">
        <div className="breadcrumb">
        <Link to="/dues" className="breadcrumb-link">Payment Dues</Link> &gt; Settle Payment
      </div>
      <form onSubmit={handleSubmit} className="settle-payment-form">
        <div className="input-container">
          <label htmlFor="playerName" className="input-label">Player Name</label>
          <input
            type="text"
            id="playerName"
            name="playerName"
            value={formData.playerName}
            onChange={handleChange}
            className="input-field"
            required
            disabled
          />
        </div>

        <div className="input-container">
          <label htmlFor="academy" className="input-label">Academy</label>
          <input
            type="text"
            id="academy"
            name="academy"
            value={formData.academy}
            onChange={handleChange}
            className="input-field"
            required
            disabled
          />
        </div>

        <div className="input-container">
          <label htmlFor="batch" className="input-label">Batch</label>
          <input
            type="text"
            id="batch"
            name="batch"
            value={formData.batch}
            onChange={handleChange}
            className="input-field"
            required
            disabled
          />
        </div>

        <div className="input-container">
          <label htmlFor="dueOn" className="input-label">Due On</label>
          <input
            type="date"
            id="dueOn"
            name="dueOn"
            value={formData.dueOn}
            onChange={handleChange}
            className="input-field"
            required
            disabled
          />
        </div>

        <div className="input-container">
          <label htmlFor="totalDueAmount" className="input-label">Total Due Amount</label>
          <input
            type="number"
            id="totalDueAmount"
            name="totalDueAmount"
            value={formData.totalDueAmount}
            onChange={handleChange}
            className="input-field"
            required
            disabled
          />
        </div>

        <div className="input-container">
          <label htmlFor="collectedAmount" className="input-label">Collected Amount</label>
          <input
            type="input"
            id="collectedAmount"
            name="collectedAmount"
            value={formData.collectedAmount}
            onChange={handleChange}
            className="input-field"
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="collectionMethod" className="input-label">Collection Method</label>
          <select
            id="collectionMethod"
            name="collectionMethod"
            value={formData.collectionMethod}
            onChange={handleChange}
            className="select-field"
            required
          >
            <option value="">Select Method</option>
            <option value="Cash">Cash</option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cheque">Cheque</option>
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="remark" className="input-label">Remark</label>
          <textarea
            id="remark"
            name="remark"
            value={formData.remark}
            onChange={handleChange}
            className="textarea-field"
          />
        </div>

        <button type="submit" className="submit-button">Collect</button>
      </form>
    </div>
  );
};

export default SettlePayment;

import React from 'react'

const NewGallery = () => {
  return (
    <div>
      NewGallery 
    </div>
  )
}

export default NewGallery

import React, { useState } from "react";
import "./Referee.css";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

const Referee = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const refereesPerPage = 10;  

  const refereeData = [
    { id: 1, name: "John Doe", mobile: "9876543210", userType: "Admin" },
    { id: 2, name: "Jane Smith", mobile: "8765432109", userType: "User" },
    { id: 3, name: "Mike Johnson", mobile: "7654321098", userType: "Referee" },
    { id: 4, name: "Emily White", mobile: "6543210987", userType: "Admin" },
    { id: 5, name: "Daniel Green", mobile: "5432109876", userType: "User" },
    { id: 6, name: "Sophia Black", mobile: "4321098765", userType: "Referee" },
    // Add more referees as needed for pagination to work effectively
  ];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const navigate = useNavigate();

  const handleEdit = (id) => {
    alert(`Edit Referee ID: ${id}`);
  };

  const handleDelete = (id) => {
    alert(`Delete Referee ID: ${id}`);
  };

  // Filter referees based on search term
  const filteredReferees = refereeData.filter((referee) =>
    Object.values(referee)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Calculate the index of the first and last referee to display on the current page
  const indexOfLastReferee = currentPage * refereesPerPage;
  const indexOfFirstReferee = indexOfLastReferee - refereesPerPage;

  // Get the referees to display on the current page
  const currentReferees = filteredReferees.slice(indexOfFirstReferee, indexOfLastReferee);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredReferees.length / refereesPerPage);

  // Handle page navigation
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNewReferee = () => navigate('/newReferee');

  return (
    <div className="refree-container">
      <div className="breadcrumb">
        <Link to="/tournamentList" className="breadcrumb-link">Tournament List</Link> &gt; Referee List
      </div>
      <h2 className="refree-header">Referee List</h2>

      <div className="search-bar">
        <input
          type="text"
          placeholder=" 🔍 Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="create-button" onClick={handleNewReferee}>
          + Add New Referee
        </button>
      </div>

      <table className="refree-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>User Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentReferees.map((referee) => (
            <tr key={referee.id}>
              <td>{referee.name}</td>
              <td>{referee.mobile}</td>
              <td>{referee.userType}</td>
              <td>
                <button
                  className="refree-edit-btn"
                  onClick={() => handleEdit(referee.id)}
                >
                  Edit
                </button>
                <button
                  className="refree-delete-btn"
                  onClick={() => handleDelete(referee.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Referee;

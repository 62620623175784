import React, { useState } from "react";
import './NewTournament.css';
import Select from "react-select";
import { Link } from "react-router-dom";
const TournamentForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    url: "https://dribble.test-app.link/",
    startDate: "",
    startTime: "",
    endDate: "",
    address: "",
    sport: "Football",
    coverPicture: null,
    maxRegistrations: "",
    academyType: "INTRA_ACADEMY",
    genderType: "MALE",
    format: "KNOCK_OUT",
    category: "",
    fees: [],
    registrationLastDate: "",
    conditions: "",
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const fixedPrefix = "https://dribble.test-app.link/";
  const [suffix, setSuffix] = useState("");
  const handleSuffixChange = (newSuffix) => {
    setSuffix(newSuffix); // Assuming `setSuffix` is updating the `suffix` state
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, coverPicture: e.target.files[0] });
  };
  const options = [
    { value: "U9", label: "U9" },
    { value: "U10", label: "U10" },
    { value: "U11", label: "U11" },
    { value: "U13", label: "U13" },
    { value: "U15", label: "U15" },
    { value: "U17", label: "U17" },
    { value: "OPEN", label: "OPEN" },
  ];

  const handleChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions || []);
  };

  const handleFeesChange = (type, checked) => {
    setFormData((prev) => ({
      ...prev,
      fees: checked
        ? [...prev.fees, type]
        : prev.fees.filter((fee) => fee !== type),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", formData);
    // Handle form submission logic here
  };

  return (
    <form onSubmit={handleSubmit} className="tournament-form">
        <div className="breadcrumb">
        <Link to="/tournamentList" className="breadcrumb-link">Tournament List</Link> &gt; New Tournament
      </div>

      <label className="form-label">
        Name*:
        <input
          type="text"
          name="name"
          className="input-name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </label>
      <label className="form-label">
        Shareable URL:
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            name="url"
            className="input-url"
            value={`${fixedPrefix}${suffix}`} // Display the full URL
            onChange={(e) => {
              const inputValue = e.target.value;

              // Ensure the prefix remains intact and extract the suffix
              if (inputValue.startsWith(fixedPrefix)) {
                const updatedSuffix = inputValue.slice(fixedPrefix.length);
                handleSuffixChange(updatedSuffix);
              }
            }}
            placeholder="Enter your custom part"
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "12px 15px",
              color: "#555",
              fontSize: "14px",
              whiteSpace: "nowrap",
              backgroundColor: "#f9f9f9",
            }}
          />
        </div>
      </label>

      <label className="form-label">
        Start Date:
        <input
          type="date"
          name="startDate"
          className="input-start-date"
          value={formData.startDate}
          onChange={handleInputChange}
          onClick={(e) => e.target.showPicker()}
          required
        />
      </label>

      <label className="form-label">
        Start Time:
        <input
          type="time"
          name="startTime"
          className="input-start-time"
          value={formData.startTime}
          onChange={handleInputChange}
          onClick={(e) => e.target.showPicker()}
          required
        />
      </label>

      <label className="form-label">
        End Date:
        <input
          type="date"
          name="endDate"
          className="input-end-date"
          value={formData.endDate}
          onChange={handleInputChange}
          onClick={(e) => e.target.showPicker()}
          required
        />
      </label>

      <label className="form-label">
        Address*:
        <textarea
          name="address"
          className="textarea-address"
          value={formData.address}
          onChange={handleInputChange}
          required
        />
      </label>

      <label className="form-label">
        Sport:
        <select
          name="sport"
          className="select-sport"
          value={formData.sport}
          onChange={handleInputChange}
        >
          <option value="Football">Football</option>
        </select>
      </label>

      <label className="form-label">
        Cover Picture:
        <input
          type="file"
          name="coverPicture"
          className="input-cover-picture"
          onChange={handleFileChange}
          accept="image/*"
        />
      </label>

      <label className="form-label">
        Max Registrations*:
        <input
          type="number"
          name="maxRegistrations"
          className="input-max-registrations"
          value={formData.maxRegistrations}
          onChange={handleInputChange}
          required
        />
      </label>

      <label className="form-label">
        Academy Type:
        <select
          name="academyType"
          className="select-academy-type"
          value={formData.academyType}
          onChange={handleInputChange}
        >
          <option value="INTRA_ACADEMY">Intra Academy</option>
          <option value="INTER_ACADEMY">Inter Academy</option>
        </select>
      </label>

      <label className="form-label">
        Gender Type:
        <select
          name="genderType"
          className="select-gender-type"
          value={formData.genderType}
          onChange={handleInputChange}
        >
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
          <option value="MIXED">Mixed</option>
        </select>
      </label>

      <label className="form-label">
        Tournament Format:
        <select
          name="format"
          className="select-format"
          value={formData.format}
          onChange={handleInputChange}
        >
          <option value="KNOCK_OUT">Knock Out</option>
          <option value="ROUND_ROBIN">Round Robin</option>
        </select>
      </label>

      <label className="form-label">Tournament Categories:
        <Select
          isMulti
          options={options}
          value={selectedCategories}
          onChange={handleChange}
          className="select-category"
          placeholder="Select categories"
          closeMenuOnSelect={false}
        />
      </label>

      <fieldset className="fieldset-fees">
        <legend className="legend-fees">Tournament Fees:</legend>
        <table className="table-fees">
          <thead>
            <tr>
              <th>Select</th>
              <th>Type</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            {["SINGLE", "DOUBLE", "MIX_DOUBLE", "MIX_SINGLE"].map((type) => (
              <tr key={type}>
                <td>
                  <input
                    type="checkbox"
                    className={`checkbox-fee-${type.toLowerCase()}`}
                    checked={formData.fees.includes(type)}
                    onChange={(e) => handleFeesChange(type, e.target.checked)}
                  />
                </td>
                <td>{type}</td>
                <td>
                  <input
                    type="text"
                    className={`input-fee-${type.toLowerCase()}`}
                    onChange={(e) =>
                      handleFeesChange(type, formData.fees.includes(type))
                    }

                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </fieldset>

      <label className="form-label">
        Registration Last Date:
        <input
          type="date"
          name="registrationLastDate"
          className="input-registration-last-date"
          value={formData.registrationLastDate}
          onChange={handleInputChange}
          onClick={(e) => e.target.showPicker()}
        />
      </label>

      <label className="form-label">
        Conditions for the tournament*:
        <textarea
          name="conditions"
          className="textarea-conditions"
          value={formData.conditions}
          onChange={handleInputChange}
          required
        />
      </label>

      <button type="submit" className="btn-submit">
        Submit
      </button>
    </form>
  );
};

export default TournamentForm;

import React, { useState } from 'react';
import './BookingCalendar.css';

const BookingCalendar = () => {
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSlots, setSelectedSlots] = useState(new Set());

  const sports = ['Badminton', 'Table Tennis', 'Squash', 'Tennis'];
  const courts = Array.from({ length: 16 }, (_, i) => i + 1);

  const generateTimeSlots = () => {
    const slots = [];
    const startTime = new Date(0, 0, 0, 5, 0); // Start from 5:00 AM
    const endTime = new Date(0, 0, 1, 5, 0); // End at 5:00 AM next day

    while (startTime < endTime) {
      slots.push(new Date(startTime).getTime()); // Use timestamp for unique slot identifiers
      startTime.setMinutes(startTime.getMinutes() + 30);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  const formatTime = (timestamp) => {
    const time = new Date(timestamp);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  const toggleSlotSelection = (courtNumber, time) => {
    const slotKey = `${courtNumber}-${time}`;
    const updatedSlots = new Set(selectedSlots);
    if (selectedSlots.has(slotKey)) {
      updatedSlots.delete(slotKey);
    } else {
      updatedSlots.add(slotKey);
    }
    setSelectedSlots(updatedSlots);
  };

  const handleBookSelectedSlots = () => {
    if (!selectedSport || !selectedDate) {
      alert('Please select a sport and date before booking.');
      return;
    }

    if (selectedSlots.size === 0) {
      alert('No slots selected!');
      return;
    }

    alert('slot booked');
  };

  return (
    <div className="booking-container">
      <div className="booking-controls">
        <div className="booking-control">
          <label htmlFor="sport">Select Sport:</label>
          <select id="sport" value={selectedSport} onChange={(e) => setSelectedSport(e.target.value)}>
            <option value="" disabled>Select a sport</option>
            {sports.map((sport, index) => (
              <option key={index} value={sport}>{sport}</option>
            ))}
          </select>
        </div>
        <div className="booking-control">
          <label htmlFor="date">Select Date:</label>
          <input type="date" id="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}
          onClick={(e) => e.target.showPicker()}
          />

        </div>
      </div>

      {/* Book Selected Slots Button */}
      <button className="booking-main-button" onClick={handleBookSelectedSlots}>
        Book Selected Slots
      </button>

      {/* Courts Table */}
      <div className="table-container" style={{ maxWidth: '1100px', overflowY: 'auto' }}>
        <table className="booking-table">
          <thead>
            <tr>
              <th>Time</th>
              {courts.map((court) => (
                <th key={court}>Court {court}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((time) => (
              <tr key={time}>
                <td>{formatTime(time)}</td>
                {courts.map((court) => {
                  const slotKey = `${court}-${time}`;
                  const isSelected = selectedSlots.has(slotKey);
                  return (
                    <td key={court}>
                      <button
                        onClick={() => toggleSlotSelection(court, time)}
                        className={`booking-button ${isSelected ? 'selected' : ''}`}
                        aria-pressed={isSelected}
                        aria-label={`Book Court ${court} at ${formatTime(time)}`}
                      >
                        {isSelected ? 'Booked' : 'Book'}
                      </button>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingCalendar;

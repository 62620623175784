import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Academy/AcademyList.css';
import { useUser } from '../context/UserContext';
import { hasPrivilege } from '../utils/hasPrivilege';
import { Link } from 'react-router-dom';
import axios from 'axios';

const DisableAcademy = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [academies, setAcademies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  useEffect(() => {
    const fetchAcademies = async () => {
      try {
        const response = await axios.get('https://admin.stage.machaxi.com/api/academy/list-disabled', {
          headers: {
            'x-authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjMyIiwic2NvcGVzIjpbIkZBTUlMWSJdLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvIiwiaWF0IjoxNzI1OTY0NzQwLCJleHAiOjUyMjU2NDQ1OTY0NzQwfQ.hmJKVk0nf09p4FpTgYORILwKd0KvKhN0mZpV5Au5qSg2kGsdjtfPG8wxBjcVT-J6MfykveREJ7xazolGhAlwdQ', 
          },
        });

        if (response.data.success) {
          setAcademies(response.data.data.academies);
        } else {
          setError('Failed to fetch academies');
        }
      } catch (error) {
        setError('An error occurred while fetching the data');
      } finally {
        setLoading(false);
      }
    };

    fetchAcademies();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [action, setAction] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10); // Default to 10 items per page
    const [items, setItems] = useState([]); // Your data (e.g., from API)
  
    const handleItemsPerPageChange = (event) => {
      setrowsPerPage(Number(event.target.value));
      setCurrentPage(1); // Reset to first page when items per page change
    };
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen !== null && !event.target.closest('.dropdown-container')) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    console.log("Selected Academy:", selectedAcademy);
  }, [selectedAcademy]);

  const filteredAcademies = academies.filter((academy) => {
    const notificationStatus = academy.notification ? 'Yes' : 'No';
    return (
      Object.values(academy)
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      notificationStatus.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const toggleDropdown = (academyId) => {
    setDropdownOpen(dropdownOpen === academyId ? null : academyId);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredAcademies.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredAcademies.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleDeleteAcademy = (academyId) => {
    setSelectedAcademy(academies.find(academy => academy.id === academyId));
    setAction('delete');
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const updatedAcademies = academies.filter(academy => academy.id !== selectedAcademy.id);
    setAcademies(updatedAcademies);
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleActionClick = (academyId, action) => {
    setSelectedAcademy(academies.find(academy => academy.id === academyId));
    switch (action) {
      case 'edit':
        navigate(`/newAcademy`);
        break;
      case 'enable':
        handleDeleteAcademy(academyId);
        break;
      case 'send_sms':
        const confirmed = window.confirm('Are you sure you want to send an SMS?');
        if (confirmed) {
          navigate(`/academylist`);
        }
        break;
      case 'feedback':
        navigate(`/academyfeedback`);
        break;
      case 'player_list':
        navigate(`/academyPlayerList`);
        break;
      case 'coach_list':
        navigate(`/academyCoachList`);
        break;
      default:
        break;
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPagesToShow = 5;

    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push('...');
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };


  const renderPagination = () => {
    return generatePageNumbers().map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`page-number ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="pagination-dots">
          {page}
        </span>
      )
    );
  };
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedAcademies = [...academies].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const getSortArrow = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };


  return (
    <div className="academy-list-container">
      <div className="search-bar">
          <div className="input-container">
          <input
            type="text"
            id="searchInput"
            placeholder=""
            className={`search-input ${searchTerm ? "has-value" : ""}`}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <label htmlFor="searchInput" className="floating-label">
            🔍 Search
          </label>
        </div>
      </div>
      <select className="items-per-page-select" value={rowsPerPage} onChange={handleItemsPerPageChange}>
  <option value={10}>10 items per page</option>
  <option value={50}>50 items per page</option>
  <option value={75}>75 items per page</option>
  <option value={100}>100 items per page</option>
</select>
      <table className="academy-table">
      <thead>
          <tr>
            <th onClick={() => handleSort('name')}>Name {getSortArrow('name')}</th>
            <th onClick={() => handleSort('about')}>About {getSortArrow('about')}</th>
            <th onClick={() => handleSort('address')}>Address {getSortArrow('address')}</th>
            <th onClick={() => handleSort('notification')}>Notification {getSortArrow('notification')}</th>
            {hasPrivilege(userData, 'DISABLED_ACADEMY_LIST_MODIFY') && (<th>Action</th>)}
          </tr>
        </thead>
        <tbody>
          {sortedAcademies.map((academy) => (
            <tr key={academy.id}>
              <td><Link to={`/batchList/${academy.id}`}>{academy.name}</Link></td>
              <td>{academy.about}</td>
              <td>{academy.address}</td>
              <td>{academy.notification ? 'Yes' : 'No'}</td>
              {hasPrivilege(userData, 'DISABLED_ACADEMY_LIST_MODIFY') && (
                <td className="action-buttons">
                  <button
                    className="ellipsis-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown(academy.id);
                    }}
                  >
                    &#x22EE;
                  </button>
                  {dropdownOpen === academy.id && (
                    <div className="custom-dropdown">
                      <button onClick={() => handleActionClick(academy.id, 'player_list')}>Player List</button>
                      <button onClick={() => handleActionClick(academy.id, 'coach_list')}>Coach List</button>
                      <button onClick={() => handleActionClick(academy.id, 'edit')}>Edit</button>
                      <button onClick={() => handleActionClick(academy.id, 'enable')}>Enable</button>
                      <button onClick={() => handleActionClick(academy.id, 'send_sms')}>Send SMS</button>
                      <button onClick={() => handleActionClick(academy.id, 'feedback')}>Feedback</button>
                    </div>
                  )}
                </td>)}

            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          className="prev-button"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPagination()}
        <button
          className="next-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>


      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={cancelAction}>&times;</button>
            <div>
              <p>
                Are you sure you want to delete the academy: {selectedAcademy.name}?
              </p>
              <div className="modal-actions">
                <button className="confirm-btn-academy" onClick={confirmDelete}>
                  Confirm Enable
                </button>
                <button className="cancel-btn-academy" onClick={cancelAction}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisableAcademy;
